import { getTier1DataTestId } from '../Service/testing';
import NestedBreadcrumb from '../NestedBreadcrumb/NestedBreadcrumb';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import ErrorBoundary from '../../ErrorBoundary';
import { usePortalsInfo, useV2Info } from '../Context';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const ConditionallyRenderedBreadcrumb = ({
  reactBreadcrumb,
  angularBreadcrumb,
  pageId,
}) => {
  const { context } = useV2Info();
  const { isPortalsChild } = usePortalsInfo();
  const angularBreadcrumbRef = React.useRef();
  const isSIProject = (id) => {
    const prefix = 'ZN_';

    return (
      id &&
      id.length > prefix.length &&
      id.substring(0, prefix.length) === prefix
    );
  };

  const showNestedSelector =
    context?.resourceId &&
    context.subresourceId &&
    context.resourceId !== context.subresourceId &&
    isSIProject(context.resourceId);

  useEffect(() => {
    if (reactBreadcrumb || isPortalsChild) {
      return;
    }

    //angular
    if (angularBreadcrumbRef.current && angularBreadcrumb?.scope) {
      for (let i = 0; i < angularBreadcrumb.clone.length; i++) {
        const element = angularBreadcrumb.clone[i];
        angularBreadcrumbRef.current.appendChild(element);
      }
    }
    //non angular
    else if (angularBreadcrumbRef.current && angularBreadcrumb) {
      angularBreadcrumbRef.current.appendChild(angularBreadcrumb);
    }

    return () => {
      if (angularBreadcrumb?.scope) {
        angularBreadcrumb.clone.remove();
        angularBreadcrumb.scope.$destroy();
      }
    };
  }, []);

  return (
    <ErrorBoundary>
      {reactBreadcrumb !== undefined ? (
        reactBreadcrumb
      ) : angularBreadcrumb ? (
        <div
          data-testid={getTier1DataTestId('Breadcrumb')}
          ref={angularBreadcrumbRef}
        />
      ) : showNestedSelector ? (
        <div data-testid={getTier1DataTestId('NestedBreadcrumb')}>
          <NestedBreadcrumb />
        </div>
      ) : (
        <div data-testid={getTier1DataTestId('DefaultBreadcrumb')}>
          <Breadcrumb pageId={pageId} />
        </div>
      )}
    </ErrorBoundary>
  );
};

ConditionallyRenderedBreadcrumb.propTypes = {
  reactBreadcrumb: PropTypes.element,
  angularBreadcrumb: PropTypes.object,
  pageId: PropTypes.string,
};
