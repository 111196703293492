export const shouldHideEntireNavInChildIframe = () => {
  try {
    if (window.parent === window.self) {
      return false;
    }

    return (
      window.parent?.Q_OrgFrequentPulse !== undefined ||
      window.parent?.Q_GWNavigationContext?.hideEntireNavInChildIframe === true
    );
  } catch {
    // in case we're inside an iframe with cross-origin blocked and we cannot access parent
    return false;
  }
};

export const shouldHideTier1NavInChildIframe = () => {
  try {
    if (window.parent === window.self) {
      return false;
    }

    return (
      shouldHideEntireNavInChildIframe() ||
      window.parent?.Q_GWNavigationContext?.hideTier1NavInChildIframe === true
    );
  } catch {
    // in case we're inside an iframe with cross-origin blocked and we cannot access parent
    return false;
  }
};
