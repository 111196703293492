import React, { useEffect, useRef, useState } from 'react';
import { BellIcon } from '@qualtrics/ui-react/icons';
import IconButton from '../IconButton/IconButton';
import { BellIcon as PortalBellIcon } from '../icons';
import { getTier1DataTestId } from '../../Service/testing';
import { messageService } from '../../Service/message-service';
import { useScreenType } from '../hooks/useScreenType';
import { usePortalsInfo } from '../../Context';

export default function Notifications() {
  const { isMobileView } = useScreenType();
  const { isPortalsApplication } = usePortalsInfo();
  const [areNotificationsVisible, setAreNotificationsVisible] = useState(false);
  const buttonRef = useRef();
  const checkIfAreNotificationsVisible = (notifications) =>
    notifications.style.display.toLowerCase() !== 'none';
  const setMutationObserver = (notifications) => {
    const changeObserver = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        setAreNotificationsVisible(
          mutation.attributeName === 'style' &&
            checkIfAreNotificationsVisible(notifications),
        );
      });
    });

    changeObserver.observe(notifications, {
      attributeFilter: ['style'],
    });
  };

  const retryPerformingActionOnNotifications = (action) => {
    // eslint-disable-next-line angular/interval-service
    const intervalId = window.setInterval(() => {
      const notifications = document.getElementById('xm-feed-iframe');
      if (notifications) {
        action(notifications);
        window.clearInterval(intervalId);
      }
    }, 100);
  };

  const observeNotifications = () => {
    retryPerformingActionOnNotifications((notifications) => {
      setAreNotificationsVisible(checkIfAreNotificationsVisible(notifications));
      setMutationObserver(notifications);
    });
  };

  useEffect(() => {
    buttonRef.current.addEventListener('click', observeNotifications);
  }, [buttonRef]);

  return (
    <IconButton
      tooltipContent={messageService.get('NOTIFICATIONS')}
      id="xm-feed-placeholder-v2"
      data-testid={getTier1DataTestId('Notifications')}
      aria-label={messageService.get('NOTIFICATIONS')}
      style={isMobileView ? { display: 'none' } : undefined}
      zIndex={10002}
      active={areNotificationsVisible}
      ref={buttonRef}
    >
      {isPortalsApplication ? (
        <PortalBellIcon aria-hidden={true} />
      ) : (
        <BellIcon aria-hidden={true} />
      )}
    </IconButton>
  );
}
