import React from 'react';
import { ItemSelector } from '@qualtrics/resource-list-selector';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from '../breadcrumb.scss';
import { messageService } from '../../Service/message-service';

export default function DefaultBreadcrumb({
  projectId,
  projectName,
  restrictedNavigation,
  language,
}) {
  const handleItemSelect = (project) => {
    window.location = project.navContext.url;
  };

  return (
    <ItemSelector
      className={classnames(styles.anchorButton, {
        [styles.disabled]: restrictedNavigation,
      })}
      clientId={'global-wrapper'}
      query={{
        includeNavContext: true,
      }}
      language={language}
      preloadData={false}
      onItemSelect={handleItemSelect}
      selectedItemId={projectId}
      labelText={projectName}
      disabled={restrictedNavigation}
      aria-label={`${messageService.get('PROJECT_SWITCHER')}`}
      selectionItemsTypes={'leaves'}
    />
  );
}

DefaultBreadcrumb.propTypes = {
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  restrictedNavigation: PropTypes.bool,
  language: PropTypes.string,
};
