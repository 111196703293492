import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './icon.scss';

const IconSizes = {
  small: 24,
  medium: 32,
  large: 40,
};

const createIconComponent = (type, svgContent) => {
  const PortalIcon = forwardRef(
    (
      { size = 'medium', className, 'aria-hidden': ariaHidden, ...rest },
      ref,
    ) => {
      const classes = className ? `${styles.icon} ${className}` : styles.icon;
      const pixels = IconSizes[size];

      return (
        <svg
          {...rest}
          className={classes}
          ref={ref}
          width={pixels}
          height={pixels}
          focusable="false"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          fill={'none'}
          data-icontype={type}
          aria-hidden={ariaHidden}
          dangerouslySetInnerHTML={{ __html: svgContent }} // eslint-disable-line react/no-danger
        />
      );
    },
  );
  PortalIcon.displayName = `${type}Icon`;
  PortalIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    'aria-hidden': PropTypes.bool,
  };
  return PortalIcon;
};

export default createIconComponent;
