import { useEffect, useState } from 'react';

export const useMenuCloser = (subscribeToMenuClosing) => {
  const [menuClosingFunctions, setMenuClosingFunctions] = useState([]);

  const addClosingFunction = (closingFunction) => {
    setMenuClosingFunctions((prevState) => [...prevState, closingFunction]);
  };

  useEffect(() => {
    if (subscribeToMenuClosing) {
      subscribeToMenuClosing(() => menuClosingFunctions.forEach((f) => f()));
    }
  }, [subscribeToMenuClosing, menuClosingFunctions]);

  return addClosingFunction;
};
