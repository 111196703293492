import React from 'react';
import styles from './top-level-nav.scss';
import { messageService } from '../Service/message-service';
import { getTier1DataTestId } from '../Service/testing';
import { XMLogo } from './Home/xmLogo';
import { ImportantMessagesBadge } from './ImportantMessages';
import AccountMenu from './AccountMenu/AccountMenu';
import WaffleMenu from './WaffleMenu/WaffleMenu';
import { useWorkMode } from '../Context';
import PropTypes from 'prop-types';

export const AccountSettingsTopLevelNav = ({ zIndex }) => {
  const { areImportantMessagesEnabled } = useWorkMode();

  return (
    <nav
      className={styles.topLevelNav}
      aria-label={messageService.get('PRIMARY')}
      data-testid={getTier1DataTestId('SettingsSimplifiedContainer')}
    >
      <div className={styles.leftSection}>
        <XMLogo />
      </div>
      <div className={styles.rightSection}>
        {areImportantMessagesEnabled && <ImportantMessagesBadge />}
        <AccountMenu zIndex={zIndex} />
        <WaffleMenu zIndex={zIndex} />
      </div>
    </nav>
  );
};

AccountSettingsTopLevelNav.propTypes = {
  zIndex: PropTypes.number,
};
