import React from 'react';
import { MenuItem } from '@qualtrics/ui-react';
import classNames from 'classnames';
import styles from './ErrorComponent.scss';
import { ErrorIcon } from '@qualtrics/ui-react/icons';
import { messageService } from '../../Service/message-service';
import { getTier1DataTestId } from '../../Service/testing';

export const getErrorMessage = (
  mainMessage = '',
  tryAgainButton = undefined,
) => {
  const indexOfTryAgain = mainMessage.indexOf('%1');
  if (indexOfTryAgain < 0) {
    return '';
  }
  return (
    <>
      {mainMessage.slice(0, indexOfTryAgain)}
      {tryAgainButton}
      {mainMessage.slice(indexOfTryAgain + 2, mainMessage.length)}
    </>
  );
};

export const getErrorComponent = (isMobileView, reloadTabs, className) => {
  const onRetryLabelKeyDown = (event) => {
    if (event.key === 'Enter') {
      reloadTabs();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onErrorItemFocus = (event) => {
    event.preventDefault();
    event.stopPropagation();
    retryErrorRef.current?.focus();
  };
  const onErrorItemClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onRetryClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    reloadTabs();
  };

  const retryErrorRef = React.createRef();
  return (
    <MenuItem
      className={classNames(className, styles.errorItem, {
        [styles.menuOptionSmallScreens]: isMobileView,
      })}
      onFocus={onErrorItemFocus}
      onClick={onErrorItemClick}
      key="menu-item-retry-on-error"
    >
      <ErrorIcon aria-hidden="true" size="small" />
      <div className={styles.errorText} role="alert">
        {getErrorMessage(
          messageService.get('ERROR_WHILE_LOADING'),
          <span
            className={styles.tryAgainButton}
            ref={retryErrorRef}
            tabIndex={0}
            role={'button'}
            onClick={onRetryClick}
            onKeyDown={onRetryLabelKeyDown}
            data-testid={getTier1DataTestId('Account-Menu-Error-TryAgain')}
          >
            {messageService.get('TRY_AGAIN')}
          </span>,
        )}
      </div>
    </MenuItem>
  );
};
