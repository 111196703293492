import {
  getAccountInfo,
  getAccountSettings,
  getLogout,
  getMenuOptions,
  getMobileViewComponents,
  getRefreshAccount,
  getUpgradePlan,
  LoadingSpinnerWrapper,
} from './MenuItems';
import styles from './account-menu.scss';
import { getErrorComponent } from '../CommonMenuItems/getErrorComponent';
import { useAccountService, usePortalsInfo, useUserInfo } from '../../Context';
import {
  useAccountSettings,
  useTabsInitialization,
} from '../../hooks/useTabs/useTabs';
import { useScreenType } from '../hooks/useScreenType';
import React, { useMemo } from 'react';

export const useAccountMenuElements = (
  setButtonActive,
  notificationsAmountAria,
  unreadBadgeValue,
  shouldDisplayNotifications,
  shouldDisplayHelp,
) => {
  const { accountActionsService, accountOptions } = useAccountService();
  const { reloadTabs } = useTabsInitialization();
  const { accountSettingsSection, accountSettingsState } = useAccountSettings();
  const { isPortalsApplication, participantPortal: languageProps } =
    usePortalsInfo();
  const { brandName, fullName, isLanguageJapaneseOrKorean, isFreeAccount } =
    useUserInfo();
  const { isMobileView } = useScreenType();

  const handlers = useMemo(
    () => ({
      handleLogout: () => accountActionsService.logout(),
      handleRefreshAccount: () => accountActionsService.refresh(),
      handleGoToAccountSettings: (accountSettingsSection) =>
        accountActionsService.accountSettings(
          accountSettingsSection,
          isPortalsApplication,
        ),
      handleGoToPlansPage: () => {
        window.location.href = '/dg-service/plan-pricing?source=accountMenu';
      },
      handleIsOpenChange: (isOpen) => {
        if (isOpen) {
          accountActionsService.onMenuOpen();
        }
        setButtonActive(isOpen);
      },
    }),
    [accountActionsService, isPortalsApplication, setButtonActive],
  );

  const statusElementsMap = useMemo(
    () => ({
      loaded: () => [
        getAccountInfo(
          isPortalsApplication,
          isLanguageJapaneseOrKorean,
          fullName,
          brandName,
        ),
        getMenuOptions(accountOptions),
        getAccountSettings(
          () => handlers.handleGoToAccountSettings(accountSettingsSection),
          isPortalsApplication,
          accountSettingsSection,
        ),
        getUpgradePlan(handlers.handleGoToPlansPage, isFreeAccount),
        getRefreshAccount(handlers.handleRefreshAccount),
        getMobileViewComponents(
          isMobileView,
          notificationsAmountAria,
          unreadBadgeValue,
          languageProps,
          shouldDisplayNotifications,
          shouldDisplayHelp,
        ),
        getLogout(handlers.handleLogout),
      ],
      // eslint-disable-next-line react/display-name
      loading: () => (
        <LoadingSpinnerWrapper
          isMobileView={isMobileView}
          className={styles.loadingSpinner}
          testIdPrefix={'AccountMenu'}
        />
      ),
      error: () => [
        getAccountInfo(
          isPortalsApplication,
          isLanguageJapaneseOrKorean,
          fullName,
          brandName,
        ),
        getErrorComponent(isMobileView, reloadTabs, styles.errorItem),
        getMenuOptions(accountOptions),
        getUpgradePlan(handlers.handleGoToPlansPage, isFreeAccount),
        getRefreshAccount(handlers.handleRefreshAccount),
        getMobileViewComponents(
          isMobileView,
          notificationsAmountAria,
          unreadBadgeValue,
          languageProps,
        ),
        getLogout(handlers.handleLogout),
      ],
    }),
    [
      isPortalsApplication,
      isLanguageJapaneseOrKorean,
      fullName,
      brandName,
      accountOptions,
      accountSettingsSection,
      handlers,
      isFreeAccount,
      isMobileView,
      notificationsAmountAria,
      unreadBadgeValue,
      languageProps,
      reloadTabs,
    ],
  );

  return { currentElements: statusElementsMap[accountSettingsState], handlers };
};
