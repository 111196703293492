import React from 'react';
import ReactDOM from 'react-dom';
import ReactContainer from './Container/ReactContainer';

export function ReactHeaderController($element, $transclude) {
  this.$onChanges = () => {
    const container = $element.find('global-wrapper-top-nav')[0];

    $transclude((clone, scope) => {
      ReactDOM.render(
        <ReactContainer
          angularBreadcrumb={getAngularBreadcrumb(
            this.showTranscludedSelector,
            this.showDefaultSelector,
            clone,
            scope,
          )}
          language={this.language}
          context={this.context}
          pageId={this.pageId}
          options={this.options}
        />,
        container,
      );
    });
  };
}

ReactHeaderController.$inject = ['$element', '$transclude'];

const ReactHeader = {
  bindings: {
    language: '<',
    context: '<',
    pageId: '<',
    options: '<',
    //Angularjs will miss updates to the options object's properties. This is because the object address doesn't change.
    //This line forces angularjs to check for updates to theme within the options object
    theme: '<',
    showTranscludedSelector: '<',
    showDefaultSelector: '<',
  },
  controller: ReactHeaderController,
  template: `<global-wrapper-top-nav></global-wrapper-top-nav>`,
  transclude: true,
};

const getAngularBreadcrumb = (
  showTranscludedSelector,
  showDefaultSelector,
  clone,
  scope,
) => {
  if (showTranscludedSelector) {
    return { clone, scope };
  }

  if (showDefaultSelector) {
    return undefined;
  }

  return document.createElement('div');
};

export default ReactHeader;
