import React from 'react';
import styles from '../top-level-nav.scss';
import { messageService } from '../../Service/message-service';
import { XMLogo } from '../Home/xmLogo';
import { ImportantMessagesBadge } from '../ImportantMessages';
import Help from '../Help/Help';
import Notifications from '../Notifications/Notifications';
import { usePortalsInfo, useWorkMode } from '../../Context';
import { useScreenType } from '../hooks/useScreenType';
import PropTypes from 'prop-types';

/**
 * @typedef {Object} RestrictedNavigationTopLevelNavProps
 * @property {import(react).Element} conditionallyRenderedBreadcrumb
 */

/**
 * @type {import(react).FC<RestrictedNavigationTopLevelNav>}
 */
export const RestrictedNavigationTopLevelNav = ({
  conditionallyRenderedBreadcrumb,
}) => {
  const { areImportantMessagesEnabled } = useWorkMode();
  const { isMobileView } = useScreenType();
  const { isPortalsApplication } = usePortalsInfo();

  return (
    <nav
      className={styles.topLevelNav}
      aria-label={messageService.get('PRIMARY')}
    >
      <div className={styles.leftSection}>
        <XMLogo />
        {conditionallyRenderedBreadcrumb}
      </div>
      <div className={styles.rightSection}>
        {areImportantMessagesEnabled && (
          <ImportantMessagesBadge
            isMobileView={isMobileView}
            isInPortalApp={isPortalsApplication}
          />
        )}
        <Help />
        <Notifications />
      </div>
    </nav>
  );
};

RestrictedNavigationTopLevelNav.propTypes = {
  conditionallyRenderedBreadcrumb: PropTypes.element,
};
