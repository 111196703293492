import React from 'react';
export const XMLogo = () => {
  return (
    <svg
      aria-hidden
      width="37"
      height="20"
      viewBox="0 0 37 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="GW-home-xm-logo"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.891 14.6956L31.0473 0.0115314H37V20H32.7537V6.38146H32.7006C32.5779 7.1586 32.4129 7.92849 32.2064 8.68773C32.0171 9.37269 31.8139 10.0853 31.5945 10.821L28.5928 19.9885H24.7044L21.7373 10.821C21.6311 10.4451 21.5295 10.0899 21.4279 9.7809C21.3263 9.47186 21.2316 9.13284 21.1438 8.77998C21.0561 8.42482 20.9661 8.04044 20.8737 7.62684C20.7813 7.21325 20.682 6.7174 20.5758 6.1393H20.525V19.9769H12.9606L8.71661 12.9958L4.61807 19.9654H0L6.39603 9.41651L0.568023 0H5.57632L9.15071 5.99631L12.5265 0.0115314H22.3815L26.8379 14.6956H26.891ZM16.5812 0.807196L11.3651 9.45572L16.5812 17.9313V0.807196Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-15.2807"
          y1="8.34346"
          x2="-3.44561"
          y2="37.6019"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04" stopColor="#21DBAA" />
          <stop offset="0.36" stopColor="#00B4EF" />
          <stop offset="0.43" stopColor="#01A8EC" />
          <stop offset="0.57" stopColor="#0487E4" />
          <stop offset="0.68" stopColor="#0768DD" />
          <stop offset="0.96" stopColor="#5F1AE5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
