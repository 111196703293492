import React, { useEffect, useState } from 'react';
import SkipToMainContentLink from '../../Accessibility/SkipToMainContentLink/SkipToMainContentLink';
import PropTypes from 'prop-types';
import { usePortalsInfo, useWorkMode } from '../../Context';

export default function SkipToMainContentAttacher({ mainContentId }) {
  const { isPortalsChild, isPortalsApplication } = usePortalsInfo();
  const { isSkipToMainContentEnabled } = useWorkMode();
  const [shouldShowSkipToMainContentLink, setShouldShowSkipToMainContentLink] =
    useState(false);

  const enableSkipToMainContent = () => {
    const mainContentExists =
      !!mainContentId ||
      !!document.querySelector('[role="main"]') ||
      !!document.getElementsByTagName('main').length > 0;

    if (isSkipToMainContentEnabled && !isPortalsChild && mainContentExists) {
      setShouldShowSkipToMainContentLink(true);
    }
  };

  useEffect(() => {
    enableSkipToMainContent();
  }, []);

  return shouldShowSkipToMainContentLink ? (
    <SkipToMainContentLink
      mainContentId={mainContentId}
      isPortalsApplication={isPortalsApplication}
    />
  ) : null;
}

SkipToMainContentAttacher.propTypes = {
  mainContentId: PropTypes.string,
};
