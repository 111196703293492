export default function scriptLoadService() {
  var service = {
    loadScript: loadScript,
    runOnLoad: runOnLoad,
  };

  return service;

  function loadScript(scriptUrl, id, context) {
    var script = window.document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = scriptUrl;
    if (id !== undefined) {
      script.id = id;
    }

    if (context !== undefined) {
      script.setAttribute('data-context', JSON.stringify(context));
    }

    var scriptLoadPromise = new Promise((resolve, reject) => {
      script.onload = resolve;
      script.onerror = reject;
    });

    var firstScript = window.document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    return scriptLoadPromise;
  }

  function runOnLoad(callback) {
    if (
      window.document.readyState === 'interactive' ||
      window.document.readyState === 'complete'
    ) {
      callback();
    } else {
      window.document.addEventListener('DOMContentLoaded', callback);
    }
  }
}
