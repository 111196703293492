import { sendTractiveMetric } from '@qualtrics/page-metrics/lib/tractiveService';

export function log(superSectionId, sectionId, subSectionId) {
  // ensure that we have at least super section provided
  if (!superSectionId) {
    return;
  }

  let key = superSectionId;
  const props = {
    amplitudeEvent: 'navigation',
    amplitudeSuperSection: superSectionId,
  };

  // preserving special logic for custom tabs
  if (superSectionId === 'CustomTabSection') {
    if (sectionId) {
      props.amplitudeSection = sectionId;
      sectionId = 'CustomSection';
      if (subSectionId) {
        props.amplitudeSubSection = subSectionId;
        subSectionId = 'CustomSection';
      }
    }
  }

  if (sectionId) {
    key += '.' + sectionId;
    props.amplitudeSection = props.amplitudeSection || sectionId;
  }

  if (sectionId && subSectionId) {
    key += '.' + subSectionId;
    props.amplitudeSubSection = props.amplitudeSubSection || subSectionId;
  }

  key = key.replace(/\s+/g, '');
  key += '.Click'; // suffix

  try {
    sendTractiveMetric({
      billingTeam: 'GW',
      eventName: key,
      customAttributes: props,
    });
  } catch (err) {
    console.error(err);
  }
}
