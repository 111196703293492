/** @prettier */
/* eslint indent: "off" */

import expressions from '../../../react/navigation/Service/expressions';

var variableMap = {
  panelLibraryId: 'PanelLibraryID',
  projectId: 'ProjectID',
  projectType: 'ProjectType',
  resourceType: 'ResourceType',
  subresourceId: 'SubresourceID',
  resourceId: 'ResourceID',
  surveyId: 'SurveyID',
  libraryId: 'LibraryID',
  directoryId: 'DirectoryID',
  pluginId: 'PluginID',
};

// parseTemplate replace context placeholders in the provided template.
function parseTemplate(ctx, template, useV2Params) {
  var localCtx = ctx;
  var localTemplate = template;
  if (useV2Params) {
    localTemplate = expressions.replaceTemplateVars(localTemplate);
  }
  if (localCtx === undefined) {
    localCtx = {};
  }
  Object.keys(variableMap).forEach(function (key) {
    var varName = variableMap[key];
    if (varName !== undefined) {
      var varRegExp = new RegExp('{{\\.' + varName + '}}', 'g');
      localTemplate = localTemplate.replace(varRegExp, localCtx[key] || '');
    }
  });

  return localTemplate;
}

const exports = {
  parseTemplate,
};

export default exports;
