import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@qualtrics/ui-react';
import { WaffleMenuIcon } from '@qualtrics/ui-react/icons';
import styles from './waffle-menu.scss';
import IconButton from '../IconButton/IconButton';
import { WaffleMenuIcon as PortalWaffleMenuIcon } from '../icons';
import { messageService } from '../../Service/message-service';
import {
  getSwitchPortalUrl,
  QUALTRICS_ID,
  switchPortalFromMenu,
} from '../../Service/portals-service';
import { getTier1DataTestId } from '../../Service/testing';
import { navigateAfterEnterClick } from '../../Service/navigation-service';
import { useWindowSize } from '../hooks/useWindowSize';
import classNames from 'classnames';
import { useAccountService, usePortalsInfo, useWorkMode } from '../../Context';
import { useScreenType } from '../hooks/useScreenType';
import { useAddClosingFunction } from '../../Context/useAddClosingFunction';

export default function WaffleMenu({ zIndex }) {
  const { isMobileView } = useScreenType();
  const [buttonActive, setButtonActive] = React.useState(false);
  const windowSize = useWindowSize();
  const { currentPortalId, currentInstanceId, isPortalsApplication, portals } =
    usePortalsInfo();
  const { debugMode } = useWorkMode();
  const { accountActionsService } = useAccountService();
  const { addClosingFunction } = useAddClosingFunction();

  const waffleButtonAriaLabel = useMemo(
    () =>
      buttonActive
        ? messageService.get('PORTAL_SWITCH_ACCOUNT_MENU')
        : messageService.get('SWITCHER'),
    [buttonActive],
  );

  const filteredAvailablePortals = portals.filter(
    (portal) => portal.id !== QUALTRICS_ID,
  );

  const bigQualtrics = portals.find((portal) => portal.id === QUALTRICS_ID);

  const shouldDisplayPortals =
    portals &&
    (portals.length > 1 ||
      portals.find(
        (portal) =>
          portal.id === currentPortalId &&
          portal.instanceId === currentInstanceId,
      ) === undefined);

  const handleIsOpenChange = (isOpen) => {
    if (isOpen) {
      accountActionsService.onAppSwitchMenuOpen();
    }
    setButtonActive(isOpen);
  };

  function getPortalButton(portal) {
    if (!portal) {
      return undefined;
    }
    const isActive =
      portal.id === currentPortalId && portal.instanceId === currentInstanceId;

    return (
      <MenuItem
        onSelect={(e) => {
          switchPortalFromMenu(portal.id, portal.instanceId, currentPortalId);
          navigateAfterEnterClick(
            e,
            getSwitchPortalUrl(portal, currentPortalId, portals),
          );
        }}
        data-testid={getTier1DataTestId(`Waffle-Menu-PortalItem-${portal.id}`)}
        data-active={isActive || null}
        aria-current={isActive ? true : undefined}
        key={
          portal.instanceId ? `${portal.id}-${portal.instanceId}` : portal.id
        }
        className={classNames(
          isActive
            ? styles.portalContainerActive
            : styles.portalContainerInactive,
          { [styles.portalContainerOnMobile]: isMobileView },
        )}
      >
        <a
          href={getSwitchPortalUrl(portal, currentPortalId, portals)}
          className={styles.portalName}
        >
          {portal.instanceName || portal.name}
        </a>
      </MenuItem>
    );
  }

  useEffect(() => {
    addClosingFunction(() => {
      setButtonActive(false);
    });
  }, []);

  if (!shouldDisplayPortals) {
    return null;
  }

  return (
    <Menu
      flyoutClassName={classNames(
        isPortalsApplication ? styles.waffleMenuInPortal : styles.waffleMenu,
        { [styles.fullMenuFlyout]: isMobileView },
      )}
      disabled={debugMode}
      onIsOpenChange={handleIsOpenChange}
      isOpen={buttonActive}
      zIndex={zIndex}
      anchor={
        <IconButton
          tooltipContent={messageService.get('SWITCHER')}
          active={buttonActive}
          data-testid={getTier1DataTestId('WaffleMenu')}
          aria-label={waffleButtonAriaLabel}
          zIndex={10002}
        >
          {isPortalsApplication ? (
            <PortalWaffleMenuIcon
              data-testid={'Portals-Waffle-Menu'}
              aria-hidden={true}
            />
          ) : (
            <WaffleMenuIcon
              data-testid={'Big-Qualtrics-Waffle-Menu'}
              aria-hidden={true}
            />
          )}
        </IconButton>
      }
      maxHeight={windowSize.height - 84}
    >
      {
        <div
          className={
            isPortalsApplication
              ? styles.switchLabel
              : styles.switchLabelInStudio
          }
          data-testid={getTier1DataTestId('Waffle-Menu-PortalSwitcher')}
        >
          {messageService.get('PORTAL_SWITCH_ACCOUNT_MENU')}
        </div>
      }

      {bigQualtrics && getPortalButton(bigQualtrics)}

      {shouldDisplayPortals &&
        filteredAvailablePortals &&
        filteredAvailablePortals.map((portal) => getPortalButton(portal))}
    </Menu>
  );
}

WaffleMenu.propTypes = {
  zIndex: PropTypes.number,
};
