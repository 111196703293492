import React, { useEffect } from 'react';
import { ArrowLeftIcon, GlobeIcon } from '@qualtrics/ui-react/icons';
import { Menu, MenuItem, Divider } from '@qualtrics/ui-react';
import IconButton from '../IconButton/IconButton';
import PropTypes from 'prop-types';
import { messageService } from '../../Service/message-service';
import styles from '../AccountMenu/account-menu.scss';
import classNames from 'classnames';
import { getTier1DataTestId } from '../../Service/testing';
import { useScreenType } from '../hooks/useScreenType';
import { usePortalsInfo } from '../../Context';
import { useAddClosingFunction } from '../../Context/useAddClosingFunction';

export default function PersonLanguage({ zIndex }) {
  const [buttonActive, setButtonActive] = React.useState(false);
  const { isMobileView } = useScreenType();
  const { participantPortal } = usePortalsInfo();
  const { addClosingFunction } = useAddClosingFunction();

  useEffect(() => {
    addClosingFunction(() => {
      setButtonActive(false);
    });
  }, []);

  if (!participantPortal) {
    return null;
  }

  const { menu, action } = participantPortal;
  const handleIsOpenChange = (isOpen) => {
    setButtonActive(isOpen);
  };

  return (
    <Menu
      onIsOpenChange={handleIsOpenChange}
      isOpen={buttonActive}
      anchor={
        <IconButton
          id={'global-wrapper-person-language-switcher'}
          active={buttonActive}
          tooltipContent={messageService.get('LANGUAGE')}
          aria-label={messageService.get('LANGUAGE_SWITCHER')}
          data-testid={getTier1DataTestId('Language')}
          style={isMobileView ? { display: 'none' } : undefined}
          zIndex={10002}
        >
          <GlobeIcon />
        </IconButton>
      }
      flyoutClassName={classNames({
        [styles.fullMenuFlyoutLanguage]: isMobileView,
      })}
      zIndex={zIndex}
      placement="bottom-end"
    >
      {isMobileView && [
        <MenuItem
          key={'language-back'}
          value={'language-back'}
          onSelect={() =>
            document.getElementById(getTier1DataTestId('Account')).click()
          }
          className={classNames(styles.menuItemPortalsLanguage)}
        >
          <ArrowLeftIcon
            className={styles.leftArrowIcon}
            size={'small'}
            aria-hidden={true}
          />
          {messageService.get('LANGUAGE_SWITCHER')}
        </MenuItem>,
        <Divider key={'language-back-divider'} />,
      ]}
      {menu.map((item) => {
        return (
          <MenuItem
            key={item.value}
            value={item.value}
            onSelect={(event) => action(item.value, item, event)}
            style={isMobileView ? undefined : { width: 300 }}
            className={classNames(
              item.selected
                ? styles.menuItemPortalsActive
                : styles.menuItemPortalsInactive,
            )}
          >
            {item.label}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

PersonLanguage.propTypes = {
  zIndex: PropTypes.number,
};
