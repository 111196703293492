import SessionStorageManager from './session-storage-manager';

// globalWrapperCache is an http cache implementing $cacheFactory.Cache that caches across page loads using the browser's sessionStorage
export default class GlobalWrapperCache {
  constructor() {
    const sessionStorageManager = new SessionStorageManager(window);
    this.KEY_PREFIX = 'http_';
    this.cache = httpSessionCache(sessionStorageManager, this.KEY_PREFIX);
  }

  put(key, value) {
    return this.cache.put(key, value);
  }

  get(key) {
    return this.cache.get(key);
  }

  remove(key) {
    return this.cache.remove(key);
  }

  removeAll() {
    return this.cache.removeAll();
  }

  removeAllPortalConnectedItems() {
    return this.cache.removeAllPortalConnectedItems();
  }
}

// httpSessionCache uses session storage to cache http requests across page
// loads.
// Private, unexported. Implements $cacheFactory.Cache.
function httpSessionCache(sessionStorageManager, keyPrefix) {
  const promiseStore = {};
  return {
    put: put,
    get: get,
    remove: remove,
    removeAll: removeAll,
    removeAllPortalConnectedItems: removeAllPortalConnectedItems,
  };

  function put(key, value) {
    // $http stores a promise while the request is in flight.
    // These won't jsonify so we'll store them separately.
    // Deleting from the opposite store avoids stale data interference.
    if (isPromiseLike(value)) {
      promiseStore[key] = value;
      sessionStorageManager.removeSessionStorage(key);
    } else {
      delete promiseStore[key];
      key = keyPrefix + key;
      const json = JSON.stringify(value);
      sessionStorageManager.setSessionStorage(key, json);
    }

    function isPromiseLike(obj) {
      return obj && typeof obj.then === 'function';
    }
  }

  function get(key) {
    if (promiseStore.hasOwnProperty(key)) {
      return promiseStore[key];
    } else {
      key = keyPrefix + key;
      const val = sessionStorageManager.getSessionStorage(key);
      try {
        // coerce session storage not found (null) to $http not found (undefined)
        return val === null ? undefined : JSON.parse(val);
      } catch (e) {
        // our stored value is hosed, let's wipe it
        sessionStorageManager.removeSessionStorage(key);
        return null;
      }
    }
  }

  function remove(key) {
    delete promiseStore[key];
    sessionStorageManager.removeSessionStorage(keyPrefix + key);
  }

  function removeAll() {
    sessionStorageManager.clearSessionStorage();
  }

  function removeAllPortalConnectedItems() {
    sessionStorageManager.clearPortalsSessionStorage();
  }
}

// uses singleton. may not be need now that the func `turnOffCrossPageCache` (that mutates the internal state of the class) no longer exists.
const globalWrapperCache = new GlobalWrapperCache();

export { globalWrapperCache };
