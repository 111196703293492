// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".shell-footer_footer__a6TKD{font-size:13px;border-top:1px solid #A5A5A5;display:flex;justify-content:center;align-items:center;position:relative}.shell-footer_brandLogo__3XT67{position:absolute;top:8px;left:24px;max-height:68px;max-width:200px}.shell-footer_contactInfo__1gmKc{display:flex;flex-grow:1;justify-content:center}.shell-footer_link__398ap{margin:30px 10px;color:#6a6d70;text-decoration:none}.shell-footer_link__398ap:visited{color:#6a6d70}.shell-footer_link__398ap:hover{color:#6a6d70;text-decoration:underline;outline:0;cursor:pointer}.shell-footer_link__398ap:focus,.shell-footer_link__398ap:active:focus{color:#6a6d70;outline:none}.shell-footer_link__398ap:focus-visible{box-shadow:0 0 0 2px #fff,0 0 0 4px #0a3f88}@media only screen and (max-width: 767px){.shell-footer_footer__a6TKD{flex-direction:column-reverse}.shell-footer_brandLogo__3XT67{position:relative;margin-bottom:8px}}\n", ""]);
// Exports
exports.locals = {
	"footer": "shell-footer_footer__a6TKD",
	"brandLogo": "shell-footer_brandLogo__3XT67",
	"contactInfo": "shell-footer_contactInfo__1gmKc",
	"link": "shell-footer_link__398ap"
};
module.exports = exports;
