import http from './http';

function getResource(projectId, resourceId) {
  const url =
    '/project-store/ui/nav-contexts/' + projectId + '/resources/' + resourceId;

  return http
    .getJSON(url)
    .then((resourceObject) => {
      return resourceObject;
    })
    .catch((err) => {
      console.error('failed to get resource', err);
    });
}

export { getResource };
