import cookies from './cookies';
import http from './http';

let getDashboardTypePromise = {};
export const getDashboardType = async (dashboardId, cache) => {
  const dashboardsUrl = `/reporting-dashboard/ripsaw/containers/${dashboardId}`;
  const sessionId = cookies.get('UDSSessionKey');
  const key = `${dashboardsUrl}&UDSSessionKey=${sessionId}`;
  const cacheData = cache.getSessionStorage(key);

  if (cacheData) {
    return cacheData;
  } else {
    if (!getDashboardTypePromise.hasOwnProperty(dashboardId)) {
      getDashboardTypePromise[dashboardId] = http
        .getJSON(dashboardsUrl)
        .then((response) => {
          if (response.product) {
            cache.setSessionStorage(key, response.product);
          }
          return response.product;
        })
        .catch(() => undefined);
    }

    return getDashboardTypePromise[dashboardId];
  }
};
