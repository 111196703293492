// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".switcher_superSectionMenu__3odXp{margin-left:4px}.switcher_fullMenuFlyout__16_05{width:calc(100% - 16px);max-width:480px}.switcher_errorItem__2Y8BY{width:243px;margin:4px 8px}.switcher_menuLoading__Z1ocI{width:250px}\n", ""]);
// Exports
exports.locals = {
	"superSectionMenu": "switcher_superSectionMenu__3odXp",
	"fullMenuFlyout": "switcher_fullMenuFlyout__16_05",
	"errorItem": "switcher_errorItem__2Y8BY",
	"menuLoading": "switcher_menuLoading__Z1ocI"
};
module.exports = exports;
