import IconButton from '../../IconButton/IconButton';
import { messageService } from '../../../Service/message-service';
import { getTier1DataTestId } from '../../../Service/testing';
import { HamburgerMenuIcon } from '@qualtrics/ui-react/icons';
import React from 'react';

export const getSwitcherAnchor = (buttonActive) => {
  return (
    <IconButton
      aria-label={messageService.get('MENU')}
      active={buttonActive}
      data-testid={getTier1DataTestId('ContextSwitcher')}
      tooltipContent={messageService.get('MENU')}
      zIndex={10002}
    >
      <HamburgerMenuIcon />
    </IconButton>
  );
};
