// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".home_home__1ohL1{min-width:54px;height:40px;display:flex;justify-content:center;align-items:center;cursor:pointer;border-radius:5px;position:relative;border:none;background:none}.home_home__1ohL1.home_centered__3qHaC{margin:auto;translate:30px}.home_home__1ohL1:after{content:'';position:absolute;top:-2px;right:-2px;bottom:-2px;left:-2px}.home_home__1ohL1:focus{outline:none;text-decoration:none}.home_home__1ohL1:focus-visible{box-shadow:0 0 0 2px #fff,0 0 0 4px #0a3f88}.home_themeLogo__oUznI{max-height:20px;max-width:100px;margin:0px 10px 0px 10px}.home_themeLogo__oUznI.home_portalThemeLogo__2VKcb{max-height:32px}.home_bigLogo__FN4M3{max-height:32px !important}\n", ""]);
// Exports
exports.locals = {
	"home": "home_home__1ohL1",
	"centered": "home_centered__3qHaC",
	"themeLogo": "home_themeLogo__oUznI",
	"portalThemeLogo": "home_portalThemeLogo__2VKcb",
	"bigLogo": "home_bigLogo__FN4M3"
};
module.exports = exports;
