import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  getAllTabs,
  getCurrentTab,
  getSection,
  getSections,
} from '../../Service/tablist';
import PropTypes from 'prop-types';

const emptyFn = () => {};

export const TabsContext = createContext({
  tabs: {
    superTabs: undefined,
    hasSectionsWithError: false,
    activeSuperTab: undefined,
    areSectionsLoaded: false,
    sections: undefined,
  },
  initialization: {
    loadTabs: emptyFn,
    reloadTabs: emptyFn,
    initializeHideAccountSettings: emptyFn,
  },
  settings: {
    accountSettingsSection: undefined,
    accountSettingsState: 'loading',
  },
});

export const TabsContextWrapper = ({ children }) => {
  const [initialization, tabs, settings] = useCreateTabsContext(
    children.props.language,
  );

  return (
    <TabsContext.Provider value={{ initialization, tabs, settings }}>
      {children}
    </TabsContext.Provider>
  );
};

TabsContextWrapper.propTypes = {
  children: PropTypes.node,
};

export const useTabs = () => {
  const { tabs } = useContext(TabsContext);

  return tabs;
};

export const useAccountSettings = () => {
  const { settings } = useContext(TabsContext);

  return settings;
};

export const useTabsInitialization = () => {
  const { initialization } = useContext(TabsContext);

  return initialization;
};

const useCreateTabsContext = (language) => {
  const [superTabs, setSuperTabs] = useState({ state: 'loading', data: [] });
  const [activeSuperTab, setActiveSuperTab] = useState(undefined);
  const [hideAccountSettings, setHideAccountSettings] = useState(undefined);
  const [accountSettingsState, setAccountSettingsState] = useState('loading');
  const [context, setContext] = useState(undefined);
  const [bundle, setBundle] = useState(undefined);

  const sections = useMemo(() => {
    if (superTabs.state === 'loaded' && superTabs.data) {
      return getSections(superTabs.data);
    }
    return undefined;
  }, [superTabs]);

  const areSectionsLoaded = useMemo(() => !!sections, [sections]);
  const loadTabs = (ctx, bundleData) => {
    const getActiveTabPromise = getCurrentTab(ctx, bundleData, language);
    // as it was decided in HOME-2273, we want to proceed with rendering while hamburger menu is being loaded in order to speed up gw
    // design: https://docs.google.com/document/d/1rudXUBFaBpbKcZo_Yv66RZqOD6Vr62N0fKrKpfE3zLc/edit?usp=sharing
    getAllTabs(ctx, bundleData, language)
      .then((tabs) => {
        setSuperTabs({ state: 'loaded', data: tabs });
      })
      .catch(() => {
        setSuperTabs({ state: 'loaded' });
      });
    setContext(ctx);
    setBundle(bundleData);
    return getActiveTabPromise.then((activeTabs) => {
      const activeTab = activeTabs.find((tab) => !tab.hasError);
      setActiveSuperTab(activeTab);
      return activeTab;
    });
  };

  const accountSettingsSection = useMemo(() => {
    if (areSectionsLoaded && superTabs.data) {
      if (hideAccountSettings) {
        setAccountSettingsState('loaded');
        return undefined;
      }

      const accountSettings =
        getSection('AccountSettingsV2', superTabs.data) ||
        getSection('AccountSettings', superTabs.data);

      if (accountSettings?.hasError) {
        setAccountSettingsState('error');
        return undefined;
      }

      setAccountSettingsState('loaded');
      return accountSettings;
    }

    setAccountSettingsState('loading');
    return undefined;
  }, [superTabs, areSectionsLoaded, hideAccountSettings]);

  const reloadTabs = useCallback(() => {
    if (!bundle || !context) {
      throw new Error(
        'Context and bundle not yet initialized! Call loadTabs before calling reload tabs.',
      );
    }

    setSuperTabs({ state: 'loading' });
    return loadTabs(context, bundle);
  }, [context, bundle]);

  const hasSectionsWithError = useMemo(
    () =>
      superTabs?.data
        ? superTabs.data.filter((tab) => tab?.hasError).length > 0
        : true,
    [superTabs],
  );

  return [
    {
      loadTabs,
      reloadTabs,
      initializeHideAccountSettings: setHideAccountSettings,
    },
    {
      superTabs: superTabs.data,
      hasSectionsWithError,
      activeSuperTab,
      areSectionsLoaded,
      sections,
    },
    {
      accountSettingsSection,
      accountSettingsState,
    },
  ];
};
