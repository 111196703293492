const KEY_PREFIX = 'rsw_';
const PERSIST_ON_CLEAR = ['cobrowsing-session-active'];
const GW_PORTALS_KEY_ELEMENT = '/available-portals';

export default class SessionStorageManager {
  constructor(window) {
    this.window = window;
  }

  /**
   * @param {string} key - will be prefixed with rsw_
   * @param {value} to store
   */
  setSessionStorage(key, value) {
    this.window.sessionStorage.setItem(KEY_PREFIX + key, value);
  }

  /**
   * @param {string}
   */
  getSessionStorage(key) {
    return this.window.sessionStorage.getItem(KEY_PREFIX + key);
  }

  removeSessionStorage(key) {
    this.window.sessionStorage.removeItem(KEY_PREFIX + key);
  }

  clearPortalsSessionStorage() {
    Object.keys(this.window.sessionStorage).forEach((key) => {
      const hasPrefix = key.indexOf(KEY_PREFIX) === 0;
      const isPortalsElement = key.indexOf(GW_PORTALS_KEY_ELEMENT) > -1;

      if (hasPrefix && isPortalsElement) {
        this.window.sessionStorage.removeItem(key);
      }
    });
  }

  /**
   * deletes all keys in sessionStorage that start with our KEY_PREFIX and
   * are not explicitly persisted by PERSIST_ON_CLEAR
   */
  clearSessionStorage() {
    Object.keys(this.window.sessionStorage).forEach((key) => {
      const hasPrefix = key.indexOf(KEY_PREFIX) === 0;
      const shouldPersist =
        PERSIST_ON_CLEAR.indexOf(key.replace(KEY_PREFIX, '')) > -1;

      if (hasPrefix && !shouldPersist) {
        this.window.sessionStorage.removeItem(key);
      }
    });
  }
}
