import { messageService } from '../../Service/message-service';
import React from 'react';
import { messageStruct } from './types';
import styles from './ImportantMessage.scss';
import PropTypes from 'prop-types';

const ListElement = ({ message }) => {
  const label = messageService.get(
    message.descriptionId,
    ...(message.additionalAttributes || []),
  );
  return <li key={message.descriptionId}>{label}</li>;
};

ListElement.propTypes = {
  message: messageStruct,
};

export const MessagesList = ({ messages }) => {
  return (
    <ul className={styles.descriptionList}>
      {messages.map((message) => (
        <ListElement message={message} key={message.descriptionId} />
      ))}
    </ul>
  );
};

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(messageStruct).isRequired,
};
