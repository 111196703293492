/* eslint angular/file-name: "off" */
/* eslint angular/timeout-service: "off" */
import footerTemplateUrl from '../templates/footer.html';

export default function qFooterDirective() {
  var directive = {
    templateUrl: footerTemplateUrl,
    controller: qFooterController,
    scope: {
      userId: '<',
      language: '<',
    },
  };
  return directive;
}

function qFooterController($scope, messageService) {
  if (!$scope.language) {
    getLanguageFromHeader($scope, messageService);
  } else {
    $scope.isLanguageKeySet = true;
  }
}

function getLanguageFromHeader($scope, messageService) {
  const timeoutTime = 2000;

  let messageServiceTimeout = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject();
    }, timeoutTime);
  });

  Promise.race([messageServiceTimeout, messageService.waitForInit()])
    .then(() => {
      $scope.language = messageService.languageKey;
    })
    .catch(() => {
      $scope.language = 'EN';
    })
    .finally(() => {
      $scope.isLanguageKeySet = true;
      $scope.$digest();
    });
}

qFooterController.$inject = ['$scope', 'globalWrapperMessageService'];
