// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".switcherTab_menuOptionSmallScreens__1Y4SB{width:auto !important}.switcherTab_menuOption__1QxC4{box-sizing:border-box;border-radius:6px;width:243px;margin:4px 8px;padding:0 !important;display:flex;align-items:center}.switcherTab_menuOption__1QxC4+.switcherTab_menuOption__1QxC4{margin-top:4px}.switcherTab_menuOption__1QxC4:focus-visible{box-shadow:0 0 0 2px #fff,0 0 0 4px #0a3f88}.switcherTab_menuActiveOption__2n_EN{background-color:rgba(11,110,208,0.0784314) !important}.switcherTab_menuOptionIcon__l7VoJ{fill:#515559;margin-right:8px}.switcherTab_menuActiveOptionIcon__Q51Bq{fill:#0854a1}.switcherTab_menuOptionName__3fZTQ{overflow-x:hidden;width:100%;height:100%;padding:8px 12px !important;max-height:44px;white-space:nowrap;text-overflow:ellipsis;color:#515559 !important;text-decoration:none;display:flex;align-items:center}.switcherTab_menuOptionName__3fZTQ:hover{text-decoration:none}.switcherTab_menuOptionName__3fZTQ:visited{color:#515559 !important}.switcherTab_menuOptionName__3fZTQ:active{color:#515559 !important}.switcherTab_menuActiveOptionName__2JeZF{color:#0854a1 !important;font-family:'72Duplex', '72'}.switcherTab_menuActiveOptionName__2JeZF:visited{color:#0854a1 !important}.switcherTab_menuActiveOptionName__2JeZF:active{color:#0854a1 !important}\n", ""]);
// Exports
exports.locals = {
	"menuOptionSmallScreens": "switcherTab_menuOptionSmallScreens__1Y4SB",
	"menuOption": "switcherTab_menuOption__1QxC4",
	"menuActiveOption": "switcherTab_menuActiveOption__2n_EN",
	"menuOptionIcon": "switcherTab_menuOptionIcon__l7VoJ",
	"menuActiveOptionIcon": "switcherTab_menuActiveOptionIcon__Q51Bq",
	"menuOptionName": "switcherTab_menuOptionName__3fZTQ",
	"menuActiveOptionName": "switcherTab_menuActiveOptionName__2JeZF"
};
module.exports = exports;
