import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@qualtrics/ui-react';
import { useWindowSize } from '../hooks/useWindowSize';
import styles from './switcher.scss';
import classNames from 'classnames';
import { useTabs, useTabsInitialization } from '../../hooks/useTabs/useTabs';
import { getErrorComponent } from '../CommonMenuItems/getErrorComponent';
import { LoadingSpinnerWrapper } from '../CommonMenuItems/LoadingSpinnerWrapper';
import { getSwitcherAnchor, getSwitcherTab } from './SwitcherItems';
import { useScreenType } from '../hooks/useScreenType';
import { useAddClosingFunction } from '../../Context/useAddClosingFunction';

export default function Switcher({ zIndex }) {
  const [buttonActive, setButtonActive] = React.useState(false);
  const windowSize = useWindowSize();
  const { isMobileView } = useScreenType();

  const handleIsOpenChange = (isOpen) => {
    setButtonActive(isOpen);
  };

  const { reloadTabs } = useTabsInitialization();

  const { sections, areSectionsLoaded, activeSuperTab, hasSectionsWithError } =
    useTabs();
  const { addClosingFunction } = useAddClosingFunction();

  useEffect(() => {
    addClosingFunction(() => {
      setButtonActive(false);
    });
  }, []);

  return (
    <div className={styles.superSectionMenu}>
      <Menu
        onIsOpenChange={handleIsOpenChange}
        isOpen={buttonActive}
        flyoutClassName={classNames({
          [styles.fullMenuFlyout]: isMobileView,
        })}
        anchor={getSwitcherAnchor(buttonActive)}
        maxHeight={windowSize.height - 84}
        zIndex={zIndex}
      >
        {areSectionsLoaded ? (
          [
            hasSectionsWithError
              ? getErrorComponent(isMobileView, reloadTabs, styles.errorItem)
              : null,
            sections?.map((section) =>
              getSwitcherTab(section, activeSuperTab, isMobileView),
            ),
          ]
        ) : (
          <LoadingSpinnerWrapper
            isMobileView={isMobileView}
            className={styles.menuLoading}
            testIdPrefix={'Switcher'}
          />
        )}
      </Menu>
    </div>
  );
}

Switcher.propTypes = {
  activeContextType: PropTypes.string,
  zIndex: PropTypes.number,
  debugMode: PropTypes.bool,
  isMobileView: PropTypes.bool,
};
