import { MenuItem } from '@qualtrics/ui-react';
import { getTier1DataTestId } from '../../../Service/testing';
import styles from '../account-menu.scss';
import { messageService } from '../../../Service/message-service';
import React from 'react';

export const getUpgradePlan = (goToPlansPage, isFreeAccount) => {
  if (!isFreeAccount) {
    return null;
  }
  return (
    <MenuItem
      onSelect={goToPlansPage}
      data-testid={getTier1DataTestId('Account-Menu-UpgradePlan')}
      className={styles.menuItemPortals}
      key={getTier1DataTestId('Account-Menu-UpgradePlan')}
    >
      {messageService.get('UPGRADE_PLAN')}
    </MenuItem>
  );
};
