import React from 'react';
import ReactDOM from 'react-dom';
import ShellFooter from './ShellFooter';

export function ReactFooterController($element) {
  const container = $element.find('global-wrapper-react-footer-placeholder')[0];

  this.$onInit = () => {
    ReactDOM.render(<ShellFooter language={this.language} />, container);
  };
}

ReactFooterController.$inject = ['$element'];

const ReactFooter = {
  bindings: {
    language: '<',
  },
  controller: ReactFooterController,
};

export default ReactFooter;
