import React, { useEffect, useState } from 'react';
import { HelpIcon } from '@qualtrics/ui-react/icons';
import { HelpIcon as PortalHelpIcon } from '../icons';
import IconButton from '../IconButton/IconButton';
import { getTier1DataTestId } from '../../Service/testing';
import { messageService } from '../../Service/message-service';
import { useScreenType } from '../hooks/useScreenType';
import { usePortalsInfo } from '../../Context';

const checkIfContainsSupportModal = (node) =>
  node.querySelector?.('#support-modal-header');

const checkIfListContainsSupportModal = (nodesList) =>
  [...nodesList].find((node) => checkIfContainsSupportModal(node));

export default function Help() {
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const { isMobileView } = useScreenType();
  const { isPortalsApplication } = usePortalsInfo();

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          if (checkIfListContainsSupportModal(mutation.addedNodes)) {
            setIsHelpModalVisible(true);
          } else if (checkIfListContainsSupportModal(mutation.removedNodes)) {
            setIsHelpModalVisible(false);
          }
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
    });
  }, []);

  return (
    <IconButton
      id="support-modal-link"
      data-testid={getTier1DataTestId('Help')}
      aria-label={messageService.get('HELP')}
      tooltipContent={messageService.get('HELP')}
      style={isMobileView ? { display: 'none' } : undefined}
      zIndex={10002}
      active={isHelpModalVisible}
    >
      {isPortalsApplication ? (
        <PortalHelpIcon aria-hidden />
      ) : (
        <HelpIcon aria-hidden />
      )}
    </IconButton>
  );
}
