// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon-button_iconButton__wFJTq{display:flex;border-radius:5px;padding:6px;margin:4px;position:relative;fill:#515559;background:none;border:none;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;user-select:none}.icon-button_iconButton__wFJTq:focus,.icon-button_iconButton__wFJTq:active:focus{border-radius:5px;outline:none}.icon-button_iconButton__wFJTq:hover{background-color:rgba(11,110,208,0.06);fill:#085caf}.icon-button_iconButton__wFJTq:active{background-color:rgba(11,110,208,0.12);fill:#085caf}.icon-button_iconButton__wFJTq:focus-visible{box-shadow:0 0 0 2px #fff,0 0 0 4px #0a3f88}.icon-button_iconButton__wFJTq:after{content:\"\";position:absolute;top:-4px;right:-4px;bottom:-4px;left:-4px}.icon-button_active__3eR5u{background-color:rgba(11,110,208,0.06);fill:#0854a1}\n", ""]);
// Exports
exports.locals = {
	"iconButton": "icon-button_iconButton__wFJTq",
	"active": "icon-button_active__3eR5u"
};
module.exports = exports;
