import React from 'react';
import { ItemSelector } from '@qualtrics/resource-list-selector';
import { messageService } from '../../Service/message-service';
import { ChevronRightIcon } from '@qualtrics/ui-react/icons';
import { Link } from '@qualtrics/ui-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from '../breadcrumb.scss';

export default function ProgramBreadcrumb({
  projectId,
  projectName,
  parentData,
  language,
  restrictedNavigation,
}) {
  const { parentId, parentName, parentType, url: parentUrl } = parentData;

  const handleItemSelect = (project) => {
    window.location = project.navContext.url;
  };

  return (
    <div className={styles.breadcrumbContainer}>
      <Link href={parentUrl} className={styles.ancestorLink}>
        {parentName ?? '...'}
      </Link>
      <ChevronRightIcon aria-hidden={true} />
      <ItemSelector
        className={classnames(styles.anchorButton, {
          [styles.disabled]: restrictedNavigation,
        })}
        clientId={'global-wrapper'}
        query={{
          includeNavContext: true,
          rootItem: {
            id: parentId,
            itemType: parentType,
          },
        }}
        language={language}
        preloadData={false}
        onItemSelect={handleItemSelect}
        selectedItemId={projectId}
        labelText={projectName}
        disabled={restrictedNavigation}
        aria-label={`${messageService.get('PROJECT_SWITCHER')}`}
        selectionItemsTypes={'leaves'}
      />
    </div>
  );
}
ProgramBreadcrumb.propTypes = {
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  parentData: PropTypes.shape({
    parentId: PropTypes.string,
    parentType: PropTypes.string,
    parentName: PropTypes.string,
    url: PropTypes.string,
  }),
  language: PropTypes.string,
  restrictedNavigation: PropTypes.bool,
};
