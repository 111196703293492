'use strict';

import http from './http';

const getProjectsWithinFolders = () => {
  var projects;
  var folders;

  return Promise.all([getProjects(), getFolders()])
    .then(([projectData, folderData]) => {
      projects = projectData;
      folders = folderData.Folders;
    })
    .then(() => {
      if (Array.isArray(folders)) {
        throw new Error('Monolith endpoint unexpectedly returned array');
      }

      var returnObject = {
        allProjects: [],
        uncategorized: [],
        categorized: [],
      };

      sortArrayAlphabetically(projects);

      returnObject.allProjects = [...projects];

      for (const folderIndex of folders.FolderHierarchy.ROOT) {
        if (folderIndex >= 0) {
          returnObject.categorized.push(createFillAndReturnFolder(folderIndex));
        }
      }

      returnObject.uncategorized = projects;

      return returnObject;
    })
    .catch((error) => {
      console.error('Failed to get projects and folders', error);
    });

  function createFillAndReturnFolder(folderIndex) {
    var returnFolder = createFolder(folderIndex);

    //add folders
    if (folders.FolderHierarchy[folderIndex]) {
      for (const childFolderIndex of folders.FolderHierarchy[folderIndex]) {
        returnFolder.content.folders.push(
          createFillAndReturnFolder(childFolderIndex),
        );
      }
    }

    //add projects
    for (const key in folders.Surveys) {
      if (folders.Surveys.hasOwnProperty(key)) {
        const value = folders.Surveys[key];

        if (value === folderIndex) {
          const project = extractProjectByID(key);

          if (project) {
            returnFolder.content.projects.push(project);
          }
        }
      }
    }

    //alphabetical sort
    sortArrayAlphabetically(returnFolder.content.projects);
    sortArrayAlphabetically(returnFolder.content.folders);

    return returnFolder;
  }

  function sortArrayAlphabetically(array) {
    array.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
    );
  }

  function createFolder(folderIndex) {
    return {
      name: getFolderName(folderIndex),
      type: 'folder',
      content: {
        folders: [],
        projects: [],
      },
    };
  }

  function getFolderName(folderIndex) {
    return folders.SurveyFolders[folderIndex];
  }

  function extractProjectByID(projectID) {
    for (const [projectIndex, project] of projects.entries()) {
      if (project.id === projectID) {
        projects.splice(projectIndex, 1);
        return project;
      }
    }

    return undefined;
  }
};

const getXSRFToken = () => {
  // Split cookies into a set of [name, value] pairs, then try to find the
  // XSRF-TOKEN cookie. Monolith defines this and requires it for all of its
  // requests, but it does not exist on the breadcrumb demo page.
  const [xsrfCookie] = document.cookie
    .split(';')
    .map((cookie) => cookie.split('=').map((s) => s.trim()))
    .filter(([name]) => name === 'XSRF-TOKEN');

  return xsrfCookie ? xsrfCookie[1] : '';
};

const getProjectName = (projectID) => {
  var url = '/project-store/ui/nav-contexts/' + projectID;

  return http
    .getJSON(url)
    .then((projectNameObject) => {
      if (!projectNameObject.projectName) {
        throw new Error('Project route response does not contain project name');
      }
      return projectNameObject.projectName;
    })
    .catch((err) => {
      console.error(
        'failed to get project name using project store proxy',
        err,
      );
    });
};

const getProjects = () => {
  return http
    .getJSON('/project-store/ui/programs-and-projects?basicModelOnly=true')
    .then((responseObject) => {
      if (!responseObject.projects) {
        throw new Error('No projects returned');
      }
      // filter out projects marked as hidden (ex. surveys connected to ex/brandtracker or projects in programs)
      const projects = responseObject.projects.filter(
        (project) => !project.hidden,
      );

      let projectData = [];
      for (let i = 0; i < projects.length; i++) {
        projectData[i] = {
          id: projects[i].id,
          name: projects[i].name,
          link: projects[i].url,
          type: projects[i].projectType.resourceTypeForNavContext,
          requiresExternalNavigation: projects[i].requiresExternalNavigation,
        };
      }

      return projectData;
    })
    .catch((err) => {
      console.error('failed to get project data', err);
      throw err;
    });
};

const getProject = (projectID) => {
  const url = '/project-store/ui/projects/' + projectID;

  return http
    .getJSON(url)
    .then((projectObject) => {
      return projectObject;
    })
    .catch((err) => {
      console.error('failed to get project', err);
    });
};

const getFolders = () => {
  var url = '/Q/MyProjectsSection/Ajax/GetMyProjectsUserSettings';
  var headers = {
    'X-XSRF-TOKEN': getXSRFToken(),
  };

  return http.getJSON(url, headers).catch((err) => {
    console.error('failed to get folder data', err);
    throw err;
  });
};

const getProjectWithParentData = (projectId) => {
  const url =
    '/project-store/ui/nav-contexts/' +
    projectId +
    '?includeParentContext=true';

  return http
    .getJSON(url)
    .then((projectObject) => {
      return projectObject;
    })
    .catch((err) => {
      console.error('failed to get project data', err);
    });
};
export {
  getProjectsWithinFolders,
  getProjectName,
  getProject,
  getProjectWithParentData,
};
