import { ButtonIcon } from '@qualtrics/ui-react/icons';
import {
  logNavigation,
  navigateAfterEnterClick,
} from '../../../Service/navigation-service';
import { MenuItem } from '@qualtrics/ui-react';
import classNames from 'classnames';
import styles from './switcherTab.scss';
import { messageService } from '../../../Service/message-service';
import React from 'react';
import { iconsMapping } from './iconsMapping';

export const getSwitcherTab = (section, activeSuperTab, isMobileView) => {
  const ContextIcon = iconsMapping[section.contextType] || ButtonIcon;
  const isActive = section.contextType === activeSuperTab?.v2Info?.contextType;

  const handleSelect = (e, section) => {
    logNavigation(section, false);
    navigateAfterEnterClick(e, section.href);
  };

  return (
    <MenuItem
      key={section.contextType}
      className={classNames(styles.menuOption, {
        [styles.menuActiveOption]: isActive,
        [styles.menuOptionSmallScreens]: isMobileView,
      })}
      label={messageService.get(section.nameKey)}
      value={section.contextType}
      onSelect={(e) => handleSelect(e, section)}
      data-testid={'gw-supertab-' + section.nameKey}
      data-active={isActive || null}
      aria-current={isActive ? true : undefined}
    >
      <a
        href={section.href}
        className={classNames(styles.menuOptionName, {
          [styles.menuActiveOptionName]: isActive,
        })}
      >
        <ContextIcon
          aria-hidden
          className={classNames(styles.menuOptionIcon, {
            [styles.menuActiveOptionIcon]: isActive,
          })}
        />
        {messageService.get(section.nameKey)}
      </a>
    </MenuItem>
  );
};
