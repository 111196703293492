import React, { useEffect, useMemo, useState } from 'react';
import { Button, Popover } from '@qualtrics/ui-react';
import { messageService } from '../../Service/message-service';
import { WarningIcon } from '@qualtrics/ui-react/icons';
import styles from './ImportantMessage.scss';
import IconButton from '../IconButton/IconButton';
import { getTier1DataTestId } from '../../Service/testing';
import PropTypes from 'prop-types';
import { messageToDisplayStruct } from './types';
import { ImportantMessageDescription } from './ImportantMessageDescription';
import { useWouldOverflow } from './useWouldOverflow';
import classNames from 'classnames';
import { useImportantMessages } from '../../hooks/useImportantMessages/useImportantMessages';
import { useScreenType } from '../hooks/useScreenType';
import { usePortalsInfo } from '../../Context';
import { useAddClosingFunction } from '../../Context/useAddClosingFunction';

export const ImportantMessagesBadge = () => {
  const {
    messagesToDisplay,
    reloadMessages,
    hasPartialError,
    areMessagesLoaded,
    isMessagesRead,
    changeMessagesState,
  } = useImportantMessages();
  const { isMobileView } = useScreenType();
  const { isPortalsApplication } = usePortalsInfo();
  const { addClosingFunction } = useAddClosingFunction();
  const [showPopover, setShowPopover] = useState(false);
  const label = useMemo(() => {
    const combinedMessages = [
      ...(messagesToDisplay?.account || []),
      ...(messagesToDisplay?.project || []),
    ];

    if (combinedMessages.length === 0) {
      return undefined;
    }
    if (hasPartialError) {
      return messageService.get('MULTIPLE_WARNINGS', '');
    }
    if (combinedMessages.length === 1) {
      return messageService.get(combinedMessages[0].bannerId);
    }

    return messageService.get('MULTIPLE_WARNINGS', combinedMessages.length);
  }, [isMobileView, messagesToDisplay, hasPartialError]);

  const {
    childRef: buttonRef,
    parentRef,
    overflow: labelOverflows,
  } = useWouldOverflow(15);

  //can be deleted after https://gitlab-app.eng.qops.net/cfe/ui/-/issues/675 issue is resolved
  useEffect(() => {
    if (!window.keyboardUserListenersAdded) {
      window.keyboardUserListenersAdded = true;
      document.addEventListener('mousedown', function () {
        document.body.classList.remove('keyboard-user');
      });
      document.addEventListener('keydown', function (e) {
        if (e.key === 'Tab') {
          document.body.classList.add('keyboard-user');
        }
      });
    }
  }, []);

  useEffect(() => {
    addClosingFunction(() => {
      setShowPopover(false);
    });
  }, []);

  const onTryAgainClick = () => {
    reloadMessages();
  };

  if (!label) {
    return null;
  }

  return (
    <div
      className={classNames(styles.buttonDiv, {
        [styles.portal]: isPortalsApplication,
      })}
      ref={parentRef}
    >
      <Popover
        showOnDescendantFocus
        content={
          <div>
            <ImportantMessageDescription
              onClose={() => setShowPopover(false)}
              messagesToDisplay={messagesToDisplay}
              isMobileView={isMobileView}
              hasPartialError={hasPartialError}
              reloadMessages={onTryAgainClick}
              areMessagesLoaded={areMessagesLoaded}
            />
          </div>
        }
        lightTheme="true"
        placement="bottom"
        className={styles.descriptionTooltip}
        show={showPopover}
        onClickOutside={() => setShowPopover(false)}
        aria-labelledby="GW-IMBA-message-description"
      >
        {!(labelOverflows || isMobileView) ? (
          <Button
            className={styles.messageButton}
            onClick={() => {
              changeMessagesState({ isMessagesRead: true });
              setShowPopover(!showPopover);
            }}
            id="GW-IMBA-message-button"
            data-testid={getTier1DataTestId('mobile-message-button')}
            aria-label={label}
            ref={buttonRef}
          >
            <WarningIcon />
            {label}
          </Button>
        ) : (
          <IconButton
            className={styles.messageButtonSmall}
            onClick={() => {
              changeMessagesState({ isMessagesRead: true });
              setShowPopover(!showPopover);
            }}
            id="GW-IMBA-message-button"
            data-testid={getTier1DataTestId('mobile-message-button')}
            tooltipContent={messageService.get('WARNINGS')}
            aria-label={messageService.get('WARNINGS')}
          >
            <WarningIcon size={isPortalsApplication ? 'large' : 'medium'} />
          </IconButton>
        )}
      </Popover>
      {isMessagesRead ? null : (
        <span
          className={
            labelOverflows || isMobileView
              ? styles.unreadMessageIndicatorSmallScreen
              : styles.unreadMessageIndicatorDefaultScreen
          }
          data-testid={getTier1DataTestId('new-warnings-indicator')}
        />
      )}
    </div>
  );
};

ImportantMessagesBadge.propTypes = {
  isMobileView: PropTypes.bool,
  messagesToDisplay: messageToDisplayStruct,
  isInPortalApp: PropTypes.bool,
};
