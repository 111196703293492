import { MenuItem } from '@qualtrics/ui-react';
import classNames from 'classnames';
import styles from '../ResizableMenu.scss';
import React from 'react';

export const getMenuElement = (
  element,
  isElementActive,
  onClick,
  index,
  dataTestType,
) => {
  return (
    <MenuItem
      key={element.id}
      className={classNames(styles.moreMenuItem, {
        [styles.menuActiveItem]: isElementActive,
      })}
    >
      <a
        data-testtype={dataTestType}
        data-testid={`menu-${element.dataTestId}`}
        href={element.href}
        onClick={onClick}
        onKeyDown={(e) => (e.key === 'Enter' ? onClick() : undefined)}
        tabIndex="0"
        className={classNames(styles.label, {
          [styles.labelActive]: isElementActive,
        })}
      >
        {element.label}
      </a>
    </MenuItem>
  );
};
