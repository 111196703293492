import classNames from 'classnames';
import styles from './LoadingSpinnerWrapper.scss';
import { getTier1DataTestId } from '../../Service/testing';
import { LoadingSpinner } from '@qualtrics/ui-react';
import React from 'react';
import PropTypes from 'prop-types';

export const LoadingSpinnerWrapper = ({
  isMobileView,
  className,
  testIdPrefix,
}) => {
  return (
    <div
      className={classNames(className, styles.menuLoading, {
        [styles.menuOptionSmallScreens]: isMobileView,
      })}
      data-testid={getTier1DataTestId(`${testIdPrefix}-LoadingSpinner`)}
    >
      <LoadingSpinner show size={'small'} />
    </div>
  );
};

LoadingSpinnerWrapper.propTypes = {
  isMobileView: PropTypes.bool,
  className: PropTypes.string,
  testIdPrefix: PropTypes.string,
};
