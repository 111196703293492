import React, { useEffect, useState } from 'react';
import styles from './shell-footer.scss';
import { messageService } from '../navigation/Service/message-service';
import cookies from '../../react/navigation/Service/cookies';
import PropTypes from 'prop-types';
// Full import path need due to this issue: https://github.com/facebook/jest/issues/7271
import '@qualtrics/base-styles/dist/base-styles.css';

export default function ShellFooter({ language }) {
  const [messageServiceInitialized, setMessageServiceInitialized] =
    useState(false);

  const sessionId = cookies.get('UDSSessionKey');
  let brandLogoUrl = '/brand-management/logo';
  if (sessionId) {
    brandLogoUrl += '?udsSessionKey=' + sessionId;
  }

  useEffect(() => {
    messageService.initialize(language).then(() => {
      setMessageServiceInitialized(true);
    });
  }, [language]);

  return (
    <footer className={styles.footer} role="contentinfo">
      {brandLogoUrl && (
        <img
          onError={(e) => {
            e.target.style.display = 'none';
          }}
          className={styles.brandLogo}
          src={brandLogoUrl}
          alt={
            messageServiceInitialized
              ? messageService.get('COMPANY_LOGO')
              : undefined
          }
        />
      )}

      <div className={styles.contactInfo}>
        <a
          className={styles.link}
          href="http://www.qualtrics.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Qualtrics.com
        </a>
        <a
          className={styles.link}
          href="http://support.qualtrics.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {messageServiceInitialized && messageService.get('CONTACT_INFO')}
        </a>
        <a
          className={styles.link}
          href="http://www.qualtrics.com/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {messageServiceInitialized && messageService.get('TERMS_OF_SERVICE')}
        </a>
      </div>
    </footer>
  );
}

ShellFooter.propTypes = {
  language: PropTypes.string.isRequired,
};
