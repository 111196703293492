/**
 * @typedef {PortalsInfoType} PortalsHook
 * @property {?string} currentPortalId
 * @property {?string} portalIdFromCookie
 * @property {boolean} isPortalsApplication
 * @property {boolean} isPortalsChild
 * @property {boolean} isParticipantPortal
 * @property {boolean} isParticipantPortalInProject
 * @property {boolean} isCookieInPortalMode
 * @property {?ParticipantProps} participantPortal
 */

import { useContext, useMemo } from 'react';
import {
  getPortalInfo,
  parsePortalModeCookie,
} from '../Service/portals-service';
import { AppContext } from './AppContext';

/** @returns {PortalsHook} */
export const usePortalsInfo = () => {
  const context = useContext(AppContext);
  const {
    isParticipantPortal,
    isParticipantPortalInProject,
    isPortalsApplication,
    isPortalsChild,
    currentPortalId,
    currentInstanceId,
  } = useMemo(
    () =>
      getPortalInfo(
        context.v2Info.context,
        context.portals.isLegacyPortalHeader,
        context.v2Info.pageId,
      ),
    [context.v2Info, context.portals],
  );

  const { portalIdFromCookie } = useMemo(
    () => parsePortalModeCookie(context.userInfo.userId),
    [context.userInfo],
  );
  const isCookieInPortalMode = useMemo(
    () =>
      portalIdFromCookie !== null &&
      portalIdFromCookie !== undefined &&
      portalIdFromCookie !== 'Qualtrics' &&
      portalIdFromCookie !== 'ParticipantPortal',
    [portalIdFromCookie],
  );
  return {
    ...context.portals,
    currentPortalId,
    currentInstanceId,
    isPortalsApplication,
    isPortalsChild,
    isParticipantPortal,
    isParticipantPortalInProject,
    portalIdFromCookie,
    isCookieInPortalMode,
    participantPortal:
      isParticipantPortal || isParticipantPortalInProject
        ? context.portals.participantProps
        : undefined,
  };
};
