import useResizeObserver from 'use-resize-observer';
import { useEffect, useRef, useState } from 'react';

export const useWouldOverflow = (margin = 0) => {
  const childRef = useRef();

  const [childWidth, setChildWidth] = useState(0);
  useEffect(() => {
    if (childRef?.current?.getBoundingClientRect) {
      setChildWidth(childRef.current.getBoundingClientRect().width + margin);
    }
  }, [childRef.current]);

  const { ref: parentRef, width: parentWidth } = useResizeObserver();
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    setOverflow(childWidth > parentWidth);
  }, [childWidth, parentWidth]);

  return { childRef, parentRef, overflow };
};
