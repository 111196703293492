// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".shell-tier1-breadcrumb{font-size:16px;color:#32363A;margin-left:16px;white-space:nowrap;max-width:436px;text-overflow:ellipsis;overflow-x:hidden}#angular-dashboard-app [data-testid='Shell-Tier1-Breadcrumb']{overflow:hidden}.gw-header-container{overflow:hidden}.top-level-nav_topLevelNav__2Wkoi,.top-level-nav_topLevelNavPortalsApplication__htJod{height:49px;background-color:#fff;padding-left:14px;padding-right:20px;box-sizing:border-box;display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid #eeeeef}.top-level-nav_topLevelNavPortalsApplication__htJod{height:69px;background:rgba(255,255,255,0.55)}.top-level-nav_topLevelNavPortalsChild__3DZsj{background:none;height:0}.top-level-nav_leftSection__33J4M,.top-level-nav_rightSection__12swI{display:flex;align-items:center}#dashboards-body #t1-navigation .top-level-nav_leftSection__33J4M{overflow:hidden}#angular-dashboard-app .top-level-nav_leftSection__33J4M{overflow:hidden}.top-level-nav_leftSection__33J4M.top-level-nav_xsmall__uMNcY{flex-basis:50%;flex-shrink:1;flex-grow:1}.top-level-nav_rightSection__12swI.top-level-nav_xsmall__uMNcY{margin-left:0;flex-basis:50%;flex-shrink:1;flex-grow:1;min-width:-moz-fit-content;min-width:fit-content}.top-level-nav_rightSection__12swI{flex-grow:1;justify-content:flex-end;margin-left:14px;overflow:hidden}#dashboards-body #t1-navigation .top-level-nav_rightSection__12swI{overflow:inherit}#angular-dashboard-app .top-level-nav_rightSection__12swI{overflow:inherit}.top-level-nav_small__13lQH.top-level-nav_topLevelNav__2Wkoi,.top-level-nav_small__13lQH.top-level-nav_topLevelNavPortalsApplication__htJod,.top-level-nav_xsmall__uMNcY.top-level-nav_topLevelNav__2Wkoi,.top-level-nav_xsmall__uMNcY.top-level-nav_topLevelNavPortalsApplication__htJod{padding-left:8px;padding-right:8px}.top-level-nav_small__13lQH.top-level-nav_rightSection__12swI,.top-level-nav_xsmall__uMNcY.top-level-nav_rightSection__12swI{margin-left:18px}\n", ""]);
// Exports
exports.locals = {
	"topLevelNav": "top-level-nav_topLevelNav__2Wkoi",
	"topLevelNavPortalsApplication": "top-level-nav_topLevelNavPortalsApplication__htJod",
	"topLevelNavPortalsChild": "top-level-nav_topLevelNavPortalsChild__3DZsj",
	"leftSection": "top-level-nav_leftSection__33J4M",
	"rightSection": "top-level-nav_rightSection__12swI",
	"xsmall": "top-level-nav_xsmall__uMNcY",
	"small": "top-level-nav_small__13lQH"
};
module.exports = exports;
