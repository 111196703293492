import http from './http';
import SessionStorageManager from './session-storage-manager';
import { logVisibilityEvalFailure } from './metrics-service';
import cookies from './cookies';

const cache = new SessionStorageManager(window);

function extractExpressions(cfg, id) {
  let expressions = {};

  if (Array.isArray(cfg.sectionTabs)) {
    cfg.sectionTabs.forEach(function (sectionTab) {
      if (typeof sectionTab.visibilityExpression === 'string') {
        expressions[id + '.' + sectionTab.id] = sectionTab.visibilityExpression;
      }
      if (Array.isArray(sectionTab.subTabs)) {
        sectionTab.subTabs.forEach(function (subTab) {
          if (typeof subTab.visibilityExpression === 'string') {
            expressions[id + '.' + sectionTab.id + '.' + subTab.id] =
              subTab.visibilityExpression;
          }
        });
      }
    });
  }
  return expressions;
}

function updateVisibilities(cfg, visibilities, id) {
  if (visibilities === undefined) {
    visibilities = {};
  }
  if (Array.isArray(cfg.sectionTabs)) {
    cfg.sectionTabs.forEach(function (secTab) {
      secTab.visible =
        !!secTab.isDynamic || !!visibilities[id + '.' + secTab.id];
      if (Array.isArray(secTab.subTabs)) {
        let groupVisibility = false;
        secTab.subTabs.forEach(function (subTab) {
          subTab.visible =
            !!subTab.isDynamic ||
            !!visibilities[id + '.' + secTab.id + '.' + subTab.id];
          groupVisibility = groupVisibility || subTab.visible;
        });
        secTab.visible = groupVisibility;
      }
    });
  }

  // Update super tab visibility
  if (cfg.visible !== undefined) {
    return cfg;
  }

  if (Array.isArray(cfg.sectionTabs)) {
    cfg.sectionTabs.forEach(function (secTab) {
      cfg.visible = cfg.visible || secTab.visible;
      if (Array.isArray(secTab.subTabs)) {
        secTab.subTabs.forEach(function (subTab) {
          cfg.visible = cfg.visible || subTab.visible;
        });
      }
    });
  }
  return cfg;
}

function createExpressionsRequestBody(exps, expCtx) {
  let expressions = {};

  Object.keys(exps).forEach((id) => {
    expressions[id] = replaceTemplateVars(exps[id]);
  });
  let ctx = Object.assign({}, expCtx);

  if (ctx.contextType) {
    ctx.type = ctx.contextType;
    delete ctx.contextType;
  }
  return {
    context: ctx,
    expressions: expressions,
  };
}

function evaluate(exps, expCtx) {
  const requestBody = createExpressionsRequestBody(exps, expCtx);
  const sessionId = cookies.get('UDSSessionKey');

  let expressionsEndpoint = '/wrapper/v1/expressions';

  expressionsEndpoint = expressionsEndpoint + '?useV2=true';

  if (sessionId) {
    expressionsEndpoint = expressionsEndpoint + '&UDSSessionKey=' + sessionId;
  }

  const key = createExpressionCacheKey(sessionId, requestBody.context);
  const cacheData = cache.getSessionStorage(key);

  if (Object.keys(requestBody.expressions).length === 0) {
    return Promise.resolve({});
  } else if (cacheData) {
    return Promise.resolve(JSON.parse(cacheData));
  } else {
    return http
      .postJSON(expressionsEndpoint, requestBody)
      .then(function (data) {
        cache.setSessionStorage(key, JSON.stringify(data.results));
        return data.results;
      })
      .catch(function (err) {
        logVisibilityEvalFailure(
          err,
          'Failed on POST expression',
          requestBody.context.type,
        );
        return { hasError: true };
      });
  }
}

function evaluateBatch(requestsMap) {
  let expressionVisibilityGroups = {};
  let batchRequests = [];
  let batchRequestsMap = {};

  const sessionId = cookies.get('UDSSessionKey');

  Object.keys(requestsMap).forEach((requestId) => {
    const request = requestsMap[requestId];
    const requestBody = createExpressionsRequestBody(
      request.exps,
      request.expsCtx,
    );

    const key = createExpressionCacheKey(sessionId, requestBody.context);
    const cacheData = cache.getSessionStorage(key);

    if (Object.keys(requestBody.expressions).length === 0) {
      expressionVisibilityGroups[requestId] = Promise.resolve({});
    } else if (cacheData) {
      expressionVisibilityGroups[requestId] = Promise.resolve(
        JSON.parse(cacheData),
      );
    } else {
      batchRequestsMap[requestId] = {
        index: batchRequests.push(requestBody) - 1,
        cacheKey: key,
      };
    }
  });

  if (batchRequests.length > 0) {
    let expressionsEndpoint = '/wrapper/v1/expressions/batch';
    if (sessionId) {
      expressionsEndpoint = expressionsEndpoint + '?UDSSessionKey=' + sessionId;
    }
    const batchRequestPromise = http.postJSON(expressionsEndpoint, {
      requests: batchRequests,
    });

    Object.keys(batchRequestsMap).forEach((requestId) => {
      expressionVisibilityGroups[requestId] = batchRequestPromise
        .then((data) => {
          if (data.responses?.length !== batchRequests.length) {
            throw new Error('Invalid number of responses returned');
          }
          const response = data.responses[batchRequestsMap[requestId].index];
          if (response.status !== 200) {
            throw new Error(
              `Invalid status ${response.status} returned for expression`,
            );
          }
          cache.setSessionStorage(
            batchRequestsMap[requestId].cacheKey,
            JSON.stringify(response.results),
          );
          return response.results;
        })
        .catch(function (err) {
          logVisibilityEvalFailure(err, 'Failed on POST expression', requestId);
          return { hasError: true };
        });
    });
  }
  return expressionVisibilityGroups;
}

function replaceTemplateVars(input) {
  const surveyIdMatcher = /{{\.SurveyID}}/g;
  const projectIdMatcher = /{{\.ProjectID}}/g;
  const projectTypeMatcher = /{{\.ProjectType}}/g;
  const directoryIdMatcher = /{{\.DirectoryID}}/g;
  const libraryIdMatcher = /{{\.LibraryID}}/g;
  const pluginIdMatcher = /{{\.PluginID}}/g;

  let exp = input;
  exp = exp.replace(projectIdMatcher, '{{.ResourceID}}');
  exp = exp.replace(projectTypeMatcher, '{{.ResourceType}}');
  exp = exp.replace(surveyIdMatcher, '{{.SubresourceID}}');
  exp = exp.replace(directoryIdMatcher, '{{.ResourceID}}');
  exp = exp.replace(libraryIdMatcher, '{{.ResourceID}}');
  exp = exp.replace(pluginIdMatcher, '{{.ResourceID}}');
  return exp;
}

/* eslint no-bitwise:off */
function hashString(s) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

function createExpressionCacheKey(sessionId, ctx) {
  return (
    'http_expressions_' +
    (sessionId ?? '') +
    'context(' +
    JSON.stringify(ctx) +
    ') + expressions_hash(' +
    hashString(JSON.stringify(ctx)) +
    ')'
  );
}

const exports = {
  replaceTemplateVars,
  extractExpressions,
  updateVisibilities,
  evaluate,
  evaluateBatch,
};

export default exports;
