import styles from './ResizableMenu.scss';
import { Menu } from '@qualtrics/ui-react';
import classNames from 'classnames';
import { ChevronDownIcon, ChevronUpIcon } from '@qualtrics/ui-react/icons';
import React, { useRef } from 'react';
import { useScreenType } from '../TopLevelNav/hooks/useScreenType';
import { useWindowSize } from '../TopLevelNav/hooks/useWindowSize';
import PropTypes from 'prop-types';
import { MainElement } from './subcomponents/MainElement';
import { getMenuElement } from './subcomponents/getMenuElement';
import { useResizeableMenuProps } from './subcomponents/useResizeableMenuProps';
import { messageService } from '../Service/message-service';

export const ResizableMenu = ({
  elements,
  activeElementId,
  onElementClickAction,
  shouldShowSubsectionTabs,
  ariaLabel,
  dataTestId,
  dataTestType,
}) => {
  const listRef = useRef(null);
  const elementsRef = useRef([]);
  const menuAnchorRef = useRef(null);
  const { moreMenu, firstHiddenIndex } = useResizeableMenuProps(
    elements,
    activeElementId,
    listRef,
    elementsRef,
    menuAnchorRef,
  );

  const windowSize = useWindowSize();
  const { isxSmallScreen } = useScreenType();

  return (
    <nav
      className={styles.gwSectionLevelNavContainer}
      aria-label={ariaLabel}
      data-testid={dataTestId}
    >
      <div className={styles.gwSectionLevelNav}>
        <ul className={styles.gwSectionTabsList} ref={listRef}>
          {elements.map((element, i) => (
            <MainElement
              key={element.id}
              isActive={element.id === activeElementId}
              onClick={() => onElementClickAction(element)}
              ref={(el) => {
                elementsRef.current[i] = el;
              }}
              style={{
                display: i >= firstHiddenIndex ? 'none' : 'inline-block',
              }}
              label={element.label}
              dataTestId={element.dataTestId}
              ariaCurrent={
                element.id === activeElementId
                  ? shouldShowSubsectionTabs
                    ? true
                    : 'page'
                  : undefined
              }
              href={element.href}
              dataTestType={dataTestType}
            />
          ))}
          <li
            data-testId="more-menu-anchor"
            className={classNames(styles.gwSectionTab, styles.moreMenuLi, {
              [styles.menuOpened]: moreMenu.isOpen,
              [styles.active]: moreMenu.isActive,
            })}
            style={{
              visibility: moreMenu.isHidden ? 'hidden' : 'visible',
            }}
            ref={menuAnchorRef}
          >
            <Menu
              flyoutClassName={classNames(styles.moreMenu, {
                [styles.fullMenuFlyout]: isxSmallScreen,
              })}
              isOpen={moreMenu.isOpen}
              onIsOpenChange={moreMenu.setIsOpen}
              anchor={
                <div className={styles.moreButton}>
                  <div>
                    <div className={styles.text}>
                      {messageService.get('MORE')}
                    </div>
                    {moreMenu.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </div>
                  <div className={styles.underline} />
                </div>
              }
              maxHeight={windowSize.height - 125}
            >
              {elements
                .filter((_, i) => i >= firstHiddenIndex)
                .map((element, i) =>
                  getMenuElement(
                    element,
                    element.id === activeElementId,
                    () => onElementClickAction(element),
                    i,
                    dataTestType,
                  ),
                )}
            </Menu>
          </li>
        </ul>
      </div>
    </nav>
  );
};

ResizableMenu.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      href: PropTypes.string,
      dataTestId: PropTypes.string,
    }),
  ),
  activeElementId: PropTypes.string,
  onElementClickAction: PropTypes.func,
  shouldShowSubsectionTabs: PropTypes.bool,
  ariaLabel: PropTypes.string,
  dataTestId: PropTypes.string,
  dataTestType: PropTypes.string,
};
