import React from 'react';
import styles from './ImportantMessage.scss';
import { ErrorIcon } from '@qualtrics/ui-react/icons';
import { messageService } from '../../Service/message-service';
import { getTier1DataTestId } from '../../Service/testing';
import { getErrorMessage } from '../CommonMenuItems/getErrorComponent';
import PropTypes from 'prop-types';

export const ImportantMessageErrorBanner = ({ reloadMessages }) => {
  const onRetryLabelKeyDown = (event) => {
    if (event.key === 'Enter') {
      reloadMessages();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onErrorItemFocus = (event) => {
    event.preventDefault();
    event.stopPropagation();
    retryErrorRef.current?.focus();
  };

  const onRetryClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    reloadMessages();
  };

  const retryErrorRef = React.createRef();
  return (
    <div
      className={styles.errorItem}
      onFocus={onErrorItemFocus}
      key="important-message-retry-on-error"
    >
      <ErrorIcon aria-hidden="true" size="small" />
      <div className={styles.errorText} role="alert">
        {getErrorMessage(
          messageService.get('ERROR_WHILE_LOADING'),
          <span
            className={styles.tryAgainButton}
            ref={retryErrorRef}
            tabIndex={0}
            role={'button'}
            onClick={onRetryClick}
            onKeyDown={onRetryLabelKeyDown}
            data-testid={getTier1DataTestId('Important-Message-Error-TryAgain')}
          >
            {messageService.get('TRY_AGAIN')}
          </span>,
        )}
      </div>
    </div>
  );
};
ImportantMessageErrorBanner.propTypes = {
  reloadMessages: PropTypes.func,
};
