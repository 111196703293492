import cookies from './cookies';
import { v4 as uuidv4 } from 'uuid';

/**
 * Promisify the XMLHttpRequest
 * @param {object} opts with following attributes:
 *      method: {String},
 *      url: {String},
 *      body: {Object},
 *      headers: {Object},
 * @return {Promise} resolves to the response from http request
 */
function request(opts) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({
          response: xhr.response,
          headers: xhr.getAllResponseHeaders(),
          status: xhr.status,
        });
      } else {
        reject(compileError(xhr, opts));
      }
    };
    xhr.onerror = () => {
      reject(compileError(xhr, opts));
    };

    let url = opts.url;

    xhr.open(opts.method, url);

    if (opts.headers) {
      Object.keys(opts.headers).forEach((key) => {
        xhr.setRequestHeader(key, opts.headers[key]);
      });
    }

    xhr.send(opts.body);
  });
}

function compileError(xhr, opts) {
  return {
    status: xhr.status,
    statusText: xhr.statusText,
    config: { url: opts.url },
  };
}

function postJSON(url, body) {
  return request({
    method: 'POST',
    url: url,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'X-Transaction-ID': uuidv4(),
      'X-Request-ID': uuidv4(),
    },
  })
    .then(({ response }) => response)
    .then((data) => {
      try {
        return JSON.parse(data);
      } catch (e) {
        throw {
          statusText: e.toString(),
        };
      }
    });
}

function getJSON(url, headers) {
  return request({
    method: 'GET',
    url: url,
    headers: headers ?? undefined,
  })
    .then(({ response }) => response)
    .then((data) => {
      try {
        return JSON.parse(data);
      } catch (e) {
        throw {
          statusText: e.toString(),
        };
      }
    });
}

function getWithCsrf(url) {
  return request({
    method: 'GET',
    url: url,
    headers: {
      'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
    },
  }).then(({ response }) => response);
}

const exports = {
  getJSON,
  postJSON,
  getWithCsrf,
  request,
};

export default exports;
