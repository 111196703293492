/* eslint-disable max-len */
import createIconComponent from '../createPortalIcon';

export default /* #__PURE__ */ createIconComponent(
  'WaffleMenu',
  '<rect x="13.5" y="2" width="5" height="5" rx="1.5" fill="#2C5CA4"/>\n' +
    '<rect x="13.5" y="24" width="5" height="5" rx="1.5" fill="#2C5CA4"/>\n' +
    '<rect x="13.5" y="13" width="5" height="5" rx="1.5" fill="#2C5CA4"/>\n' +
    '<rect x="2" y="2" width="5" height="5" rx="1.5" fill="#2C5CA4"/>\n' +
    '<rect x="2" y="24" width="5" height="5" rx="1.5" fill="#2C5CA4"/>\n' +
    '<rect x="2" y="13" width="5" height="5" rx="1.5" fill="#2C5CA4"/>\n' +
    '<rect x="25" y="2" width="5" height="5" rx="1.5" fill="#2C5CA4"/>\n' +
    '<rect x="25" y="24" width="5" height="5" rx="1.5" fill="#2C5CA4"/>\n' +
    '<rect x="25" y="13" width="5" height="5" rx="1.5" fill="#2C5CA4"/>',
);
