import { isRenderedInPortalFrame } from '@qualtrics-portals/portal-checker';
import bundleServiceFactory from './bundle-service';
import {
  checkIfPageIsValidInPortal,
  getAvailablePortals,
  isPortalsApp,
} from './portals-service';
import {
  initializeMetricsService,
  initializeBaseRumMetaData,
  initializeRumMetaData,
} from './metrics-service';
import scriptLoadServiceFactory from './script-load-service';
import { messageService } from './message-service';
import { shouldHideEntireNavInChildIframe } from './program-child-checker-service';
import { isExportPage } from './tool-check';

const preInitializationRUMMetadata = {
  userId: '',
  brandId: '',
  dc: window.Qualtrics?.state?.datacenter,
};

const initialize = (language, context, pageId, loadTabs, isExternalAppMode) => {
  const bundleService = bundleServiceFactory();

  const config = {};
  initializeBaseRumMetaData(preInitializationRUMMetadata);
  initializeMetricsService();
  const translationsPromise = messageService.initialize(language);
  return bundleService
    .getBundle()
    .then((bundleData) => {
      const user = bundleData.user;
      const brand = bundleData.brand;

      const userData = {
        userId: user.userId,
        brandId: user.brandId,
        language: language,
        email: user.email,
        userName: user.userName,
        firstName: user.firstName,
        lastName: user.lastName,
        displayName: user.firstName + ' ' + user.lastName,
        accountType: user.accountType,
        isRestrictedNavigation: user.accountType === 'UT_SHARED',
        accountCreationDate: user.accountCreationDate,
        brandName: brand.brandName,
        brandType: brand.brandType,
      };

      const rumMetadata = {
        userId: user.userId,
        brandId: user.brandId,
        dc: bundleData.system?.dc,
      };

      config.restrictedNavigation = userData.isRestrictedNavigation;
      config.userData = userData;

      initializeRumMetaData(rumMetadata);

      return bundleData;
    })
    .then((bundleData) => {
      config.portals = [];
      const portalsConfig = getAvailablePortals(language)
        .then((portals) => {
          checkIfPageIsValidInPortal(
            portals,
            context,
            config.userData.userId,
            config.restrictedNavigation,
            pageId,
            !!bundleData.features['Portals.EnablePortalsChooser'],
          );
          config.portals = portals;
        })
        .catch(() => {
          config.portals = [];
        });

      const mainConfig = translationsPromise.then(() => {
        return loadTabs(context, bundleData).then((_) => {
          // TODO: Fix this hack for the Jira pulse NAV-386

          const externalModulesProps = {
            context,
            isFullstoryPilotEnabled: !!bundleData.features.fullstory,
            samProxySupportModalEnabled:
              !!bundleData.features.GW_SAM_PROXY_SUPPORT_MODAL,
            digitalAdoptionEnabled: !!bundleData.features.PG_DigitalAdoption,
            userId: config.userData.userId,
            isPortalsApplication: isPortalsApp(context.contextType),
            pageId,
            webVitalsEnabled: !!bundleData.features['GlobalWrapper.QWebVitals'],
          };

          const isHomepageUIFeatureFlipperEnabled =
            !!bundleData.features['Homepage.EnableUI'];

          config.isCustomLogoEnabled =
            !!bundleData.features['GlobalWrapper.EnableCustomLogoHeader'] ||
            false;

          config.homeDefaultDestination = isHomepageUIFeatureFlipperEnabled
            ? '/homepage/ui'
            : '/Q/MyProjectsSection';

          config.isFreeAccount = !!bundleData.features.PG_IsFreeAccount;

          config.enableSkipToMainContent =
            !!bundleData.features['GlobalWrapper.EnableSkipToMainContent'] ||
            false;

          config.displayHelpModalInPortal =
            !!bundleData.features['Portals.EnablePortalsSettings'] || false;
          config.enableExternalPortals =
            !!bundleData.features['Portals.EnableExternalPortals'] || false;

          config.displayHelpModalInPortal =
            !!bundleData.features['Portals.EnablePortalsSettings'] || false;

          config.isImportantMessagesEnabled =
            !!bundleData.features['GlobalWrapper.IMBA.Enable'] || false;

          config.isAuthnLogoutEnabled =
            !!bundleData.features['IDS.UseAuthLogoutDirectly'] || false;

          config.v2Info = {
            pageId: pageId,
            context: context,
          };

          const { dc } = bundleData.system || {};
          config.DC = dc;

          return [config, externalModulesProps];
        });
      });

      return Promise.all([mainConfig, portalsConfig]).then(
        ([[config, externalModulesProps]]) => {
          loadExternalModules(
            externalModulesProps.context,
            externalModulesProps.isFullstoryPilotEnabled,
            externalModulesProps.samProxySupportModalEnabled,
            externalModulesProps.digitalAdoptionEnabled,
            externalModulesProps.userId,
            externalModulesProps.isPortalsApplication,
            externalModulesProps.pageId,
            externalModulesProps.webVitalsEnabled,
            isExternalAppMode,
            config.DC,
          );
          return config;
        },
      );
    });
};

const loadExternalModules = (
  context,
  fullstoryPilotEnabled,
  samProxySupportModalEnabled,
  digitalAdoptionEnabled,
  userId,
  isPortalsApplication,
  pageId,
  webVitalsEnabled,
  isExternalPortalMode,
  dc,
) => {
  const scriptLoadService = scriptLoadServiceFactory();
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isInPortal = isRenderedInPortalFrame();
  const shouldHideNav = shouldHideEntireNavInChildIframe();
  const isNavHiddenInIframe = isInPortal || shouldHideNav;
  if (!isNavHiddenInIframe && !isExternalPortalMode) {
    // Do not wait on promise from script loading
    scriptLoadService.loadScript(
      '/app/notifications-bell/main.js',
      'script-notifications-modal',
    );
  }

  if (!isNavHiddenInIframe) {
    scriptLoadService.loadScript('/login/proxy-approval-client/bundle.min.js');
    scriptLoadService.loadScript('/login/keepalive-client/bundle.min.js');
  }

  if (digitalAdoptionEnabled) {
    scriptLoadService.loadScript('/dg-service/scripts/digital_adoption.min.js');
  }

  if (!isNavHiddenInIframe) {
    scriptLoadService.loadScript(
      '/dg-service/scripts/free_account_prompts.min.js',
    );

    if (!isPortalsApplication && !isExternalPortalMode) {
      if (samProxySupportModalEnabled) {
        scriptLoadService.loadScript(
          '/app/support-modal/bundle.js',
          'script-support-modal',
          context,
        );
      } else {
        scriptLoadService.loadScript(
          '/static/support-modal/bundle.js',
          'script-support-modal',
          context,
        );
      }
    }

    if (webVitalsEnabled && !isExportPage()) {
      scriptLoadService.loadScript(
        '/static/q-web-vitals/q-web-vitals.js',
        'script-q-web-vitals',
        { contextType: context.contextType, pageId, dc },
      );
    }
  }

  scriptLoadService.loadScript(
    '/static/home-page-utils/homepage-recently-visited.min.js',
    'script-homepage-recently-visited',
    context,
  );

  if (fullstoryPilotEnabled) {
    scriptLoadService.loadScript(
      '/static/fullstory-snippet/qualtrics-fs-snippet.js',
      'script-fullstory',
      { userId: userId },
    );
  }

  const siteInterceptUrl =
    '/static/site-intercept-global-wrapper-snippets/snippet.js';
  scriptLoadService
    .loadScript(siteInterceptUrl, 'script-site-intercept', { extRefId: userId })
    .catch((error) =>
      console.error(
        `Global Wrapper failed to load ${siteInterceptUrl}:`,
        error,
      ),
    );
};

export { initialize };
