import {
  createCommonPageTimings,
  generateSendMetricsForApp,
  increaseCounter,
} from '@qualtrics/page-metrics/lib/metrics-emitter';

export function initializeMetricsObserver(metricsDelegate) {
  const { sendMetric } = generateSendMetricsForApp(
    'homepage',
    'global_wrapper',
  );
  const timingStart = Date.now();
  const { setOnAppInteractive, setOnAppComplete } = createCommonPageTimings(
    timingStart,
  ).applyAutoSend('homepage', 'global_wrapper', true);
  metricsDelegate.subscribeObserver({
    onAppInteractive: setOnAppInteractive,
    onAppComplete: setOnAppComplete,
    onVisibilityEvalFailure: (
      errorMessage,
      contextType,
      additionalKeyPairs,
    ) => {
      sendMetric(
        increaseCounter('visibility_eval_failures', 1, { contextType }),
      );
    },
    onBundleFailure: (errorMessage, additionalKeyPairs) =>
      sendMetric(increaseCounter('bundle_failures')),
  });
}
