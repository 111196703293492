/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useMemo } from 'react';
import NavigationService from '../Service/navigation-service';
import { log } from '../Service/clickstream';
import PropTypes from 'prop-types';
import { messageService } from '../Service/message-service';
import { ResizableMenu } from '../ResizableMenu';
import { useWorkMode } from '../Context';

export default function SubsectionLevelNav({
  activeSuperTab,
  pageId,
  changePageId,
}) {
  const { debugMode } = useWorkMode();
  const navigationService = new NavigationService(window, debugMode);

  const subsections = useMemo(() => {
    const sectionTabs = activeSuperTab?.sectionTabs || [];

    let subsectionTabs = sectionTabs.reduce((subTabs, tab) => {
      if (tab.subTabs) {
        // We only want to return visible subtabs where one matches the pageId
        let foundActiveSubTab = false;
        let potentialVisibleSubTabs = [];

        tab.subTabs.forEach((subTab) => {
          if (subTab.visible) {
            potentialVisibleSubTabs.push(subTab);
          }
          if (subTab.v2Info.pageId === pageId) {
            foundActiveSubTab = true;
          }
        });

        if (foundActiveSubTab) {
          subTabs = potentialVisibleSubTabs;
        }
      }

      return subTabs;
    }, []);

    subsectionTabs.forEach((subsectionTab) => {
      subsectionTab.dataTestId = 'gw-subtab-' + subsectionTab.nameKey;
    });

    return subsectionTabs;
  }, [activeSuperTab?.sectionTabs]);

  const subsectionIsActive = (subsection) => {
    return subsection.v2Info.pageId === pageId;
  };

  // navigateTosubsection will log click metrics, handle SPAs, and navigate to the href.
  const navigateToSubsection = (subsection) => {
    if (subsection.isSinglePageApp) {
      changePageId(subsection.v2Info.pageId);
    }
    log(
      activeSuperTab.id,
      getSectionIdFromSubsection(subsection),
      subsection.id,
    );
    navigationService.navigateToLocation(subsection.href);

    return false; // so that href doesn't execute... example: https://stackoverflow.com/questions/45701647/how-to-add-an-onclick-but-dont-execute-href-url-in-a-a-tag
  };

  const getSectionIdFromSubsection = (subsection) => {
    let id;
    activeSuperTab.sectionTabs.forEach((tab) => {
      if (!tab.subTabs) {
        return;
      }
      tab.subTabs.forEach((subTab) => {
        if (subTab.v2Info.id === subsection.v2Info.id) {
          id = tab.id;
        }
      });
    });
    return id;
  };

  return (
    <ResizableMenu
      elements={subsections.map((subsection) => ({
        id: subsection.id,
        dataTestId: subsection.dataTestId,
        href: subsection.href,
        label: subsection.name,
      }))}
      activeElementId={
        subsections.find((subsection) => subsectionIsActive(subsection))?.id
      }
      onElementClickAction={navigateToSubsection}
      ariaLabel={messageService.get('TERTIARY')}
      dataTestId={'GW-tertiary-nav'}
      dataTestType="subTab"
    />
  );
}

SubsectionLevelNav.propTypes = {
  activeSuperTab: PropTypes.object,
  pageId: PropTypes.string,
  changePageId: PropTypes.func,
  debugMode: PropTypes.bool,
};
