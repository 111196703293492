import FocusTrap from 'focus-trap-react';
import { getTier1DataTestId } from '../../Service/testing';
import styles from './ImportantMessage.scss';
import IconButton from '../IconButton/IconButton';
import { messageService } from '../../Service/message-service';
import { CloseIcon } from '@qualtrics/ui-react/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { messageToDisplayStruct } from './types';
import { MessagesList } from './MessagesList';
import { LoadingSpinnerWrapper } from '../CommonMenuItems/LoadingSpinnerWrapper';
import { ImportantMessageErrorBanner } from './ImportantMessageErrorBanner';

const arrayHasElements = (array) => array && array.length > 0;

export const ImportantMessageDescription = ({
  onClose,
  messagesToDisplay,
  isMobileView,
  hasPartialError,
  reloadMessages,
  areMessagesLoaded,
}) => {
  return areMessagesLoaded ? (
    <FocusTrap
      focusTrapOptions={{
        returnFocusOnDeactivate: true,
      }}
    >
      <div
        style={{ maxHeight: `${window.innerHeight - 84}px` }}
        className={styles.description}
      >
        <div
          className={styles.descriptionWrapper}
          id={'GW-IMBA-message-description'}
        >
          {arrayHasElements(messagesToDisplay?.account) && (
            <>
              <h5 className={styles.sectionHeading}>
                {messageService.get('ACCOUNT_WARNINGS')}:
              </h5>
              <MessagesList messages={messagesToDisplay.account} />
            </>
          )}
          {arrayHasElements(messagesToDisplay?.project) && (
            <>
              <h5>{messageService.get('PROJECT_WARNINGS')}:</h5>
              <MessagesList messages={messagesToDisplay.project} />
            </>
          )}
        </div>
        {hasPartialError && (
          <ImportantMessageErrorBanner reloadMessages={reloadMessages} />
        )}
        <IconButton
          onClick={onClose}
          className={styles.closeButton}
          aria-label={messageService.get('CLOSE')}
          data-testid={getTier1DataTestId('close-warnings-button')}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </FocusTrap>
  ) : (
    <LoadingSpinnerWrapper
      isMobileView={isMobileView}
      className={styles.descriptionLoading}
      testIdPrefix={'ImportantMessage'}
    />
  );
};

ImportantMessageDescription.propTypes = {
  onClose: PropTypes.func.isRequired,
  messagesToDisplay: messageToDisplayStruct,
  isMobileView: PropTypes.bool,
  hasPartialError: PropTypes.bool,
  reloadMessages: PropTypes.func,
  areMessagesLoaded: PropTypes.bool,
};
