import { MenuItem } from '@qualtrics/ui-react';
import { getTier1DataTestId } from '../../../Service/testing';
import styles from '../account-menu.scss';
import React from 'react';

export const getMenuOptions = (accountMenuOptions) => {
  return accountMenuOptions?.map((option) => (
    <MenuItem
      onSelect={option.onSelect}
      key={option.label}
      data-testid={getTier1DataTestId('Account-Menu-CustomOption')}
      className={styles.menuItemPortals}
    >
      {option.label}
    </MenuItem>
  ));
};
