export const debounce = (func, delay) => {
  let timeout;

  return () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    // eslint-disable-next-line angular/timeout-service
    timeout = setTimeout(() => {
      func();
    }, delay);
  };
};

export const tryUntilSuccess = (func, delay, retries = 20) => {
  if (retries === 0) return;

  if (!func()) {
    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      tryUntilSuccess(func, delay, retries - 1);
    }, delay);
  }
};
