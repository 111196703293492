/* THIS FILE IS AUTOMATICALLY GENERATED BY `generate_configs_map.js`,
DO NOT MANUALLY EDIT */

import _interstitialdirectorycontact from './InterstitialDirectoryContact.json';
import _interstitialdirectoryemployee from './InterstitialDirectoryEmployee.json';
import _interstitialdirectorylocation from './InterstitialDirectoryLocation.json';
import _interstitialdirectoryhome from './InterstitialDirectoryhome.json';
import _accountsettingsv2 from './accountSettingsV2.json';
import _accountsettings from './accountsettings.json';
import _admin from './admin.json';
import _approvals from './approvals.json';
import _catalog from './catalog.json';
import _directoryhome from './directoryhome.json';
import _dxportal from './dxportal.json';
import _emihome from './emiHome.json';
import _extensions from './extensions.json';
import _extensionseua from './extensionsEUA.json';
import _extp360 from './extp360.json';
import _extpactionplans from './extpActionPlans.json';
import _extphome from './extpHome.json';
import _extpideaboards from './extpIdeaBoards.json';
import _extpteamvoice from './extpTeamVoice.json';
import _extpviewresults from './extpViewResults.json';
import _globalactions from './globalactions.json';
import _globaldatasets from './globaldatasets.json';
import _homepage from './homepage.json';
import _insightexplorer from './insightExplorer.json';
import _intersitialnavigationpage from './intersitialNavigationPage.json';
import _journeys from './journeys.json';
import _library from './library.json';
import _libraryv3home from './libraryv3home.json';
import _myprojects from './myprojects.json';
import _participantportal from './participantportal.json';
import _portalsextensions from './portalsExtensions.json';
import _portalssettings from './portalsSettings.json';
import _portalsapp from './portalsapp.json';
import _researchhub from './researchHub.json';
import _scheduling from './scheduling.json';
import _surveydirector from './surveydirector.json';
import _tickets from './tickets.json';
import _360 from './project/360.json';
import _360employeeled from './project/360EmployeeLed.json';
import _adtesting from './project/AdTesting.json';
import _advertisingpretest from './project/AdvertisingPreTest.json';
import _agilevideodiscovery from './project/AgileVideoDiscovery.json';
import _attitudeandusage from './project/AttitudeAndUsage.json';
import _bxdashboards from './project/BXDashboards.json';
import _barometer from './project/Barometer.json';
import _benefitsperks from './project/BenefitsPerks.json';
import _brandawareness from './project/BrandAwareness.json';
import _branddiagnostic from './project/BrandDiagnostic.json';
import _brandliftpremium from './project/BrandLiftPremium.json';
import _brandperception from './project/BrandPerception.json';
import _brandtracker from './project/BrandTracker.json';
import _brandtrustpulse from './project/BrandTrustPulse.json';
import _callcenterassistant from './project/CallCenterAssistant.json';
import _cardsort from './project/CardSort.json';
import _changemanagement from './project/ChangeManagement.json';
import _chobaniproducttest from './project/ChobaniProductTest.json';
import _compositeproject from './project/CompositeProject.json';
import _concepttesting from './project/ConceptTesting.json';
import _courseevaluations from './project/CourseEvaluations.json';
import _creativetesting from './project/CreativeTesting.json';
import _crossxmanalytics from './project/CrossXMAnalytics.json';
import _customerconfidencepulse from './project/CustomerConfidencePulse.json';
import _customereffortscore from './project/CustomerEffortScore.json';
import _customerpulse from './project/CustomerPulse.json';
import _customersatisfactionscore from './project/CustomerSatisfactionScore.json';
import _dashboardactionplanning from './project/DashboardActionPlanning.json';
import _dashboardexcommentsummaries from './project/DashboardEXCommentSummaries.json';
import _dashboardexspotlightinsights from './project/DashboardEXSpotlightInsights.json';
import _dashboardstatsiqfieldset from './project/DashboardStatsIQFieldset.json';
import _dashboardtextiqfieldset from './project/DashboardTextIQFieldset.json';
import _dashboardscxspotlightinsights from './project/DashboardsCXSpotlightInsights.json';
import _datasetanalysis from './project/DatasetAnalysis.json';
import _digitaladvertisingbrandlift from './project/DigitalAdvertisingBrandLift.json';
import _digitalopendoor from './project/DigitalOpenDoor.json';
import _distinctiveassets from './project/DistinctiveAssets.json';
import _diversityandinclusion from './project/DiversityAndInclusion.json';
import _ee from './project/EE.json';
import _excovidreturntowork from './project/EXCovidReturnToWork.json';
import _edschoolhome from './project/EdSchoolHome.json';
import _edteachhome from './project/EdTeachHome.json';
import _employeeadhoc from './project/EmployeeAdHoc.json';
import _employeelifecycle from './project/EmployeeLifecycle.json';
import _eventfeedback from './project/EventFeedback.json';
import _fftfemployeegiving from './project/FFTFEmployeeGiving.json';
import _featureawareness from './project/FeatureAwareness.json';
import _frequentpulsedashboard from './project/FrequentPulseDashboard.json';
import _frequentpulsetemplate from './project/FrequentPulseTemplate.json';
import _frontlinefeedback from './project/FrontlineFeedback.json';
import _frontlinefeedbacksolution from './project/FrontlineFeedbackSolution.json';
import _gwinternaltesting from './project/GWInternalTesting.json';
import _gwinternaltestingreact from './project/GWInternalTestingReact.json';
import _gaborgrangerpricesensitivity from './project/GaborGrangerPriceSensitivity.json';
import _guidedfaq from './project/GuidedFAQ.json';
import _healthcareworkpulse from './project/HealthcareWorkPulse.json';
import _highedschoolhome from './project/HighEdSchoolHome.json';
import _interactionview from './project/InteractionView.json';
import _kanoanalysis from './project/KanoAnalysis.json';
import _managerfeedback from './project/ManagerFeedback.json';
import _managerledsurveys from './project/ManagerLedSurveys.json';
import _maxdiffproject from './project/MaxDiffProject.json';
import _moderatedusertesting from './project/ModeratedUserTesting.json';
import _needsbased from './project/NeedsBased.json';
import _onlinereputationunconfigured from './project/OnlineReputation.Unconfigured.json';
import _onlinereputation from './project/OnlineReputation.json';
import _pxsolution from './project/PXSolution.json';
import _productnaming from './project/ProductNaming.json';
import _productsatisfaction from './project/ProductSatisfaction.json';
import _program from './project/Program.json';
import _programconcepttesting from './project/ProgramConceptTesting.json';
import _relationalnps from './project/RelationalNPS.json';
import _remoteworkpulse from './project/RemoteWorkPulse.json';
import _returntoworkpulse from './project/ReturnToWorkPulse.json';
import _singlesurveysolution from './project/SingleSurveySolution.json';
import _suppliercontpulse from './project/SupplierContPulse.json';
import _teameventfeedback from './project/TeamEventFeedback.json';
import _ticketsfollowupdetails from './project/TicketsFollowUpDetails.json';
import _totalxmreport from './project/TotalXMReport.json';
import _touchpoint from './project/Touchpoint.json';
import _trainingfeedback from './project/TrainingFeedback.json';
import _transactionalnps from './project/TransactionalNPS.json';
import _vanwestendorppricesensitivitymeter from './project/VanWestendorpPriceSensitivityMeter.json';
import _amazontastetest from './project/amazontastetest.json';
import _conjoint from './project/conjoint.json';
import _crayola_concepttest from './project/crayola_concepttest.json';
import _dashboard from './project/dashboard.json';
import _demosolution from './project/demosolution.json';
import _featurerequest from './project/featureRequest.json';
import _importdataproject from './project/importDataProject.json';
import _microsoft_kanoanalysis from './project/microsoft_kanoanalysis.json';
import _nba_teameffectiveness from './project/nba_teameffectiveness.json';
import _q_sales_customer_perception from './project/q_sales_customer_perception.json';
import _siteintercept from './project/siteIntercept.json';
import _siteinterceptsurvey from './project/siteIntercept.survey.json';
import _surveyproject from './project/surveyproject.json';
import _vocalize from './project/vocalize.json';
import _wafprojectv2editor from './project/wafProjectV2.editor.json';
import _wafprojectv2 from './project/wafProjectV2.json';
import _wafprojectv2sr from './project/wafProjectV2.sr.json';
import _wafprojectv2survey from './project/wafProjectV2.survey.json';
import _woolworths_concepttest from './project/woolworths_concepttest.json';
import _directoriessection from './directory/DirectoriesSection.json';
import _directory from './directory/Directory.json';
import _directorylite from './directory/DirectoryLite.json';
import _directorylitev2 from './directory/DirectoryLiteV2.json';
import _directorynonav from './directory/DirectoryNoNav.json';
import _directoryv2 from './directory/DirectoryV2.json';
import _globaldirectorysection from './directory/GlobalDirectorySection.json';
import _xmdactions from './directory/XmdActions.json';

const lookup = {
  'InterstitialDirectoryContact': {
    'ROOT': _interstitialdirectorycontact
  },
  'InterstitialDirectoryEmployee': {
    'ROOT': _interstitialdirectoryemployee
  },
  'InterstitialDirectoryLocation': {
    'ROOT': _interstitialdirectorylocation
  },
  'InterstitialIQDirectory': {
    'ROOT': _interstitialdirectoryhome
  },
  'AccountSettingsV2': {
    'ROOT': _accountsettingsv2
  },
  'AccountSettings': {
    'ROOT': _accountsettings
  },
  'Admin': {
    'ROOT': _admin
  },
  'Approvals': {
    'ROOT': _approvals
  },
  'Catalog': {
    'ROOT': _catalog
  },
  'IQDirectory': {
    'ROOT': _directoryhome,
    'Directory': _directory,
    'DirectoryLite': _directorylite,
    'DirectoryLiteV2': _directorylitev2,
    'DirectoryNoNav': _directorynonav,
    'DirectoryV2': _directoryv2,
    'XmdActions': _xmdactions
  },
  'DXPortal': {
    'ROOT': _dxportal
  },
  'EMIPortalHome': {
    'ROOT': _emihome
  },
  'Extensions': {
    'ROOT': _extensions,
    'PluginID': _extensionseua,
    'Plugin': _portalsextensions
  },
  'EXTeamPortal360': {
    'ROOT': _extp360
  },
  'EXTeamPortalActionPlanning': {
    'ROOT': _extpactionplans
  },
  'EXTeamPortalHome': {
    'ROOT': _extphome
  },
  'EXTeamPortalIdeaBoards': {
    'ROOT': _extpideaboards
  },
  'EXTeamPortalTeamVoice': {
    'ROOT': _extpteamvoice
  },
  'EXTeamPortalDashboards': {
    'ROOT': _extpviewresults
  },
  'GlobalActions': {
    'ROOT': _globalactions
  },
  'GlobalDatasets': {
    'ROOT': _globaldatasets
  },
  'Homepage': {
    'ROOT': _homepage
  },
  'InsightExplorerApp': {
    'ROOT': _insightexplorer
  },
  'InterstitialNavigationPage': {
    'ROOT': _intersitialnavigationpage
  },
  'Journeys': {
    'ROOT': _journeys
  },
  'Library': {
    'Library': _library
  },
  'LibraryV3': {
    'ROOT': _libraryv3home
  },
  'Project': {
    'ROOT': _myprojects,
    'ThreeSixtyProject': _360,
    'EmployeeLed360': _360employeeled,
    'AdTesting': _adtesting,
    'AdvertisingPreTest': _advertisingpretest,
    'AgileVideoDiscovery': _agilevideodiscovery,
    'AttitudeAndUsage': _attitudeandusage,
    'BXDashboardsProject': _bxdashboards,
    'Barometer': _barometer,
    'BenefitsPerks': _benefitsperks,
    'BrandAwareness': _brandawareness,
    'BrandDiagnostic': _branddiagnostic,
    'BrandLiftPremium': _brandliftpremium,
    'BrandPerception': _brandperception,
    'BrandTracker': _brandtracker,
    'BrandTrustPulse': _brandtrustpulse,
    'CallCenterAssistant': _callcenterassistant,
    'CardSort': _cardsort,
    'ChangeManagement': _changemanagement,
    'ChobaniProductTest': _chobaniproducttest,
    'CompositeProject': _compositeproject,
    'ConceptTesting': _concepttesting,
    'CourseEvaluations': _courseevaluations,
    'CreativeTesting': _creativetesting,
    'CrossXMAnalytics': _crossxmanalytics,
    'CustomerConfidencePulse': _customerconfidencepulse,
    'CustomerEffortScore': _customereffortscore,
    'CustomerPulse': _customerpulse,
    'CustomerSatisfactionScore': _customersatisfactionscore,
    'VocalizeDashboard.DashboardActionPlanning': _dashboardactionplanning,
    'VocalizeDashboard.DashboardEXCommentSummaries': _dashboardexcommentsummaries,
    'VocalizeDashboard.DashboardEXSpotlightInsight': _dashboardexspotlightinsights,
    'VocalizeDashboard.DashboardStatsIQFieldset': _dashboardstatsiqfieldset,
    'VocalizeDashboard.DashboardTextIQFieldset': _dashboardtextiqfieldset,
    'VocalizeDashboard.DashboardCXSpotlightInsight': _dashboardscxspotlightinsights,
    'DatasetAnalysis': _datasetanalysis,
    'DigitalAdvertisingBrandLift': _digitaladvertisingbrandlift,
    'DigitalOpenDoor': _digitalopendoor,
    'DistinctiveAssets': _distinctiveassets,
    'DiversityAndInclusion': _diversityandinclusion,
    'EmployeeEngagement': _ee,
    'EXCovidReturnToWork': _excovidreturntowork,
    'EdSchoolHome': _edschoolhome,
    'EdTeachHome': _edteachhome,
    'EmployeeAdHoc': _employeeadhoc,
    'EmployeeLifecycle': _employeelifecycle,
    'EventFeedback': _eventfeedback,
    'FFTFEmployeeGiving': _fftfemployeegiving,
    'FeatureAwareness': _featureawareness,
    'FrequentPulseDashboard': _frequentpulsedashboard,
    'FrequentPulseTemplate': _frequentpulsetemplate,
    'FrontlineFeedback': _frontlinefeedback,
    'FrontlineFeedbackSolution': _frontlinefeedbacksolution,
    'GWInternalTestingResource': _gwinternaltesting,
    'GWInternalTestingReactResource': _gwinternaltestingreact,
    'GaborGrangerPriceSensitivity': _gaborgrangerpricesensitivity,
    'GuidedFAQ': _guidedfaq,
    'HealthcareWorkPulse': _healthcareworkpulse,
    'HighEdSchoolHome': _highedschoolhome,
    'InteractionConfiguration': _interactionview,
    'KanoAnalysis': _kanoanalysis,
    'ManagerFeedback': _managerfeedback,
    'ManagerLedSurveys': _managerledsurveys,
    'MaxDiffProject': _maxdiffproject,
    'ModeratedUserTesting': _moderatedusertesting,
    'NeedsBased': _needsbased,
    'OnlineReputation.Unconfigured': _onlinereputationunconfigured,
    'OnlineReputation': _onlinereputation,
    'PXSolution': _pxsolution,
    'ProductNaming': _productnaming,
    'ProductSatisfaction': _productsatisfaction,
    'Program': _program,
    'ProgramConceptTesting': _programconcepttesting,
    'RelationalNPS': _relationalnps,
    'RemoteWorkPulse': _remoteworkpulse,
    'ReturnToWorkPulse': _returntoworkpulse,
    'SingleSurveySolution': _singlesurveysolution,
    'SupplierContPulse': _suppliercontpulse,
    'TeamEventFeedback': _teameventfeedback,
    'TicketsFollowUpDetails': _ticketsfollowupdetails,
    'TotalXMReport': _totalxmreport,
    'Touchpoint': _touchpoint,
    'TrainingFeedback': _trainingfeedback,
    'TransactionalNPS': _transactionalnps,
    'VanWestendorpPriceSensitivityMeter': _vanwestendorppricesensitivitymeter,
    'amazontastetest': _amazontastetest,
    'ConjointProject': _conjoint,
    'crayola_concepttest': _crayola_concepttest,
    'Dashboard': _dashboard,
    'demosolution': _demosolution,
    'FeatureRequest': _featurerequest,
    'ImportedData': _importdataproject,
    'microsoft_kanoanalysis': _microsoft_kanoanalysis,
    'nba_teameffectiveness': _nba_teameffectiveness,
    'q_sales_customer_perception': _q_sales_customer_perception,
    'SiteInterceptProject': _siteintercept,
    'siteIntercept.survey': _siteinterceptsurvey,
    'SurveyProject': _surveyproject,
    'Vocalize': _vocalize,
    'wafProjectV2.editor': _wafprojectv2editor,
    'wafProjectV2': _wafprojectv2,
    'wafProjectV2.sr': _wafprojectv2sr,
    'wafProjectV2.survey': _wafprojectv2survey,
    'woolworths_concepttest': _woolworths_concepttest
  },
  'ParticipantPortal': {
    'ROOT': _participantportal
  },
  'PortalsSettings': {
    'ROOT': _portalssettings
  },
  'PortalsApp': {
    'Portal': _portalsapp,
    'ROOT': _researchhub
  },
  'SchedulingApp': {
    'ROOT': _scheduling
  },
  'SurveyDirector': {
    'ROOT': _surveydirector
  },
  'Tickets': {
    'ROOT': _tickets
  },
  'Directory': {
    'ROOT': _directoriessection
  },
  'GlobalDirectory': {
    'ROOT': _globaldirectorysection
  }
};

export default lookup;
