import { isMac, isFirefox } from './tool-check';
import { sendTractiveMetric } from '@qualtrics/page-metrics/lib/tractiveService';
import { isPortalsApp, isPortalsAppChild } from './portals-service';

const FOCUSABLES_SELECTOR =
  'button:not([tabindex="-1"]), [href]:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])';

const regionMemoryType = 'regionMemory';
const regionMemory = {};

export default function qRegionListener(
  focusInSectionsInfo,
  postFocusAction,
  context,
  event,
) {
  if (
    event.key === 'F6' &&
    ((isFirefox() && event.altKey) ||
      (!isMac() && !isFirefox() && event.ctrlKey) ||
      (isMac() && !isFirefox() && event.metaKey))
  ) {
    const isPortalsApplication = isPortalsApp(context);
    const isPortalsChild = isPortalsAppChild(context);
    let iframe = null;

    // eslint-disable-next-line compat/compat
    const regions = getSortedRegions(document);

    const lastNonIframeRegionIndex = regions.length - 1;

    if (isPortalsApplication) {
      iframe = document.getElementById('portal-iframe').contentDocument;
      const iframeRegions = getSortedRegions(iframe);
      regions.push(...iframeRegions);
    }

    if (isPortalsChild) {
      const parentRegions = getSortedRegions(window.parent.document);
      regions.push(...parentRegions);
    }

    if (regions.length === 0) {
      return;
    }

    const startRegion = document.activeElement?.closest('[q-region-index]');
    let currRegion = startRegion;
    let focusableChild = null;
    let i = 0;
    while (!focusableChild) {
      const newFocusQRegion = event.shiftKey
        ? getPrevQRegion(regions, currRegion)
        : getNextQRegion(regions, currRegion);

      const iframeJump =
        regions.indexOf(newFocusQRegion) > lastNonIframeRegionIndex;

      if (!iframeJump) {
        focusableChild = getCustomFocusableChild(
          newFocusQRegion,
          focusInSectionsInfo,
        );
      }

      if (!focusableChild) {
        const focusableChildren =
          newFocusQRegion.querySelectorAll(FOCUSABLES_SELECTOR);
        for (const elem of focusableChildren) {
          if (!elem.closest('[style*="display: none;"]')) {
            focusableChild = elem;
            break;
          }
        }
      }

      if (focusableChild) {
        logF6Navigate(currRegion, newFocusQRegion, event.shiftKey);

        const currSectionId = currRegion?.id;
        if (
          focusInSectionsInfo &&
          focusInSectionsInfo[currSectionId]?.type === regionMemoryType
        ) {
          storeRegionMemory(currRegion);
        }

        focusableChild.focus();

        if (postFocusAction) postFocusAction();
      } else if ((i > 1 && currRegion === startRegion) || i > 10) {
        break;
      } else {
        currRegion = newFocusQRegion;
        i++;
      }
    }
    event.preventDefault();
  }
}

function getNextQRegion(regions, currRegion) {
  const i = regions.indexOf(currRegion);
  return regions[i + 1] ? regions[i + 1] : regions[0];
}

function getPrevQRegion(regions, currRegion) {
  const i = regions.indexOf(currRegion);
  return regions[i - 1] ? regions[i - 1] : regions[regions.length - 1];
}

function storeRegionMemory(region) {
  // find the closest (going up the DOM tree) element with an 'id' and store the ID
  const id = document.activeElement.closest('[id]')?.id;
  if (id) {
    regionMemory[region.id] = id;
  }
}

function getRegionMemory(region) {
  // check the memory from when we F6'd away
  if (regionMemory[region.id]) {
    const id = regionMemory[region.id];
    // find the closest (going down the DOM tree) focusable element
    const element = document.getElementById(id);
    if (element) {
      if (element.matches(FOCUSABLES_SELECTOR)) {
        return element;
      }
      const child = element.querySelector(FOCUSABLES_SELECTOR);
      if (child instanceof HTMLElement) {
        return child;
      }
    }
  }
}

function focusElementValidator(elementToFocus, region) {
  return region.contains(elementToFocus) &&
    elementToFocus?.matches(FOCUSABLES_SELECTOR)
    ? elementToFocus
    : null;
}

function logF6Navigate(fromRegionElem, toRegionElem, shiftKey) {
  const fromRegion = fromRegionElem
    ? parseInt(fromRegionElem.getAttribute('q-region-index'))
    : null;
  const toRegion = toRegionElem
    ? parseInt(toRegionElem.getAttribute('q-region-index'))
    : null;

  sendTractiveMetric({
    billingTeam: 'GW',
    eventName: 'Page.F6Navigate',
    customAttributes: {
      fromRegion: fromRegion,
      toRegion: toRegion,
      shiftKey: shiftKey,
    },
  });
}

function getCustomFocusableChild(region, focusInSectionsInfo) {
  return focusInSectionsInfo && region.id in focusInSectionsInfo
    ? focusInSectionsInfo[region.id]?.type === regionMemoryType
      ? getRegionMemory(region)
      : focusElementValidator(focusInSectionsInfo[region.id].handler(), region)
    : null;
}

function getSortedRegions(root) {
  // eslint-disable-next-line compat/compat
  let regions = Array.from(root.querySelectorAll('[q-region-index]'));

  regions = regions.sort((a, b) => {
    const indexA = parseInt(a.getAttribute('q-region-index'));
    const indexB = parseInt(b.getAttribute('q-region-index'));
    return indexA - indexB;
  });

  return regions;
}
