import { sendTractiveMetric } from '@qualtrics/page-metrics/lib/tractiveService';
import JSCookie from 'js-cookie';
import SessionStorageManager from './session-storage-manager';
import cookies, { getCookie } from './cookies';
import http from './http';
import { globalWrapperCache } from './global-wrapper-cache';
import { isPathAvailableInGivenPortal } from '@qualtrics-portals/portal-checker';
import { filterAsync } from '../../utils/filterAsync';
import { getDashboardType } from './dashboards-service';
import { isExportPage } from './tool-check';

const cache = new SessionStorageManager(window);
const portalIdCacheKey = (sessionId) => `portal_id_${sessionId}`;
const buildPortalCacheValue = (portalId, instanceId) => {
  if (instanceId) {
    return `${portalId}+${instanceId}`;
  }
  return portalId;
};

export const QUALTRICS_ID = 'Qualtrics';
const PARTICIPANT_PORTAL_ID = 'ParticipantPortal';
const DX_PORTAL_ID = 'DXPortal';
const DX_PORTAL_PBA_ID = 'dx-portal';

export const isPortalsApp = (ctx) => {
  return ctx?.contextType === 'PortalsApp';
};

export const isPortalsAppChild = (ctx) => {
  try {
    return !isPortalsApp(ctx) && window.parent.Q_PortalsContext !== undefined;
  } catch {
    // in case we're inside an iframe with cross-origin blocked and we cannot access parent
    return false;
  }
};

/**
 * @param ctx
 * @param {boolean} isLegacyPortalHeader
 * @param {string} pageId
 * @returns {{currentPortalId?: string, isParticipantPortal: boolean, isParticipantPortalInProject: boolean, isPortalsApplication: boolean, isPortalsChild: boolean}}
 */
export const getPortalInfo = (ctx, isLegacyPortalHeader, pageId) => {
  const isPortalsApplication = isPortalsApp(ctx);
  const isPortalsChild = isPortalsAppChild(ctx);
  const isParticipantPortal =
    isParticipantPortalApp(ctx) || isLegacyPortalHeader;
  const isParticipantPortalInProject =
    isParticipantPortalPage(pageId) && !isParticipantPortal;

  let currentPortalId = QUALTRICS_ID;
  let currentInstanceId;
  if (isPortalsApplication) {
    currentPortalId = ctx?.resourceId;
    currentInstanceId = ctx?.subresourceId;
  } else if (isParticipantPortal || isParticipantPortalInProject) {
    currentPortalId = PARTICIPANT_PORTAL_ID;
  } else if (isDXPortalApp(ctx)) {
    currentPortalId = DX_PORTAL_PBA_ID;
  }
  return {
    isPortalsApplication,
    isPortalsChild,
    isParticipantPortal,
    isParticipantPortalInProject,
    currentPortalId,
    currentInstanceId,
  };
};

export const notifyAboutContext = (ctx, activeSuperTab) => {
  if (isPortalsAppChild(ctx) && window.parent.Q_PortalsContext.onContextLoad) {
    window.parent.Q_PortalsContext.onContextLoad(ctx, activeSuperTab);
  }
};

export const getPortalIds = (ctx) => {
  if (isPortalsApp(ctx)) {
    return {
      portalId: ctx.resourceId,
      instanceId: ctx.subresourceId,
    };
  }
  if (isPortalsAppChild(ctx)) {
    return {
      portalId: window.parent.Q_PortalsContext.portalId,
      instanceId: window.parent.Q_PortalsContext.instanceId,
    };
  }
  return {
    portalId: undefined,
    instanceId: undefined,
  };
};

export const getAvailablePortals = async (language) => {
  const portalsUrl = `/portals/api/available-portals?includeURLLists=true&lang=${language}&includeExternalPortals=true`;
  const sessionId = cookies.get('UDSSessionKey');
  const key = `${portalsUrl}&UDSSessionKey=${sessionId}`;
  const cacheData = cache.getSessionStorage(key);
  if (cacheData) {
    return JSON.parse(cacheData).portals;
  } else {
    const availablePortals = http.getJSON(portalsUrl);
    return availablePortals
      .then((data) => {
        if (!data || !data.portals) {
          return [];
        }
        cache.setSessionStorage(key, JSON.stringify(data));
        return data.portals;
      })
      .catch((res) => {
        let message = 'Cannot get available portals';
        if (res) {
          message = res.status + ' ' + res.statusText;
        }
        console.error(message);
        return [];
      });
  }
};

export const getPortalModeCookieName = (userId) =>
  `${userId.toUpperCase()}_PORTAL_MODE`;

export const buildPortalModeCookieValue = (portalId, instanceId) =>
  instanceId ? `${portalId}+${instanceId}` : portalId;

/*
  Used only for defining if we should try to redirect user to a portal when loading GW outside of portal context
  Currently also used for AccountMenu to hide current portal
  And in Account Settings to determine if we should display simplified navigation
 */
export const parsePortalModeCookie = (userId) => {
  if (!userId) return {};
  const cookieName = getPortalModeCookieName(userId);
  const cookieValue = getCookie(cookieName);
  if (!cookieValue) {
    return {};
  }
  const cookieValueParts = cookieValue.split('+');
  return {
    portalIdFromCookie: cookieValueParts[0],
    instanceIdFromCookie: cookieValueParts[1],
  };
};

export const storePortalIds = (portalId, instanceId, userId) => {
  const sessionId = cookies.get('UDSSessionKey');
  const portalCacheValue = buildPortalCacheValue(portalId, instanceId);
  cache.setSessionStorage(portalIdCacheKey(sessionId), portalCacheValue);
  if (userId) {
    const { portalIdFromCookie, instanceIdFromCookie } =
      parsePortalModeCookie(userId);
    if (
      portalIdFromCookie !== portalId ||
      instanceIdFromCookie !== instanceId
    ) {
      const cookieValue = buildPortalModeCookieValue(portalId, instanceId);
      const cookieName = getPortalModeCookieName(userId);
      JSCookie.set(cookieName, cookieValue, { secure: true, expires: 365 });
    }
  }
};

export const getStoredPortalIds = () => {
  const sessionId = cookies.get('UDSSessionKey');
  const cacheValue = cache.getSessionStorage(portalIdCacheKey(sessionId)) || '';
  const valueParts = cacheValue.split('+');
  return {
    portalId: valueParts[0],
    instanceId: valueParts[1],
  };
};

export const switchPortalFromMenu = (
  targetPortalId,
  targetInstanceId,
  currentPortalId,
) => {
  sendTractiveMetric({
    billingTeam: 'EUA',
    eventName: 'App.Switch',
    customAttributes: {
      portalId: currentPortalId,
      switchTo: targetPortalId,
    },
  });
  storePortalIds(targetPortalId, targetInstanceId);
};

export const emitAppLoadEvent = (portalId, portals) => {
  const { portalId: currentPortalId } = getStoredPortalIds();
  if (currentPortalId !== window.parent.Q_PortalsContext?.portalId) {
    // if config not loaded yet, try again in 100ms (config is needed to define if custom logo and theme used)
    // eslint-disable-next-line angular/timeout-service
    return setTimeout(() => emitAppLoadEvent(portalId, portals), 100);
  }
  if (portalId !== 'Qualtrics') {
    const availablePortalIds = portals
      ? portals.map((portal) => portal.id)
      : [];
    sendTractiveMetric({
      billingTeam: 'EUA',
      eventName: 'App.Load',
      customAttributes: {
        portalId: portalId,
        availablePortalIds,
        hasCustomLogo: !!window.parent.Q_PortalsContext?.portalData?.logoUrl,
        hasCustomTheme:
          !!window.parent.Q_PortalsContext?.portalData?.headerColor,
      },
    });
  }
};
export const getSwitchPortalUrl = (portal, currentPortalId, portals) => {
  if (portal.portalType === 'EXTERNAL') {
    return portal.homeHref;
  }

  if (
    portal.id === QUALTRICS_ID &&
    currentPortalId === PARTICIPANT_PORTAL_ID &&
    !portals.find((portal) => portal.id === PARTICIPANT_PORTAL_ID)
  ) {
    // when switching to qualtrics from participant portal when there's no participant portal available
    // force a switch to homepage
    return `/portals/api/switch-portal?portalId=${portal.id}`;
  }
  return getRedirectPortalUrl(portal.id, portal.instanceId);
};

export const getRedirectPortalUrl = (portalId, instanceId, path) => {
  const link = window.location.href;
  const pathParam = path || link.replace(window.location.origin, '');
  const encodedPathParamPart = pathParam
    ? `&path=${encodeURIComponent(pathParam)}`
    : '';
  let redirectUrl = `/portals/api/switch-portal?portalId=${portalId}${encodedPathParamPart}`;
  if (instanceId) {
    redirectUrl += `&instanceId=${instanceId}`;
  }
  return redirectUrl;
};

export const redirectToPortal = (portalId, instanceId, path, userId) => {
  storePortalIds(portalId, instanceId, userId);
  window.location.href = getRedirectPortalUrl(portalId, instanceId, path);
};

export const isParticipantPortalApp = (ctx) => {
  return ctx?.contextType === PARTICIPANT_PORTAL_ID;
};

export const isDXPortalApp = (ctx) => {
  return ctx?.contextType === DX_PORTAL_ID;
};

export const isParticipantPortalPage = (pageId) => {
  return pageId === PARTICIPANT_PORTAL_ID;
};

export const selectPortalAndRedirect = (portalsValidForPath, path, userId) => {
  const selectedPortal = portalsValidForPath[0];

  if (selectedPortal.id === QUALTRICS_ID) {
    storePortalIds(QUALTRICS_ID, undefined, userId);
  } else {
    redirectToPortal(
      selectedPortal.id,
      selectedPortal.instanceId,
      path,
      userId,
    );
  }
};

const portalMatchesIds = (portal, portalId, instanceId) => {
  if (portal.id !== portalId) {
    return false;
  }
  if (instanceId) {
    return portal.instanceId === instanceId;
  }
  return true;
};

export const checkIfPortalIsValidAndRedirectIfNeeded = (
  portalIdToCheck,
  instanceIdToCheck,
  portalsValidForPath,
  path,
  userId,
  isPortalsRoadblockEnabled = false,
) => {
  if (
    portalsValidForPath.some((portal) =>
      portalMatchesIds(portal, portalIdToCheck, instanceIdToCheck),
    )
  ) {
    if (portalIdToCheck === QUALTRICS_ID) {
      storePortalIds(QUALTRICS_ID, undefined, userId);
    } else {
      globalWrapperCache.removeAllPortalConnectedItems();
      redirectToPortal(portalIdToCheck, instanceIdToCheck, path, userId);
    }
    return;
  }
  if (isPortalsRoadblockEnabled && portalsValidForPath.length > 1) {
    window.location.replace(
      `/portals/chooser/ui?path=${encodeURIComponent(
        path,
      )}&displayRoadblock=true`,
    );
  } else {
    selectPortalAndRedirect(portalsValidForPath, path, userId);
  }
};

export const checkIfPageIsValidInPortal = async (
  portals,
  context,
  userId,
  isRestrictedNavigation,
  pageId,
  isPortalsRoadblockEnabled,
) => {
  const { portalIdFromCookie } = parsePortalModeCookie(userId);
  if (isRestrictedNavigation || !portals || !portalIdFromCookie) {
    // shared user account, no portals support for them
    return;
  }
  if (isPortalsAppChild(context)) {
    // no action required for portals frames
    return;
  }
  if (isParticipantPortalApp(context) || isParticipantPortalPage(pageId)) {
    // for now treat ParticipantPortal as a special case of portal and don't redirect away from it in any case
    return;
  }
  if (isDXPortalApp(context)) {
    // for now treat DXPortal as a special case of portal and don't redirect away from it in any case
    return;
  }
  if (isExportPage()) {
    // export page should always be open in BigQualtrics mode as it's settings fields on window
    return;
  }
  if (isPortalsApp(context)) {
    // portals app, store current portal id
    const { portalId, instanceId } = getPortalIds(context);
    storePortalIds(portalId, instanceId, userId);
    emitAppLoadEvent(portalId, portals);
    return;
  }
  const fullPath = window.location.href.replace(window.location.origin, '');

  const portalsValidForPath = await filterAsync(
    portals,
    async (portal) =>
      portal.portalType !== 'EXTERNAL' &&
      (await isPathAvailableInGivenPortal(portal, fullPath, (dashboardId) =>
        getDashboardType(dashboardId, cache),
      )),
  );

  if (portalsValidForPath.length === 0) {
    const { portalId: storedPortalId } = getStoredPortalIds();
    sendTractiveMetric({
      billingTeam: 'EUA',
      eventName: 'App.BigQRedirect',
      customAttributes: {
        action: 'redirect',
        portalId: storedPortalId,
        destinationPath: fullPath,
      },
    });
    return;
  }
  const { portalId: storedPortalId, instanceId: storedInstanceId } =
    getStoredPortalIds();
  if (storedPortalId) {
    checkIfPortalIsValidAndRedirectIfNeeded(
      storedPortalId,
      storedInstanceId,
      portalsValidForPath,
      fullPath,
      userId,
      isPortalsRoadblockEnabled,
    );
  } else {
    const { portalIdFromCookie, instanceIdFromCookie } =
      parsePortalModeCookie(userId);
    checkIfPortalIsValidAndRedirectIfNeeded(
      portalIdFromCookie || QUALTRICS_ID,
      instanceIdFromCookie,
      portalsValidForPath,
      fullPath,
      userId,
      isPortalsRoadblockEnabled,
    );
  }
};
