import { log } from './clickstream';

export default class NavigationService {
  constructor(window, debugMode = false) {
    this.window = window;
    this.debugMode = debugMode;
  }

  navigateToLocation(href) {
    if (!this.debugMode) {
      this.window.location.href = href;
    }
  }
}

export function logNavigation({ contextType, href }, skipLog) {
  if (!skipLog) {
    log(contextType);
  }
}

export function navigateAfterEnterClick(e, href) {
  if (e.key === ' ' || e.key === 'Enter') {
    window.location.href = href;
  }
}
