import expiringLicense from './ConfigCatalog/expiring-license';
import g1Environment from './ConfigCatalog/g1-environment';
import proxyLogin from './ConfigCatalog/proxy-login';

const configRegistry = [expiringLicense, g1Environment, proxyLogin];

const configMap = configRegistry.reduce(
  (currentConfigMap, config) => ({
    ...currentConfigMap,
    [config.messageId]: config,
  }),
  {},
);

export const getConfig = (messageId) => {
  return configMap[messageId];
};
