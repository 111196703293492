import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu } from '@qualtrics/ui-react';
import { messageService } from '../../Service/message-service';
import { useWindowSize } from '../hooks/useWindowSize';
import styles from './account-menu.scss';
import { useUnreadBadgeValue } from './useUnreadBadgeValue';
import { getNotificationsAmountAriaText } from './getNotificationsAmountAriaText';
import { Anchor } from './MenuItems';
import { getTier1DataTestId } from '../../Service/testing';
import { usePortalsInfo, useUserInfo, useWorkMode } from '../../Context';
import { useScreenType } from '../hooks/useScreenType';
import { useAccountMenuElements } from './useAccountMenuElements';
import { useAddClosingFunction } from '../../Context/useAddClosingFunction';

export default function AccountMenu({
  zIndex,
  shouldDisplayNotifications = true,
  shouldDisplayHelp = true,
}) {
  const { isMobileView } = useScreenType();
  const { brandName, fullName, isLanguageJapaneseOrKorean } = useUserInfo();
  const { isPortalsApplication } = usePortalsInfo();
  const { debugMode } = useWorkMode();
  const { addClosingFunction } = useAddClosingFunction();
  const windowSize = useWindowSize();
  const unreadBadgeValue = useUnreadBadgeValue(isMobileView);
  const notificationsAmountAria = useMemo(
    () => getNotificationsAmountAriaText(unreadBadgeValue, isMobileView),
    [unreadBadgeValue, isMobileView],
  );

  const [buttonActive, setButtonActive] = React.useState(false);

  const accountMenuAriaLabel = useMemo(
    () =>
      buttonActive
        ? `${fullName} ${brandName}`
        : `${messageService.get('MY_ACCOUNT')}${notificationsAmountAria}`,
    [brandName, buttonActive, fullName, notificationsAmountAria],
  );

  const { currentElements, handlers } = useAccountMenuElements(
    setButtonActive,
    notificationsAmountAria,
    unreadBadgeValue,
    shouldDisplayNotifications,
    shouldDisplayHelp,
  );

  useEffect(() => {
    addClosingFunction(() => {
      setButtonActive(false);
    });
  }, []);

  return (
    <Menu
      flyoutClassName={classNames({
        [styles.portalsAccountMenu]: isPortalsApplication,
        [styles.fullMenuFlyout]: isMobileView,
      })}
      onIsOpenChange={handlers.handleIsOpenChange}
      isOpen={buttonActive}
      zIndex={zIndex}
      disabled={debugMode}
      id={getTier1DataTestId('Account')}
      anchor={
        <Anchor
          buttonActive={buttonActive}
          isLanguageJapaneseOrKorean={isLanguageJapaneseOrKorean}
          accountMenuAriaLabel={accountMenuAriaLabel}
          fullNameFirstChar={fullName[0]}
          isMobileView={isMobileView}
          isInPortalApp={isPortalsApplication}
          unreadBadgeValue={unreadBadgeValue}
        />
      }
      maxHeight={windowSize.height - 84}
    >
      {currentElements()}
    </Menu>
  );
}

AccountMenu.propTypes = {
  zIndex: PropTypes.number,
  shouldDisplayHelp: PropTypes.bool,
  shouldDisplayNotifications: PropTypes.bool,
};
