/* eslint jsx-a11y/anchor-is-valid: "off" */
/* eslint jsx-a11y/no-noninteractive-tabindex: "off" */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './home.scss';
import { getTier1DataTestId } from '../../Service/testing';
import PropTypes from 'prop-types';
import { messageService } from '../../Service/message-service';
import { XMLogo } from './xmLogo';
import { useHomeInfo, usePortalsInfo, useWorkMode } from '../../Context';

export default function Home({ isCentered }) {
  const {
    homeCallback,
    homeDefaultDestination,
    isCustomLogoEnabled,
    logoHref,
    logoUrl,
  } = useHomeInfo();
  const { debugMode } = useWorkMode();
  const { isPortalsApplication } = usePortalsInfo();
  const handleClick = (event) => {
    event.preventDefault();
    if (debugMode) {
      return;
    }
    if (homeCallback) {
      homeCallback();
    } else {
      window.location.href = homeDefaultDestination;
    }
  };
  const [hasLoadingErrorOccurred, setHasLoadingErrorOccurred] = useState(false);
  const onLoadingError = () => setHasLoadingErrorOccurred(true);

  useEffect(() => {
    setHasLoadingErrorOccurred(false);
  }, [logoUrl]);

  return (
    //once fully migrated off of the angularjs interface, we can remove event handlers, role, and `tabIndex` in favor of a custom HREF.
    <a
      href={homeCallback ? logoHref : homeDefaultDestination}
      className={classNames(
        styles.home,
        isCentered ? styles.centered : undefined,
      )}
      data-testid={getTier1DataTestId('Home')}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClick(e);
        }
      }}
      aria-label={
        logoUrl && !hasLoadingErrorOccurred
          ? messageService.get('COMPANY_LOGO')
          : messageService.get('HOMEPAGE')
      }
      tabIndex="0"
    >
      {!hasLoadingErrorOccurred && logoUrl ? (
        <img
          className={classNames(styles.themeLogo, {
            [styles.portalThemeLogo]: isPortalsApplication,
            [styles.bigLogo]:
              !isPortalsApplication && logoUrl && isCustomLogoEnabled,
          })}
          src={logoUrl}
          aria-hidden={true}
          alt={messageService.get('COMPANY_LOGO')}
          onError={onLoadingError}
          data-testid="GW-home-custom-logo"
        />
      ) : (
        <XMLogo />
      )}
    </a>
  );
}

Home.propTypes = {
  isCentered: PropTypes.bool,
};
