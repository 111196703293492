/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NavigationService from '../Service/navigation-service';
import { log } from '../Service/clickstream';
import { ResizableMenu } from '../ResizableMenu';
import { messageService } from '../Service/message-service';
import { useWorkMode } from '../Context';

export default function SectionLevelNav({
  activeSuperTab,
  pageId,
  changePageId,
  shouldShowSubsectionTabs,
}) {
  const { debugMode } = useWorkMode();
  const sections = useMemo(() => {
    const sectionTabs = activeSuperTab?.sectionTabs || [];

    sectionTabs.forEach((sectionTab) => {
      sectionTab.dataTestId = 'gw-tab-' + sectionTab.nameKey;
    });

    const tels = sectionTabs.filter((t) => {
      if (!t.subTabs) {
        return t.visible;
      }

      const e =
        t.subTabs.filter((subtab) => {
          return subtab.visible;
        }).length > 0;
      return e;
    });

    return tels;
  }, [activeSuperTab]);

  const activeSectionId = useMemo(() => {
    const sectionIsActive = (section) => {
      if (section.subTabs) {
        // if we are a tab group, search all the subTabs
        return section.subTabs.some(
          (subTab) => subTab.v2Info.pageId === pageId,
        );
      } else {
        return getPageId(section) === pageId;
      }
    };

    return sections.find(sectionIsActive)?.id;
  }, [sections, pageId]);

  const navigationService = new NavigationService(window, debugMode);
  function navigateToSection(section) {
    if (section.isSinglePageApp) {
      changePageId(getPageId(section));
    }
    log(activeSuperTab.id, section.id);

    navigationService.navigateToLocation(section.href);

    return false;
  }

  return (
    <ResizableMenu
      elements={sections.map((section) => ({
        id: section.id,
        href: section.href,
        label: section.name,
        dataTestId: section.dataTestId,
      }))}
      activeElementId={activeSectionId}
      onElementClickAction={navigateToSection}
      shouldShowSubsectionTabs={shouldShowSubsectionTabs}
      ariaLabel={messageService.get('SECONDARY')}
      dataTestId="GW-secondary-nav"
      dataTestType="sectionTab"
    />
  );
}

// getPageId is a helper fn to get the first subtab pageId a tab's pageId.
function getPageId(section) {
  return section.subTabs
    ? section.subTabs[0].v2Info.pageId
    : section.v2Info.pageId;
}

SectionLevelNav.propTypes = {
  activeSuperTab: PropTypes.object,
  pageId: PropTypes.string,
  changePageId: PropTypes.func,
  shouldShowSubsectionTabs: PropTypes.bool,
  debugMode: PropTypes.bool,
};
