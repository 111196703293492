import React from 'react';
import IconButton from '../../IconButton/IconButton';
import { messageService } from '../../../Service/message-service';
import { getTier1DataTestId } from '../../../Service/testing';
import { AccountIcon } from '@qualtrics/ui-react/icons';
import styles from '../account-menu.scss';
import PropTypes from 'prop-types';

export const Anchor = React.forwardRef(
  (
    {
      buttonActive,
      accountMenuAriaLabel,
      isLanguageJapaneseOrKorean,
      fullNameFirstChar,
      isMobileView,
      unreadBadgeValue,
      isInPortalApp,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        {...props}
        tooltipContent={messageService.get('MY_ACCOUNT')}
        active={buttonActive}
        id={getTier1DataTestId('Account')}
        data-testid={getTier1DataTestId('Account')}
        aria-label={accountMenuAriaLabel}
        zIndex={10002}
        ref={ref}
      >
        <>
          {isLanguageJapaneseOrKorean ? (
            <AccountIcon aria-hidden={true} />
          ) : (
            <div
              aria-hidden={true}
              className={
                isInPortalApp
                  ? styles.profileCircleInPortal
                  : styles.profileCircle
              }
            >
              {fullNameFirstChar}
            </div>
          )}
          {isMobileView && unreadBadgeValue ? (
            <span
              data-testid={getTier1DataTestId('Account-Unread-Dot')}
              className={styles.notificationBadgeIconStyle}
            >
              {unreadBadgeValue}
            </span>
          ) : null}
        </>
      </IconButton>
    );
  },
);

Anchor.displayName = 'Anchor';

Anchor.propTypes = {
  buttonActive: PropTypes.bool,
  accountMenuAriaLabel: PropTypes.string,
  isLanguageJapaneseOrKorean: PropTypes.bool,
  fullNameFirstChar: PropTypes.string,
  isMobileView: PropTypes.bool,
  unreadBadgeValue: PropTypes.string,
  isInPortalApp: PropTypes.bool,
};
