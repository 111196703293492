import cookies from './cookies';
import SessionStorageManager from './session-storage-manager';
import { v4 as uuidv4 } from 'uuid';
import http from './http';
import { getConfig } from '../ImportantMessageConfigs/config-registry';
import { messageService } from './message-service';
import { buildUrlWithoutNullishParams } from '@qualtrics/page-http-client/lib/getQueryString';

const cache = new SessionStorageManager(window);

const messageIdCacheKey = (sessionId) => `GW_imba_id_${sessionId}`;
const messagesStateCacheKey = (sessionId) => `GW_imba_state_${sessionId}`;
const messageServiceEndpoint = '/wrapper/v1/messages';

const buildMessageState = () => ({
  isMessagesRead: false,
});

export const initializeIMBA = async () => {
  const sessionId = cookies.get('UDSSessionKey');

  let messageServiceUrl = buildUrlWithoutNullishParams(messageServiceEndpoint, {
    UDSSessionKey: sessionId,
  });

  const messagesData = await getMessageData(sessionId, messageServiceUrl);
  const hasPartialError = !!messagesData.errors.length;

  return {
    messagesToDisplay: getMessagesToDisplay(messagesData),
    hasPartialError,
  };
};

export const clearIMBACache = () => {
  const sessionId = cookies.get('UDSSessionKey');
  cache.removeSessionStorage(messageIdCacheKey(sessionId));
  cache.removeSessionStorage(messagesStateCacheKey(sessionId));
};

export const getCacheMessagesState = async () => {
  const sessionId = cookies.get('UDSSessionKey');
  let cacheKey, messagesState;

  if (sessionId) {
    cacheKey = messagesStateCacheKey(sessionId);
    messagesState = cache.getSessionStorage(cacheKey);
  }

  if (messagesState) {
    messagesState = await JSON.parse(messagesState);
  } else {
    messagesState = buildMessageState();
    if (sessionId)
      cache.setSessionStorage(cacheKey, JSON.stringify(messagesState));
  }
  return messagesState;
};

export const cacheNewMessageState = async (newMessageStateProps) => {
  const sessionId = cookies.get('UDSSessionKey');
  const currentMessageState = await getCacheMessagesState();
  const newMessageState = { ...currentMessageState, ...newMessageStateProps };
  cache.setSessionStorage(
    messagesStateCacheKey(sessionId),
    JSON.stringify(newMessageState),
  );
  return newMessageState;
};

const getMessageData = async (sessionId, serviceUrl) => {
  let messagesData = cache.getSessionStorage(messageIdCacheKey(sessionId));

  if (messagesData) {
    messagesData = await JSON.parse(messagesData);
  } else {
    messagesData = await http.getJSON(serviceUrl, {
      'X-Transaction-ID': uuidv4(),
      'X-Request-ID': uuidv4(),
    });

    if (sessionId) {
      cache.setSessionStorage(
        messageIdCacheKey(sessionId),
        JSON.stringify(messagesData),
      );
    }
  }
  return messagesData;
};

const castIdToMessage = (messageData) => {
  const { bannerTag, descriptionTag, type, additionalAttributes } = getConfig(
    messageData.messageId,
  );

  const translatedAttributes = additionalAttributes.map((attribute) => {
    if (attribute.requireTranslate) {
      return messageService.get(
        messageData.additionalAttributes[attribute.name],
      );
    }
    return messageData.additionalAttributes[attribute.name];
  });

  return {
    type,
    message: {
      bannerId: bannerTag,
      descriptionId: descriptionTag,
      additionalAttributes: translatedAttributes,
    },
  };
};

const getMessagesToDisplay = (messagesData) => {
  return messagesData.messages.reduce(
    (messagesToDisplay, message) => {
      const result = castIdToMessage(message);
      messagesToDisplay[result.type] = [
        ...messagesToDisplay[result.type],
        result.message,
      ];
      return messagesToDisplay;
    },
    { account: [], project: [] },
  );
};
