import qRegionListener from '../../Service/q-region-listener';
import { useEffect } from 'react';

/** @typedef f6NavigationProps
 * @property {?boolean} isEnabled
 * @property {boolean} focusInSectionsInfo
 * @property {func} postFocusAction
 */

/**
 * @param {?f6NavigationProps} f6Navigation
 * @param {object} context
 */
export const useF6Navigation = (context, f6Navigation) => {
  const enableF6Navigation = () => {
    if (f6Navigation?.isEnabled) {
      const f6Listener = qRegionListener.bind(
        undefined,
        f6Navigation.focusInSectionsInfo,
        f6Navigation.postFocusAction,
        context,
      );

      document.addEventListener('keydown', f6Listener);
      return () => document.removeEventListener('keydown', f6Listener);
    }
  };

  useEffect(() => {
    enableF6Navigation();
  }, []);
};
