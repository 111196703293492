import React from 'react';
import PropTypes from 'prop-types';
import styles from './icon-button.scss';
import classNames from 'classnames';
import { Tooltip } from '@qualtrics/ui-react';

const IconButton = React.forwardRef(function IconButton(
  {
    active,
    children,
    component,
    className,
    tooltipContent,
    zIndex = 100,
    tooltipClassName,
    ...rest
  },
  ref,
) {
  const classes = classNames(className, styles.iconButton, {
    [styles.active]: active,
  });

  if (typeof component === 'function') {
    return component({ ...rest, children, className: classes });
  }

  if (!tooltipContent) {
    return (
      <button ref={ref} {...rest} type="button" className={classes}>
        {children}
      </button>
    );
  }

  return (
    <Tooltip
      lightTheme
      content={tooltipContent}
      delayMs={500}
      disabled={active}
      zIndex={zIndex}
      className={tooltipClassName}
    >
      <button ref={ref} {...rest} type="button" className={classes}>
        {children}
      </button>
    </Tooltip>
  );
});

IconButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.func,
  tooltipContent: PropTypes.string.isRequired,
  zIndex: PropTypes.number,
  tooltipClassName: PropTypes.string,
};

export default IconButton;
