// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingSpinnerWrapper_menuLoading__KVYxk{height:40px;display:flex;align-items:center;justify-content:center}.LoadingSpinnerWrapper_menuOptionSmallScreens__3gC1K{width:auto !important}\n", ""]);
// Exports
exports.locals = {
	"menuLoading": "LoadingSpinnerWrapper_menuLoading__KVYxk",
	"menuOptionSmallScreens": "LoadingSpinnerWrapper_menuOptionSmallScreens__3gC1K"
};
module.exports = exports;
