import { Divider } from '@qualtrics/ui-react';
import { MenuItem } from '@qualtrics/ui-react';
import { getTier1DataTestId } from '../../../Service/testing';
import styles from '../account-menu.scss';
import { messageService } from '../../../Service/message-service';
import React from 'react';
import { ChevronRightIcon } from '@qualtrics/ui-react/icons';

export const getMobileViewComponents = (
  isMobileView,
  notificationsAmountAria,
  unreadBadgeValue,
  personLanguage,
  shouldDisplayNotifications,
  shouldDisplayHelp,
) => {
  if (!isMobileView) return null;
  const isAnyTab =
    shouldDisplayNotifications || shouldDisplayHelp || personLanguage;
  return [
    <Divider key={'divider1'} />,
    personLanguage && (
      <MenuItem
        key={'person-language'}
        onSelect={() => {
          document
            .getElementById('global-wrapper-person-language-switcher')
            ?.click();
        }}
        data-testid={getTier1DataTestId('Account-Menu-Language')}
        className={styles.menuItemPortals}
      >
        {messageService.get('LANGUAGE_SWITCHER')}
        <ChevronRightIcon
          className={styles.rightChevronIcon}
          aria-hidden={true}
        />
      </MenuItem>
    ),
    shouldDisplayHelp && (
      <MenuItem
        key={'account-menu-help'}
        onSelect={() => {
          document.getElementById('support-modal-link')?.click();
        }}
        data-testid={getTier1DataTestId('Account-Menu-Help')}
        className={styles.menuItemPortals}
      >
        {messageService.get('HELP')}
      </MenuItem>
    ),
    shouldDisplayNotifications && (
      <MenuItem
        key={'account-menu-notifications'}
        onSelect={() => {
          // setTimeout is needed here, without it notifications feed opens and closes almost immediately
          // eslint-disable-next-line angular/timeout-service
          setTimeout(
            () => document.getElementById('xm-feed-placeholder-v2')?.click(),
            16,
          );
        }}
        data-testid={getTier1DataTestId('Account-Menu-Notifications')}
        className={styles.menuItemPortals}
        aria-label={`${messageService.get(
          'NOTIFICATIONS',
        )}${notificationsAmountAria}`}
      >
        {messageService.get('NOTIFICATIONS')}
        {unreadBadgeValue ? (
          <span
            data-testid={getTier1DataTestId('Account-Menu-Unread-Dot')}
            className={styles.notificationBadgeStyle}
            aria-hidden={true}
          >
            {unreadBadgeValue}
          </span>
        ) : null}
      </MenuItem>
    ),
    isAnyTab && <Divider key={'divider2'} />,
  ];
};
