import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';

const getScreenType = (windowWidth) => {
  const breakpoints = [
    { screen: 'large', minWidth: 1025 },
    { screen: 'medium', minWidth: 769 },
    { screen: 'small', minWidth: 481 },
    { screen: 'xsmall', minWidth: 320 },
  ];

  const currentBreakpoint = breakpoints.find(
    ({ minWidth }) => windowWidth >= minWidth,
  );
  return currentBreakpoint?.screen ?? 'xsmall';
};

export const useScreenType = () => {
  const { width } = useWindowSize();
  const [screenType, setScreenType] = useState('large');
  const isSmallScreen = screenType === 'small' || screenType === 'xsmall';
  const isxSmallScreen = screenType === 'xsmall';
  useEffect(() => {
    setScreenType(getScreenType(width ?? 1000));
  }, [setScreenType, width]);

  return {
    screenType,
    isSmallScreen,
    isxSmallScreen,
    isMobileView: isxSmallScreen,
  };
};
