export default {
  messageId: 'expiring-license',
  bannerTag: 'EXPIRING_LICENSE',
  descriptionTag: 'EXPIRING_LICENSE_DESCRIPTION',
  type: 'account',
  additionalAttributes: [
    {
      name: 'days',
      requireTranslate: false,
    },
  ],
};
