import classNames from 'classnames';
import styles from '../account-menu.scss';
import { AccountIcon } from '@qualtrics/ui-react/icons';
import React from 'react';

export const getAccountInfo = (
  isInPortalApp,
  isLanguageJapaneseOrKorean,
  fullName,
  brandName,
) => {
  return (
    <div
      className={classNames(
        isInPortalApp
          ? styles.userInfoPortalsInPortal
          : styles.userInfoPortalsInStudio,
        styles.menuItem,
      )}
      key="menu-account-info"
    >
      {isLanguageJapaneseOrKorean ? (
        <AccountIcon aria-hidden={true} />
      ) : (
        <div aria-hidden={true} className={styles.profileCircleInnerMenuPortal}>
          {fullName[0]}
        </div>
      )}
      <div className={styles.userData}>
        <div className={styles.userFullName}>{fullName}</div>
        <div className={styles.brandName}>{brandName}</div>
      </div>
    </div>
  );
};
