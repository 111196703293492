import { useContext, useMemo } from 'react';
import { AppContext } from './AppContext';
/**
 * @typedef {UserPropertiesType} UserPropertiesHook
 * @property {string} fullName
 * @property {boolean} isLanguageJapaneseOrKorean
 */

/** @returns {UserPropertiesHook}*/
export const useUserInfo = () => {
  const context = useContext(AppContext);
  const { firstName, lastName, language } = context.userInfo || {};
  const isLanguageJapaneseOrKorean = useMemo(() => {
    if (!language) {
      return false;
    }
    const languageLowerCase = language.toLowerCase();
    return languageLowerCase === 'ja' || languageLowerCase === 'ko';
  }, [language]);

  return {
    ...context.userInfo,
    fullName: `${firstName} ${lastName}`,
    isLanguageJapaneseOrKorean,
  };
};
