// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErrorComponent_errorItem__1jwSg{box-sizing:border-box;justify-content:space-between;border:1px solid #BB0000;background:#FFFBFB !important;border-radius:4px;width:292px;margin:4px 4px;cursor:auto;line-height:normal;padding:0;align-items:flex-start;box-shadow:none !important}.ErrorComponent_errorItem__1jwSg:focus{background:#FFFBFB !important;box-shadow:none !important}.ErrorComponent_errorItem__1jwSg .ErrorComponent_tryAgainButton__2io-J{cursor:pointer;color:#0B6ED0;outline:none}.ErrorComponent_errorItem__1jwSg .ErrorComponent_tryAgainButton__2io-J:focus-visible{box-shadow:0 0 0 2px #0a3f88}.ErrorComponent_errorItem__1jwSg svg{fill:#BB0000;margin:6px 8px;width:19px;height:19px}.ErrorComponent_errorItem__1jwSg .ErrorComponent_errorText__HDOrr{margin:8px 8px 8px 0}.ErrorComponent_menuOptionSmallScreens__22QhZ{width:auto !important}\n", ""]);
// Exports
exports.locals = {
	"errorItem": "ErrorComponent_errorItem__1jwSg",
	"tryAgainButton": "ErrorComponent_tryAgainButton__2io-J",
	"errorText": "ErrorComponent_errorText__HDOrr",
	"menuOptionSmallScreens": "ErrorComponent_menuOptionSmallScreens__22QhZ"
};
module.exports = exports;
