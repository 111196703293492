import { messageService } from '../../Service/message-service';

const moreThanMaxRegex = /^(\d+)\+$/;
const oneDigitAmountRegex = /^[1-9]$/;

export const getNotificationsAmountAriaText = (
  unreadNotificationsBadge,
  isMobileView,
) => {
  const getAriaText = () => {
    unreadNotificationsBadge = String(unreadNotificationsBadge).trim();
    if (moreThanMaxRegex.test(unreadNotificationsBadge)) {
      return messageService.get('NOTIFICATIONS_MANY_NEW', '9');
    } else if (oneDigitAmountRegex.test(unreadNotificationsBadge)) {
      return messageService.get('NOTIFICATIONS_NEW', unreadNotificationsBadge);
    }

    return messageService.get('NOTIFICATIONS_NO_NEW');
  };

  return isMobileView ? `, ${getAriaText()}` : '';
};
