import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  clearIMBACache,
  initializeIMBA,
  getCacheMessagesState,
  cacheNewMessageState,
} from '../../Service/imba-service';

/** @typedef {Object} MessagesState
 * @property {boolean} isMessageRead - property defer if user should see red dot indicator
 */

/** @typedef {Object} ImportantMessagesHook
 * @property {boolean} messagesToDisplay
 * @property {boolean} hasPartialError
 * @property {function} reloadMessages
 * @property {string} areMessagesLoaded
 * @property {boolean} isMessagesRead
 * @property {MessagesState} changeMessagesState
 */

/** @returns  ImportantMessagesHook */
export const useImportantMessages = () => {
  const [messagesToDisplay, setMessagesToDisplay] = useState(null);
  const [hasPartialError, setHasPartialError] = useState(false);
  const [messagesLoadingState, setMessagesLoadingState] = useState('loading');
  const [isMessagesRead, setIsMessagesRead] = useState(true);
  const areMessagesLoaded = useMemo(
    () => messagesLoadingState === 'loaded',
    [messagesLoadingState],
  );
  const fetchMessages = useCallback(async () => {
    const { messagesToDisplay: messages, hasPartialError: partialError } =
      await initializeIMBA();
    setMessagesToDisplay(messages);
    setHasPartialError(partialError);
    setMessagesLoadingState('loaded');
  }, [messagesToDisplay, hasPartialError]);

  const fetchMessagesState = useCallback(async () => {
    const { isMessagesRead: isMessagesReadState } =
      await getCacheMessagesState();
    setIsMessagesRead(isMessagesReadState);
  }, [cacheNewMessageState]);

  useEffect(() => {
    fetchMessages();
    fetchMessagesState();
  }, []);

  const reloadMessages = async () => {
    setMessagesLoadingState('loading');
    clearIMBACache();
    await fetchMessages();
  };

  /** @param  {MessagesState} newStateProps */
  const changeMessagesState = useCallback(async (newStateProps) => {
    const newMessagesState = await cacheNewMessageState(newStateProps);
    setIsMessagesRead(newMessagesState.isMessagesRead);
  }, []);

  return {
    messagesToDisplay,
    hasPartialError,
    reloadMessages,
    areMessagesLoaded,
    isMessagesRead,
    changeMessagesState,
  };
};
