import { RUM } from '@qualtrics/page-metrics/lib/rumLogger';
import { SumoLogger } from '@qualtrics/page-metrics/lib/sumoService';
import { LogQueueService } from '@qualtrics/page-metrics/lib/logQueueService';

let metadata;
let logger;

export function initializeMetadata(rumMetadata) {
  metadata = rumMetadata;
  // eslint-disable-next-line new-cap
  logger = SumoLogger(LogQueueService('globalWrapperClient', metadata));
}

export function initializeLogsObserver(metricsDelegate) {
  const timingBaseline = Date.now();
  metricsDelegate.subscribeObserver({
    onAppInteractive: () => {
      RUM.setMetricTime('home.global_wrapper.rum', 'appInteractive');
    },
    onAppComplete: () => {
      RUM.setMetricTime('home.global_wrapper.rum', 'appComplete');
      RUM.postComponentTimings(
        'home.global_wrapper.rum',
        timingBaseline,
        metadata,
      );
    },
    onVisibilityEvalFailure: (
      errorMessage,
      contextType,
      additionalKeyPairs,
    ) => {
      logger?.logError?.(errorMessage, additionalKeyPairs);
    },
    onBundleFailure: (errorMessage, additionalKeyPairs) => {
      logger?.logError?.(errorMessage, additionalKeyPairs);
    },
    error: (message, additionalKeyPairs) => {
      logger?.logError?.(message, additionalKeyPairs);
    },
  });
}
