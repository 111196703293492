import SessionStorageManager from './session-storage-manager';
import { messageService } from './message-service';
import http from './http';
import cookies from './cookies';
import parseHeaders from 'parse-headers';

const cache = new SessionStorageManager(window);

const getShouldUseDynamicNavigationValidatorForContext = {
  Project: ({ resourceId, resourceType }, features) => {
    return (
      (features['GlobalWrapper.EnableCompositeProjects'] &&
        resourceType === 'CompositeProject') ||
      (features['GlobalWrapper.EnableNonCompositeProjects'] && !!resourceId)
    );
  },
};

const getDynamicNavigationUrl = (ctx) => {
  switch (ctx.contextType) {
    case 'Project':
      return `/project-store/api/projects/${ctx.resourceId}/navigation/tabs`;
  }
  return '';
};

const mapDynamicTab = (
  { pageId, nameTranslationType, nameKey, name, href, subTabs },
  ctx,
) => {
  const { contextType } = ctx;
  const isInlineTranslation = nameTranslationType === 'inline';
  const dynamicTab = {
    id: pageId,
    name: isInlineTranslation ? name : messageService.get(nameKey),
    nameKey,
    href,
    isDynamic: true,
    v2Info: {
      pageId,
      contextType,
    },
  };

  if (subTabs) {
    dynamicTab.subTabs = subTabs.map((dynamicSubTab) =>
      mapDynamicTab(dynamicSubTab, ctx),
    );
  }
  return dynamicTab;
};

const mapDynamicSectionTabs = ({ sections }, ctx) => {
  return sections.map((dynamicSectionTab) =>
    mapDynamicTab(dynamicSectionTab, ctx),
  );
};

const fetchDynamicSections = async (ctx, language) => {
  const dynamicNavigationUrl = getDynamicNavigationUrl(ctx);
  const sessionId = cookies.get('UDSSessionKey');
  const cacheKey = dynamicNavigationUrl + '?UDSSessionKey=' + sessionId;
  const cacheData = cache.getSessionStorage(cacheKey);
  if (cacheData) {
    const dynamicSectionsTabs =
      JSON.parse(cacheData) !== null
        ? {
            dynamicSectionsTabs: mapDynamicSectionTabs(
              JSON.parse(cacheData),
              ctx,
            ),
          }
        : null;
    return Promise.resolve(dynamicSectionsTabs);
  }
  try {
    const { dynamicSectionsResponse, dynamicSectionsHeader } = await http
      .request({
        method: 'GET',
        url: dynamicNavigationUrl,
        headers: {
          'translation-language': language,
          'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
        },
      })
      .then(({ response, headers }) => {
        return {
          dynamicSectionsResponse: JSON.parse(response),
          dynamicSectionsHeader: parseHeaders(headers),
        };
      });

    const isCacheable =
      dynamicSectionsHeader['cache-control'] !== undefined
        ? !dynamicSectionsHeader['cache-control'].includes('no-cache')
        : true;

    if (!dynamicSectionsResponse.sections && isCacheable) {
      cache.setSessionStorage(cacheKey, JSON.stringify(null));
      return Promise.resolve(null);
    }
    const dynamicSectionsTabs = {
      dynamicSectionsTabs: mapDynamicSectionTabs(dynamicSectionsResponse, ctx),
    };

    if (isCacheable) {
      cache.setSessionStorage(
        cacheKey,
        JSON.stringify(dynamicSectionsResponse),
      );
    }

    return Promise.resolve(dynamicSectionsTabs);
  } catch {
    return Promise.resolve(null);
  }
};

export const getDynamicSectionsForCurrentContextConfig = async (
  currentCtx,
  features,
  language,
) => {
  const shouldUseDynamicNavigationValidator =
    getShouldUseDynamicNavigationValidatorForContext[currentCtx.contextType];

  if (shouldUseDynamicNavigationValidator?.(currentCtx, features)) {
    return fetchDynamicSections(currentCtx, language);
  }

  return Promise.resolve(null);
};
