// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".skip-to-main-content-link_skipToContentLink__2PUhW{position:absolute !important;left:6px;top:6px;z-index:1000;transform:translate(0, -200%)}.skip-to-main-content-link_skipToContentLink__2PUhW:focus{transform:translate(0, 0)}.skip-to-main-content-link_skipToContentLink__2PUhW.skip-to-main-content-link_portalsApp__swZ4b{top:16px}\n", ""]);
// Exports
exports.locals = {
	"skipToContentLink": "skip-to-main-content-link_skipToContentLink__2PUhW",
	"portalsApp": "skip-to-main-content-link_portalsApp__swZ4b"
};
module.exports = exports;
