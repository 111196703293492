import 'core-js/features/promise/finally'; // Don't touch this because e6 Promises don't include finally and other applications may expect .finally to work.

import qFooter from './footer';
import qHeader from './header';

import ReactHeader from '../../react/navigation/ReactHeader';
import { messageService, } from '../../react/navigation/Service/message-service';
import ReactFooter from '../../react/footer/ReactFooter';

// prettier-ignore
angular.module('research-suite-wrapper', [])
  /* eslint angular/di: "off" */
  .directive('qFooter', qFooter)
  .directive('qHeader', qHeader)
  .value('globalWrapperMessageService', messageService)
  .provider('rsw.userService', LegacyUserServiceProvider)

    // React entry
    .component('globalWrapperReactHeader', ReactHeader).value('BundleService', getBundleService)
    .component('globalWrapperReactFooter', ReactFooter);

function LegacyUserServiceProvider() {
    this.setUser = function () {
    };
    this.$get = [
        function userService() {
            return {
                getBundleFallback: function () {
                }
            };
        }
    ];
}

function getBundleService() {
    return {
        user: {
            userId: 'userId',
            brandId: 'brandId',
            userName: 'userName',
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            language: 'LN',
            accountType: 'accountType',
            accountCreationDate: 'accountCreationDate'
        },
        brand: {
            brandName: 'brandName',
            brandType: 'brandType'
        },
        features: {
            HUB_FEED_UI: true
        }
    };
}