import PropTypes from 'prop-types';

export const messageStruct = PropTypes.shape({
  bannerId: PropTypes.string.isRequired,
  descriptionId: PropTypes.string.isRequired,
  additionalAttributes: PropTypes.arrayOf(PropTypes.string),
});

export const messageToDisplayStruct = PropTypes.shape({
  account: PropTypes.arrayOf(messageStruct),
  project: PropTypes.arrayOf(messageStruct),
});
