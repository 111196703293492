import wrapConfig from '../wrapper.js';
import lookupV2 from './lookup.js';

var exported = {
  getConfig: getConfig,
  customtabs: wrapConfig(getCustomTabs),
};

export default exported;

function getCustomTabs(customTabs) {
  customTabs = customTabs || [];
  var tabs = customTabs.map(buildCustomTab);
  return {
    nameKey: 'ADD_ONS',
    visible: customTabs.length > 0,
    defaultHref: (tabs[0] || { href: '' }).href,
    id: 'CustomTabSection',
    v2Info: {
      id: 'CustomTabs',
      contextType: 'CustomTabs',
    },
    sectionTabs: tabs,
  };
}

function buildCustomTab(customTab) {
  return {
    name: customTab.description,
    id: customTab.tabId,
    visible: true,
    visibilityExpression: 'true',
    href: '/Q/' + customTab.tabId,
    v2Info: {
      pageId: customTab.tabId,
      contextType: 'CustomTabs',
    },
  };
}

function getConfig(ctx, useV2Params) {
  var subKey = ctx.resourceType || 'ROOT';

  if (lookupV2[ctx.contextType] === undefined) {
    console.log('BAD', ctx.contextType);
  }

  // only accepting "survey" for now until we clean up the config that use both resource and subresource, but only 1 level of breadcrumb

  const isSurveySubresource =
    ctx.contextType === 'Project' &&
    ctx.subresourceType !== undefined &&
    ctx.subresourceType === 'survey';

  const VocalizeDashboardWithFakeSubresource =
    ctx.contextType === 'Project' &&
    ctx.subresourceType !== undefined &&
    ctx.resourceType === 'VocalizeDashboard';

  if (isSurveySubresource || VocalizeDashboardWithFakeSubresource) {
    subKey += '.' + ctx.subresourceType;
  }

  const cfg = lookupV2[ctx.contextType][subKey];

  if (cfg === undefined) {
    console.log('ANOTHER BAD', ctx.contextType, '+', subKey);
  }

  return wrapConfig(function () {
    return JSON.parse(JSON.stringify(cfg));
  })(ctx, useV2Params);
}
