// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".breadcrumb_anchorButton__3sTim{box-shadow:none !important;border-style:hidden !important;max-width:400px !important;background-color:#fff !important;font-size:16px !important}.breadcrumb_anchorButton__3sTim:hover{color:#0b6ed0 !important;background-color:#fff !important}.breadcrumb_anchorButton__3sTim:hover svg{fill:#0b6ed0 !important}.breadcrumb_anchorButton__3sTim:active{color:#0854a1 !important;background-color:#fff !important}.breadcrumb_anchorButton__3sTim:active svg{fill:#0854a1 !important}.breadcrumb_anchorButton__3sTim.breadcrumb_disabled__1r3Eq{pointer-events:none;color:#74777a !important;cursor:default !important}.breadcrumb_anchorButton__3sTim.breadcrumb_disabled__1r3Eq svg{opacity:0}@media screen and (max-width: 767px){.breadcrumb_anchorButton__3sTim{padding-left:0 !important}}.breadcrumb_breadcrumbContainer__4KyUy,.breadcrumb_loadingBreadcrumb__QYGXh{font-size:16px;line-height:36px;display:flex;justify-content:center;align-items:center}.breadcrumb_loadingBreadcrumb__QYGXh{padding:0 12px}.breadcrumb_ancestorLink__2DHOU{text-decoration:none !important;color:#74777a !important;padding:0 12px;max-width:400px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.breadcrumb_ancestorLink__2DHOU:before{border-bottom:none !important}.breadcrumb_ancestorLink__2DHOU:hover{color:#0b6ed0 !important}.breadcrumb_ancestorLink__2DHOU:active{color:#0854a1 !important}.breadcrumb_anchorButton__3sTim:focus-visible,.breadcrumb_ancestorLink__2DHOU:focus-visible{border-radius:5px !important;box-shadow:0 0 0 2px #fff,0 0 0 4px #0a3f88 !important}\n", ""]);
// Exports
exports.locals = {
	"anchorButton": "breadcrumb_anchorButton__3sTim",
	"disabled": "breadcrumb_disabled__1r3Eq",
	"breadcrumbContainer": "breadcrumb_breadcrumbContainer__4KyUy",
	"loadingBreadcrumb": "breadcrumb_loadingBreadcrumb__QYGXh",
	"ancestorLink": "breadcrumb_ancestorLink__2DHOU"
};
module.exports = exports;
