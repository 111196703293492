import React, { useState, useEffect } from 'react';
import * as projectsAPI from '../Service/projects';
import { ChevronRightIcon } from '@qualtrics/ui-react/icons';
import * as resourcesAPI from '../Service/resources';
import styles from './nested-breadcrumb.scss';
import { useV2Info } from '../Context';

export default function NestedBreadcrumb() {
  const { context } = useV2Info();
  const placeholderText = '...';
  const resourceID = context.resourceId;
  const subresourceID = context.subresourceId;

  const [resource, setResource] = useState({ name: placeholderText });
  const [subresourceName, setSubresourceName] = useState(placeholderText);

  const fetchResource = () => {
    projectsAPI
      .getProject(resourceID)
      .then((resourceObject) => {
        setResource(resourceObject);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchSubresource = () => {
    resourcesAPI
      .getResource(resourceID, subresourceID)
      .then((subresourceObject) => {
        setSubresourceName(subresourceObject?.resourceName ?? placeholderText);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleResourceNameClick = (event) => {
    event.preventDefault();

    if (resource.url) {
      window.location = resource.url;
    }
  };

  useEffect(() => {
    if (!resourceID) {
      console.error(
        'Nested Breadcrumb failed to fetch resources because no resourceId was passed',
      );
      return;
    }
    fetchSubresource();
    fetchResource();
  }, []);

  return (
    <div
      role="link"
      onKeyDown={(event) =>
        event.key === 'Enter' && handleResourceNameClick(event)
      }
      tabIndex="0"
      className={styles.container}
    >
      <button
        tabIndex="-1"
        onClick={(event) => handleResourceNameClick(event)}
        className={styles.projectName}
      >
        {resource?.name ?? placeholderText}
      </button>
      <ChevronRightIcon aria-hidden={true} />
      {subresourceName}
    </div>
  );
}
