import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from '../ResizableMenu.scss';
import PropTypes from 'prop-types';

export const MainElement = forwardRef(
  (
    {
      isActive,
      shouldShowSubsectionTabs,
      key,
      style,
      dataTestId,
      href,
      label,
      onClick,
      ariaCurrent,
      dataTestType,
    },
    ref,
  ) => {
    return (
      <li
        data-testtype={dataTestType}
        data-testid={dataTestId}
        data-active={isActive}
        className={classNames(styles.gwSectionTab, {
          active: isActive,
          [styles.active]: isActive,
        })}
        ref={ref}
        style={style}
      >
        <a
          href={href}
          onClick={onClick}
          onKeyDown={(e) => (e.key === 'Enter' ? onClick() : undefined)}
          tabIndex="0"
          aria-current={ariaCurrent}
        >
          <div className={styles.text}>{label}</div>
          <div className={styles.underline} />
        </a>
      </li>
    );
  },
);

MainElement.propTypes = {
  isActive: PropTypes.bool,
  shouldShowSubsectionTabs: PropTypes.bool,
  key: PropTypes.string,
  style: PropTypes.object,
  dataTestId: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  ariaCurrent: PropTypes.string,
  dataTestType: PropTypes.string,
};
MainElement.displayName = 'MainElement';
