import { sendTractiveMetric } from '@qualtrics/page-metrics/lib/tractiveService';
import http from './http';
import { globalWrapperCache } from './global-wrapper-cache';
import { logNavigation } from './navigation-service';

export default class AccountActionsService {
  constructor(user, portalId, portals, isAuthnLogoutEnabled) {
    this.user = user;
    this.portalId = portalId;
    this.availablePortalIds = portals ? portals.map((portal) => portal.id) : [];
    this.isAuthnLogoutEnabled = isAuthnLogoutEnabled;
  }

  onMenuOpen() {
    sendTractiveMetric({
      billingTeam: 'GW',
      eventName: 'AccountMenu.Open',
      customAttributes: {
        portalId: this.portalId,
      },
    });
  }

  onAppSwitchMenuOpen() {
    sendTractiveMetric({
      billingTeam: 'GW',
      eventName: 'AppSwitchingMenu.Open',
      customAttributes: {
        portalId: this.portalId,
        availablePortalIds: this.availablePortalIds,
      },
    });
  }

  accountSettings(accountSettingsSection, isInsidePortal) {
    sendTractiveMetric({
      billingTeam: 'GW',
      eventName: 'AccountSettings.Click',
      customAttributes: {
        amplitudeEvent: 'navigation',
        portalId: this.portalId,
      },
    });
    logNavigation(accountSettingsSection, true);
    const newAccountSettingsEnabled =
      accountSettingsSection.contextType === 'AccountSettingsV2';
    window.open(
      accountSettingsSection.href,
      isInsidePortal || newAccountSettingsEnabled ? '_blank' : '_self',
    );
  }

  logout() {
    sendTractiveMetric({
      billingTeam: 'GW',
      eventName: 'Logout.Click',
      customAttributes: {
        amplitudeEvent: 'navigation',
        portalId: this.portalId,
      },
    });

    //  TODO put some spinner/overlay the entire page
    globalWrapperCache.removeAll();
    if (this.isAuthnLogoutEnabled) {
      window.location.href = '/authn/api/v1/logout';
    } else {
      window.location.href = '/Q/IdentitySection/Post/Logout';
    }
  }

  /**
   * This can't be a link because angular needs to add X-XSRF-TOKEN to the header via the $http service
   * @param {bool} redirectToMySurveys if true, redirect to mysurveys after opt out, else reload the section
   */
  refresh() {
    sendTractiveMetric({
      billingTeam: 'GW',
      eventName: 'AccountMenu.RefreshUserInformation.Click',
      customAttributes: {
        amplitudeEvent: 'navigation',
        portalId: this.portalId,
      },
    });

    globalWrapperCache.removeAll();
    const refreshUrl =
      '/Q/IdentitySection/Post/RefreshUserInformation?SubsequentRedirect=false';

    const refreshAccountPromise = http.getWithCsrf(refreshUrl);

    return refreshAccountPromise
      .then(() => {
        window.location.reload();
      })
      .catch((res) => {
        let message =
          'There was an error refreshing the session via accountActions.';
        if (res) {
          message =
            res.status +
            ' ' +
            res.statusText +
            ' from ' +
            res.config.url +
            ' when attempting to refresh the session.';
        }
        console.error(message);
      });
  }
}
