// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".nested-breadcrumb_container__2Nw7A{font-style:normal;font-weight:normal;font-size:16px;line-height:21px;color:#32363a;display:flex;justify-content:center;align-items:center;padding:7px 16px 7px 16px}.nested-breadcrumb_container__2Nw7A:focus{outline:none}.nested-breadcrumb_container__2Nw7A:focus-visible{border-radius:5px;box-shadow:0 0 0 2px #fff,0 0 0 4px #0a3f88 !important}.nested-breadcrumb_container__2Nw7A .nested-breadcrumb_projectName__2qTat{cursor:pointer;color:#74777a;padding:0px;font-size:inherit;border:none;background-color:#fff;outline:none;box-shadow:none}.nested-breadcrumb_container__2Nw7A .nested-breadcrumb_projectName__2qTat:hover{color:#085caf}.nested-breadcrumb_container__2Nw7A .nested-breadcrumb_projectName__2qTat:active{color:#0854a1}.nested-breadcrumb_container__2Nw7A svg{margin-left:4px;margin-right:4px}\n", ""]);
// Exports
exports.locals = {
	"container": "nested-breadcrumb_container__2Nw7A",
	"projectName": "nested-breadcrumb_projectName__2qTat"
};
module.exports = exports;
