import { Delegate } from '@qualtrics/page-metrics/lib/metrics-emitter';
import { initializeMetricsObserver } from './metrics-observer';
import { initializeLogsObserver, initializeMetadata } from './log-observer';

let metricsDelegate;

let isMetricsInitialized = false;
let isRumMetaDataInitialized = false;
let isPreInitializationRumDataSet = false;
let isAppCompletePosted = false;

function initializeMetricsService() {
  if (!isMetricsInitialized) {
    metricsDelegate = new Delegate();
    initializeMetricsObserver(metricsDelegate);
    initializeLogsObserver(metricsDelegate);
    isMetricsInitialized = true;
  }
}

function initializeBaseRumMetaData(metadata) {
  if (!isPreInitializationRumDataSet) {
    initializeMetadata(metadata);
    isPreInitializationRumDataSet = true;
  }
}

function initializeRumMetaData(metadata) {
  if (!isRumMetaDataInitialized) {
    initializeMetadata(metadata);
    isRumMetaDataInitialized = true;
  }
}

function logBundleFailure(error, msg) {
  if (isMetricsInitialized) {
    metricsDelegate.raiseEvent('onBundleFailure', msg, {
      errorMsg: error?.message,
    });
  }
  console.error(msg);
}

function logVisibilityEvalFailure(error, msg, contextType) {
  if (isMetricsInitialized) {
    metricsDelegate.raiseEvent('onVisibilityEvalFailure', msg, contextType, {
      errorMsg: error?.message,
      contextType,
    });
  }
  console.error(msg);
}

function onAppInteractive() {
  if (!isAppCompletePosted && isMetricsInitialized) {
    metricsDelegate.raiseEvent('onAppInteractive');
  }
}

function onAppComplete() {
  if (!isAppCompletePosted && isMetricsInitialized) {
    metricsDelegate.raiseEvent('onAppComplete');
    isAppCompletePosted = true;
  }
}

function onProjectTypeDifference(projectType, resourceType, pageId) {
  metricsDelegate.raiseEvent(
    'error',
    'Inconsistent ProjectType and ResourceType',
    { projectType, resourceType, pageId },
  );
}

export {
  initializeMetricsService,
  logBundleFailure,
  logVisibilityEvalFailure,
  onAppInteractive,
  onAppComplete,
  initializeRumMetaData,
  onProjectTypeDifference,
  initializeBaseRumMetaData,
};
