import React, { useCallback } from 'react';
import { Button } from '@qualtrics/ui-react';
import PropTypes from 'prop-types';
import { messageService } from '../../Service/message-service';
import skipToMainContent from '../../Service/skip-to-main-content';
import classNames from 'classnames';
import styles from './skip-to-main-content-link.scss';

export default function SkipToMainContentLink({
  mainContentId,
  isPortalsApplication,
}) {
  const skipToMainOnClick = useCallback(
    () => skipToMainContent(mainContentId),
    [mainContentId],
  );
  const skipToMainOnKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        skipToMainContent(mainContentId);
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [mainContentId],
  );

  return (
    <Button
      kind={'primary'}
      data-testid={'skip-to-main-content-link'}
      className={classNames(
        styles.skipToContentLink,
        isPortalsApplication ? styles.portalsApp : undefined,
      )}
      onClick={skipToMainOnClick}
      onKeyDown={skipToMainOnKeyDown}
    >
      {messageService.get('SKIP_TO_MAIN_CONTENT')}
    </Button>
  );
}

SkipToMainContentLink.propTypes = {
  mainContentId: PropTypes.string,
  isPortalsApplication: PropTypes.bool,
};
