import http from './http';
import cookies from './cookies';
import SessionStorageManager from './session-storage-manager';
import { v4 as uuidv4 } from 'uuid';

const cache = new SessionStorageManager(window);

export default function BundleService() {
  return {
    getBundle: getBundle,
  };

  function getBundle() {
    const sessionId = cookies.get('UDSSessionKey');

    let bundleEndpoint = '/wrapper/v1/bundle';

    if (sessionId) {
      bundleEndpoint = bundleEndpoint + '?UDSSessionKey=' + sessionId;
    }

    const key = bundleEndpoint;
    const cacheData = cache.getSessionStorage(key);

    if (cacheData) {
      return Promise.resolve(JSON.parse(cacheData));
    } else {
      return http
        .getJSON(bundleEndpoint, {
          'X-Transaction-ID': uuidv4(),
          'X-Request-ID': uuidv4(),
        })
        .then(function (responseObject) {
          if (!responseObject.features) {
            responseObject.features = {};
          }

          sessionId
            ? cache.setSessionStorage(key, JSON.stringify(responseObject))
            : undefined;
          return responseObject;
        })
        .catch(function (err) {
          const errorMessage = `Unable to obtain wrapper bundle due to: ${JSON.stringify(
            err,
          )}`;
          console.error(errorMessage);
          return Promise.reject(new Error(errorMessage));
        });
    }
  }
}
