import { useEffect, useMemo, useState } from 'react';
import { debounce, tryUntilSuccess } from '../../../utils/debounce';
import { useWindowSize } from '../../TopLevelNav/hooks/useWindowSize';

export const useResizeableMenuProps = (
  elements,
  activeElementId,
  listRef,
  elementsRef,
  menuAnchorRef,
) => {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [firstHiddenIndex, setFirstHiddenIndex] = useState(elements.length);
  const { width } = useWindowSize();

  const isMoreMenuHidden = useMemo(
    () => firstHiddenIndex >= elements.length,
    [firstHiddenIndex, elements],
  );

  const isMoreMenuActive = useMemo(() => {
    return (
      !isMoreMenuHidden &&
      elements.filter(
        (element, i) => i >= firstHiddenIndex && element.id === activeElementId,
      ).length > 0
    );
  }, [elements, firstHiddenIndex, isMoreMenuHidden]);

  const [wasCalculated, setWasCalculated] = useState(false);
  const [rightEdgesInitialPositions, setRightEdgesInitialPositions] = useState(
    [],
  );

  const calculateInitialPositions = () => {
    if (elementsRef.current && !wasCalculated) {
      const initialPositions = elementsRef.current.map((element) => {
        const position = element?.getBoundingClientRect?.();

        return (
          position.x +
          position.width -
          listRef.current.getBoundingClientRect().x
        );
      });

      if (
        initialPositions.length > 0 &&
        initialPositions.reduce((a, b) => a + b, 0) === 0
      ) {
        return false;
      }

      setWasCalculated(true);
      setRightEdgesInitialPositions(initialPositions);
    }

    return true;
  };

  useEffect(() => {
    tryUntilSuccess(calculateInitialPositions, 100);
  }, [elementsRef.current]);

  const calculateVisibleCards = () => {
    if (!menuAnchorRef?.current) return false;
    const menuButtonWidth =
      menuAnchorRef?.current?.getBoundingClientRect?.()?.width;
    const availableWidth = width - menuButtonWidth;
    let lastIndex = rightEdgesInitialPositions.length;
    for (let i = 0; i < rightEdgesInitialPositions.length; i++) {
      if (rightEdgesInitialPositions[i] > width) {
        lastIndex = i;
        break;
      }
    }

    if (lastIndex === rightEdgesInitialPositions.length) {
      setFirstHiddenIndex(lastIndex);
      return true;
    }

    for (let i = 0; i < rightEdgesInitialPositions.length; i++) {
      if (rightEdgesInitialPositions[i] > availableWidth) {
        setFirstHiddenIndex(i);
        return true;
      }
    }

    setFirstHiddenIndex(rightEdgesInitialPositions.length);
    return true;
  };

  const debouncedCalculateVisibleCards = debounce(calculateVisibleCards, 20);

  useEffect(() => {
    if (rightEdgesInitialPositions.length > 0) {
      tryUntilSuccess(calculateVisibleCards, 50);
    }
  }, [rightEdgesInitialPositions]);

  useEffect(() => {
    if (rightEdgesInitialPositions.length > 0) {
      debouncedCalculateVisibleCards();
    }
  }, [width, rightEdgesInitialPositions]);

  useEffect(() => {
    if (isMoreMenuHidden) {
      setIsMoreMenuOpen(false);
    }
  }, [isMoreMenuHidden]);

  return {
    firstHiddenIndex,
    moreMenu: {
      isHidden: isMoreMenuHidden,
      isActive: isMoreMenuActive,
      isOpen: isMoreMenuOpen,
      setIsOpen: setIsMoreMenuOpen,
    },
  };
};
