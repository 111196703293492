import { useEffect, useState } from 'react';

const XM_FEED_ID = 'xm-feed-placeholder-v2';
const UNREAD_BADGE_ID = 'notifications-unread-dot';

export const useUnreadBadgeValue = () => {
  const [unreadBadgeValue, setUnreadBadgeValue] = useState(null);
  useEffect(() => {
    const targetNode = document.getElementById(XM_FEED_ID);
    const config = { subtree: true, childList: true };

    const callback = (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          const [addedUnreadDot = null] = [
            ...mutation.addedNodes.values(),
          ].filter((el) => el.id === UNREAD_BADGE_ID);
          if (addedUnreadDot !== null) {
            setUnreadBadgeValue(addedUnreadDot.textContent);
          } else {
            const [removedUnreadDot = null] = [
              ...mutation.removedNodes.values(),
            ].filter((el) => el.id === UNREAD_BADGE_ID);

            if (removedUnreadDot !== null) {
              setUnreadBadgeValue(null);
            }
          }
        }
      }
    };
    const observer = new MutationObserver(callback);

    try {
      if (targetNode) {
        observer.observe(targetNode, config);
      }
    } catch (e) {
      setUnreadBadgeValue(null);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return unreadBadgeValue;
};
