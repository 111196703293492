import { messageService } from '../Service/message-service';

export const showSectionTabs = (activeSuperTab, activePageId) => {
  const sectionTabs = activeSuperTab?.sectionTabs || [];
  const visibleSectionTabCount = sectionTabs.filter(function (sectionTab) {
    return sectionTab.visible;
  }).length;

  return (
    visibleSectionTabCount > 1 ||
    showSubsectionTabs(activeSuperTab, activePageId)
  );
};

export const showSubsectionTabs = (activeSuperTab, activePageId) => {
  const sectionTabs = activeSuperTab?.sectionTabs || [];

  let show = false;

  sectionTabs
    .filter((sectionTab) => !!sectionTab.subTabs) // look in tab groups
    .forEach((sectionTab) => {
      const visible = sectionTab.subTabs.filter(
        (subTab) => subTab.visible,
      ).length; // count visible
      const active = sectionTab.subTabs.find(
        (subTab) => subTab?.v2Info.pageId === activePageId,
      ); // only count where a subtab matches the pageId
      show = show || (active && visible > 1);
    });

  return show;
};

export const getUser = (user) => {
  return {
    fullName: messageService.get(
      'FIRST_AND_LAST_NAME',
      user.firstName,
      user.lastName,
    ),
    userId: user.userId,
    brandId: user.brandId,
    brandName: user.brandName,
    language: user.language,
    accountType: user.accountType,
  };
};
