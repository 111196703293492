import { versions, fallbackTranslations } from './../../../lang';
import { loadTranslations } from './translations-load-service';

const EN_LANGUAGE = 'EN';
const FR_LANGUAGE = 'FR';
const SECTIONS = ['Researchsuitewrapperglobal', 'Researchsuitewrapper'];

class MessageService {
  constructor() {
    this.preferredLanguageMessages = null;
    this.devMode = false;
    this.deferred = null;
    this.deferInit = new Promise((resolve) => {
      this.deferred = { resolve };
    });
  }

  async initialize(language) {
    const setPreferredLanguage = async (language) => {
      if (!language) {
        console.error(`No language specified. Falling back to ${EN_LANGUAGE}`);
        language = EN_LANGUAGE;
      } else if (language.toLowerCase() === 'dev') {
        this.devMode = true;
        return;
      }

      if (language === EN_LANGUAGE) {
        this.preferredLanguageMessages = fallbackTranslations;
        return;
      }

      this.preferredLanguageMessages = await loadTranslations(
        language,
        SECTIONS,
        versions[language],
      );

      if (
        !this.preferredLanguageMessages &&
        language.toLowerCase() === 'fr-ca'
      ) {
        // fallback to French if French Canadian doesn't have a translation
        console.error(
          `Translations in ${language} language could not be fetched. Fetching fallback translations in ${FR_LANGUAGE} language...`,
        );
        language = FR_LANGUAGE;
        this.preferredLanguageMessages = await loadTranslations(
          language,
          SECTIONS,
          versions[language],
        );
      }

      if (!this.preferredLanguageMessages) {
        console.error(
          `Translations in ${language} language could not be fetched. Fallback to ${EN_LANGUAGE}`,
        );
        this.preferredLanguageMessages = fallbackTranslations;
      } else {
        this.preferredLanguageMessages = Object.assign(
          this.preferredLanguageMessages.Researchsuitewrapper || {},
          this.preferredLanguageMessages.Researchsuitewrapperglobal || {},
        );
      }
    };

    if (!this.preferredLanguageMessages) {
      await setPreferredLanguage(language);
      this.languageKey = language;
      this.deferred.resolve();
    }
  }

  waitForInit() {
    return this.deferInit;
  }

  // Accepts extra arguments as piped arguments to qmessage
  get(key) {
    if (!this.preferredLanguageMessages && this.devMode === false) {
      throw new Error(
        'Wrapper messages not yet initialized. Call messageService.initialize() before messageService.get().',
      );
    }

    if (this.devMode) {
      return getUndefinedKeyValue(key);
    }

    let value =
      this.preferredLanguageMessages[key] ||
      fallbackTranslations[key] ||
      getUndefinedKeyValue(key);

    if (arguments.length > 1) {
      const params = Array.prototype.slice.call(arguments, 1);
      value = mixInTemplateParams(value, params);
    }

    return value;

    function getUndefinedKeyValue(key) {
      return '#RESEARCHSUITEWRAPPER, ' + key + '#';
    }

    function mixInTemplateParams(value, params) {
      // It looks like this is not a case that can be hit, leaving for now to reduce code changes
      if (params && params.length) {
        for (let i = 0; i <= params.length; i++) {
          value = value.replace('%' + (i + 1), params[i]);
        }
      }
      return value;
    }
  }
}

const messageService = new MessageService();

export { messageService };

export default MessageService;
