import { MenuItem } from '@qualtrics/ui-react';
import { getTier1DataTestId } from '../../../Service/testing';
import styles from '../account-menu.scss';
import { messageService } from '../../../Service/message-service';
import React from 'react';

export const getLogout = (logout) => {
  return (
    <MenuItem
      onSelect={logout}
      data-testid={getTier1DataTestId('Account-Menu-Logout')}
      className={styles.menuItemPortals}
      key={getTier1DataTestId('Account-Menu-Logout')}
    >
      {messageService.get('LOGOUT')}
    </MenuItem>
  );
};
