import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TopLevelNav from '../TopLevelNav/TopLevelNav';
import SectionLevelNav from '../SectionLevelNav/SectionLevelNav';
import SubsectionLevelNav from '../SubsectionLevelNav/SubsectionLevelNav';
import { UIProvider } from '@qualtrics/ui-react';
import { notifyAboutContext } from '../Service/portals-service';
import { initialize } from '../Service/initialization-service';
import { showSectionTabs, showSubsectionTabs, getUser } from './tabsUtils';

// Full import path need due to this issue: https://github.com/facebook/jest/issues/7271
import '@qualtrics/base-styles/dist/base-styles.css';
import SkipToMainContentAttacher from './enableShipToMainContent/SkipToMainContentAttacher';
import {
  logBundleFailure,
  onAppComplete,
  onAppInteractive,
} from '../Service/metrics-service';
import {
  TabsContextWrapper,
  useTabs,
  useTabsInitialization,
} from '../hooks/useTabs/useTabs';
import { shouldHideEntireNavInChildIframe } from '../Service/program-child-checker-service';
import { AppContextProvider } from '../Context';
import { useF6Navigation } from '../TopLevelNav/hooks/useF6Navigation';
import { useContextSetup } from './useContextSetup';
import { useMenuCloser } from '../hooks/useMenuCloser';

export default function ReactContainerWithContext(props) {
  return (
    <TabsContextWrapper>
      <ReactContainer {...props} />
    </TabsContextWrapper>
  );
}

export function ReactContainer(props) {
  const {
    angularBreadcrumb,
    breadcrumb: reactBreadcrumb,
    language,
    context = {},
    pageId,
    options = {},
    onLoaded,
    accessibilityOptions,
    debugMode,
  } = props;

  const [activePageId, setActivePageId] = useState(pageId);
  const { loadTabs } = useTabsInitialization();
  const { areSectionsLoaded, sections, activeSuperTab } = useTabs();
  const [hasAppCompleteBeenCalled, setHasAppCompleteBeenCalled] =
    useState(false);

  const [initializeError, setInitializeError] = useState(null);

  const addClosingFunction = useMenuCloser(options?.subscribeToMenuClosing);

  const { readyToRender, translations, setUpContext, appContext, logoUrl } =
    useContextSetup({ ...props, addClosingFunction });

  const accessibilityConfigOptions =
    accessibilityOptions ?? options.accessibilityOptions;

  // because we also allow GW consumers to update the page-id passed to `q-header`,
  // we also setActivePageId when pageId changes.
  useEffect(() => {
    setActivePageId(pageId);
    notifyAboutContext(context, activeSuperTab);
  }, [pageId]);

  useEffect(() => {
    if (debugMode) {
      setUpContext(debugMode);
    } else {
      initialize(
        language,
        context,
        pageId,
        loadTabs,
        !!options.isExternalAppMode,
      )
        .then((config) => {
          setUpContext(config);
        })
        .catch((error) => {
          setInitializeError(error);
        });
    }
  }, [debugMode]);

  useEffect(() => {
    if (initializeError) {
      logBundleFailure(
        initializeError,
        'Global Wrapper Client could not obtain initialize data.',
      );
      throw initializeError;
    }
  }, [initializeError]);

  useEffect(() => {
    if (readyToRender) {
      onAppInteractive();
    }
  }, [readyToRender]);

  useEffect(() => {
    if (readyToRender && areSectionsLoaded && !hasAppCompleteBeenCalled) {
      setHasAppCompleteBeenCalled(true);
      onAppComplete();
      if (onLoaded) {
        onLoaded({ superTabs: sections, activeSuperTab });
      }
    }
  }, [
    readyToRender,
    areSectionsLoaded,
    hasAppCompleteBeenCalled,
    onLoaded,
    sections,
    activeSuperTab,
  ]);

  useF6Navigation(context, accessibilityConfigOptions?.f6Navigation || {});

  const shouldHideNav = shouldHideEntireNavInChildIframe();
  const shouldShowSectionTabs =
    !shouldHideNav && showSectionTabs(activeSuperTab, activePageId);
  const shouldShowSubsectionTabs =
    !shouldHideNav && showSubsectionTabs(activeSuperTab, activePageId);

  return readyToRender ? (
    <div data-testid="header-root">
      {/* contract with #product-growth team */}
      <div id="trial-expiration-banner-anchor" />
      <UIProvider config={{ localizedText: translations }}>
        <AppContextProvider initialState={appContext} logoUrl={logoUrl}>
          <SkipToMainContentAttacher
            mainContentId={accessibilityConfigOptions?.mainContentId}
          />
          <TopLevelNav
            reactBreadcrumb={reactBreadcrumb}
            angularBreadcrumb={angularBreadcrumb}
            pageId={activePageId}
          />
          {shouldShowSectionTabs ? (
            <SectionLevelNav
              activeSuperTab={activeSuperTab}
              pageId={activePageId}
              changePageId={setActivePageId}
              shouldShowSubsectionTabs={shouldShowSubsectionTabs}
            />
          ) : null}
          {shouldShowSubsectionTabs ? (
            <SubsectionLevelNav
              activeSuperTab={activeSuperTab}
              pageId={activePageId}
              changePageId={setActivePageId}
            />
          ) : null}
        </AppContextProvider>
      </UIProvider>
    </div>
  ) : null;
}

ReactContainer.propTypes = {
  theme: PropTypes.shape({
    color: PropTypes.string.isRequired,
    logoURL: PropTypes.string,
  }),
  accountMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onSelect: PropTypes.func,
      href: PropTypes.string,
    }),
  ),
  accessibilityOptions: PropTypes.shape({
    f6Navigation: PropTypes.shape({
      isEnabled: PropTypes.bool.isRequired,
      focusInSectionsInfo: PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            type: PropTypes.oneOf(['regionMemory']).isRequired,
          }),
          PropTypes.shape({
            type: PropTypes.string,
            handler: PropTypes.func.isRequired,
          }),
        ]),
      ),
      postFocusAction: PropTypes.func,
    }),
    mainContentId: PropTypes.string,
  }),
  onLoaded: PropTypes.func,
  homeHref: PropTypes.string,
  isPortal: PropTypes.bool,
  breadcrumb: PropTypes.element,
  angularBreadcrumb: PropTypes.object,
  language: PropTypes.string,
  context: PropTypes.object,
  pageId: PropTypes.string,
  debugMode: PropTypes.object,
  options: PropTypes.shape({
    logoHref: PropTypes.string,
    isExternalAppMode: PropTypes.bool,
    homeCallback: PropTypes.func,
    customAccountMenuOption: PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    }),
    personLanguage: PropTypes.shape({
      menu: PropTypes.array.isRequired,
      action: PropTypes.func.isRequired,
    }),
    theme: PropTypes.shape({
      color: PropTypes.string,
      logoURL: PropTypes.string,
    }),
    hideAccountSettings: PropTypes.bool,
    activeProjectId: PropTypes.string,
    projectType: PropTypes.string,
    activeSuperTabId: PropTypes.string,
    subprojectType: PropTypes.string,
    directoryId: PropTypes.string,
    directoryType: PropTypes.string,
    activeSurveyId: PropTypes.string,
    subresourceId: PropTypes.string,
    activeSectionTabId: PropTypes.string,
    activeSubTabId: PropTypes.string,
    hideProjectSelector: PropTypes.bool,
    useTranscludedSelector: PropTypes.bool,
    subscribeToMenuClosing: PropTypes.func,
  }),
};

export { showSectionTabs, showSubsectionTabs, getUser };
