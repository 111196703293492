import scriptLoadService from '../../react/navigation/Service/script-load-service';

const loaderService = scriptLoadService();

loaderService.runOnLoad(function injectTractive() {
  const url = '/tractive/scripts/tractive.min.js';

  loaderService
    .loadScript(url, 'internalTractiveScript')
    .catch((error) =>
      console.error(`Global Wrapper failed to load ${url}:`, error),
    );
});
