/** @prettier */
/* eslint indent: "off" */

import context from './context.js';
import { messageService } from '../../../react/navigation/Service/message-service';

// wrapConfig will wrap a returned config and process certain keys.
export default function wrapConfig(getHierarchy) {
  return function (ctx, useV2Params) {
    var config = getHierarchy(ctx);

    if (config.v2Info?.resourceInfo?.resourceTemplate) {
      config.v2Info.resourceInfo.resource = context.parseTemplate(
        ctx,
        config.v2Info.resourceInfo.resourceTemplate,
        useV2Params,
      );
      delete config.v2Info.resourceInfo.resourceTemplate;
    }
    if (config.v2Info?.resourceInfo?.resourceTypeTemplate) {
      config.v2Info.resourceInfo.resourceType = context.parseTemplate(
        ctx,
        config.v2Info.resourceInfo.resourceTypeTemplate,
        useV2Params,
      );
      delete config.v2Info.resourceInfo.resourceTypeTemplate;
    }
    if (config.v2Info?.resourceInfo?.subresourceTemplate) {
      config.v2Info.resourceInfo.subresource = context.parseTemplate(
        ctx,
        config.v2Info.resourceInfo.subresourceTemplate,
        useV2Params,
      );
      delete config.v2Info.resourceInfo.subresourceTemplate;
    }

    if (config.nameKey) {
      config.name = messageService.get(config.nameKey);
    }

    if (!Array.isArray(config.sectionTabs)) {
      // no more to process
      return config;
    }

    config.sectionTabs = config.sectionTabs.map(function (tab) {
      if (tab.nameKey) {
        tab.name = messageService.get(tab.nameKey);
      }
      if (tab.hrefTemplate) {
        tab.href = context.parseTemplate(ctx, tab.hrefTemplate, useV2Params);
      }

      if (!Array.isArray(tab.subTabs)) {
        // no more to process
        return tab;
      }
      tab.subTabs = tab.subTabs.map(function (subtab) {
        if (subtab.nameKey) {
          subtab.name = messageService.get(subtab.nameKey);
        }
        subtab.href = context.parseTemplate(
          ctx,
          subtab.hrefTemplate,
          useV2Params,
        );
        return subtab;
      });

      return tab;
    });

    return config;
  };
}
