import AccountActionsService from '../Service/account-actions-service';
import { useContext, useMemo } from 'react';
import { AppContext } from './AppContext';

export const useAccountService = () => {
  const { userInfo, portals, accountOptions, workMode } =
    useContext(AppContext);

  const accountActionsService = useMemo(
    () =>
      new AccountActionsService(
        userInfo,
        portals.currentPortalId,
        portals.portals,
        workMode.isAuthnLogoutEnabled,
      ),
    [
      portals.currentPortalId,
      portals.portals,
      userInfo,
      workMode.isAuthnLogoutEnabled,
    ],
  );

  return {
    accountOptions,
    accountActionsService,
  };
};
