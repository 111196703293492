const FOCUSABLES_SELECTOR =
  'button:not([tabindex="-1"]):not([aria-hidden="true"]), [href]:not([tabindex="-1"]):not([aria-hidden="true"]), input:not([tabindex="-1"]):not([aria-hidden="true"]), select:not([tabindex="-1"]):not([aria-hidden="true"]), textarea:not([tabindex="-1"]):not([aria-hidden="true"]), [tabindex]:not([tabindex="-1"]):not([aria-hidden="true"])';
const IFRAME_SELECTOR = 'iframe:not([tabindex="-1"]):not([aria-hidden="true"])';

function isIframe(elem) {
  return !!elem.contentDocument;
}

function findFirstFocusableElementInIframe(iframeElem) {
  const iframeBody = iframeElem.contentDocument.body;
  return findFirstFocusableElement(iframeBody);
}

function isAnyOfParentElementsHidden(elem) {
  while (elem) {
    const styles = window.getComputedStyle(elem);
    if (styles.visibility === 'hidden') {
      return true;
    }
    if (styles.display === 'none') {
      return true;
    }
    elem = elem.parentElement;
  }
  return false;
}

function findFirstFocusableElement(parent) {
  if (parent.matches(FOCUSABLES_SELECTOR)) return parent;

  if (parent.matches(IFRAME_SELECTOR)) {
    return findFirstFocusableElementInIframe(parent);
  }

  const focusableAndIframeChildren = parent.querySelectorAll(
    `${FOCUSABLES_SELECTOR}, ${IFRAME_SELECTOR}`,
  );
  for (const elem of focusableAndIframeChildren) {
    if (!isAnyOfParentElementsHidden(elem)) {
      return isIframe(elem) ? findFirstFocusableElementInIframe(elem) : elem;
    }
  }
}

export default function skipToMainContent(mainContentId) {
  const getMainElementByTag = () => {
    const mainElements = document.getElementsByTagName('main');
    return mainElements && mainElements.length > 0
      ? mainElements.item(0)
      : undefined;
  };
  const mainSection = mainContentId
    ? document.getElementById(mainContentId)
    : document.querySelector('[role="main"]') || getMainElementByTag();
  if (mainSection) {
    findFirstFocusableElement(mainSection)?.focus();
  }
}
