/* eslint angular/document-service: "off" */
/* eslint angular/no-services: "off" */
/* eslint angular/module-getter: "off" */
/* eslint angular/function-type: "off" */
/* eslint angular/file-name: "off" */
/**
 * @module q-header
 */

/**
 * q-header directive instantiates the global wrapper header.
 * @name q-header
 * @example
 * // in js controller
 * $scope.headerOptions = {
 *  homeCallback: function() { ... },
 * };
 * $scope.context = {
 *  contextType: "Project",
 * };
 * $scope.language = "EN";
 * $scope.pageId = "MyProjects";
 * $scope.userId = "UR_01";
 *
 * // html
 * <q-header options="headerOptions" context="context" language="language" page-id="pageId" user-id="userId">{my optionally transcluded breadcrumb}</q-header>
 * @function
 *
 * @param {object} options - Allows applications to configure the header
 * @param {object} [options.customAccountMenuOption=null] - Allows a custom qmenu item to be added to the account menu
 * @param {string} options.customAccountMenuOption.label - Translated text for the account menu item
 * @param {function} options.customAccountMenuOption.action - Action to take when menu option is selected
 *
 * @param {object} [options.personLanguage=null] - Builds a language selector in the header when supplied
 * @param {object} options.personLanguage.menu - Qmenu holding list of possible languages
 * @param {function} options.personLanguage.action - Action to take when selecting a language
 *
 * @param {bool} [options.useTranscludedSelector=false] - Generic Option to enable Transcluded Selector for breadcrumb.
 *
 * @param {bool} [options.hideAccountSettings=false] - Hides the Account Settings item in the account menu when enabled
 * @param {function} [options.homeCallback] - Overwrites the default home navigation behavior of the home button
 * @param {string} [options.logoHref] - Overwrites href attribute when homeCallback is provided
 * @param {bool} [options.isExternalAppMode] - Overwrites href attribute when homeCallback is provided
 * @param {bool} [options.hideProjectSelector=false] - If it is true it will hide a hamburger menu, notifications and help menu
 *
 * @param {object} [options.theme=null] - Creates placeholder object for theme elements.
 * @param {string} options.theme.color - The hex color of the underline for themed navigation. Formatted with "#" as in "#0a0a0a".
 * @param {string} options.theme.logoURL - URL of the logo to be displayed for themed navigation. Will be scaled to max width of 100px or max height of 20px.
 * @param {string} options.accessibilityOptions.mainContentId - id of the element where to land after clicking "Skip to main"
 * @param {bool} options.accessibilityOptions.f6Navigation.isEnable - info if you want to use mechanism for skipping large blocks of content with f6 patter
 * @param {object} options.accessibilityOptions.f6Navigation.focusInSectionsInfo - optional override of existing logic to determine next section to f6 jump into
 * @param {function} options.accessibilityOptions.f6Navigation.postFocusAction - function triggered after f6 jump
 *
 * @param {string} [options.subresourceId] - REMOVED this is no longer used
 * @param {string} [options.activeProjectId=""] - REMOVED this is no longer used
 * @param {string} [options.activeSurveyId] - REMOVED this is no longer used
 * @param {string} [options.projectType=""] - REMOVED this is no longer used
 * @param {string} [options.directoryType=""] - REMOVED this is no longer used
 * @param {string} options.activeSuperTabId - REMOVED this is no longer used
 * @param {string} options.activeSectionTabId - REMOVED this is no longer used
 * @param {string} [options.activeSubTabId=""] - REMOVED this is no longer used
 * @param {bool} [options.isGlobalTickets=false] - REMOVED this is no longer used
 * @param {string} options.activeAccountSettingsSectionTabId - REMOVED this is no longer used
 * @param {string} options.activeAdminSectionTabId - REMOVED this is no longer used
 * @param {string} options.activeLibrarySectionTabId - REMOVED this is no longer used
 * @param {string} options.activePanelsSectionTabId - REMOVED this is no longer used
 * @param {string} options.activeSurveyDirectorSectionTabId - REMOVED  this is no longer used
 * @param {string} options.activeSurveySectionTabId - REMOVED  this is no longer used
 * @param {string} [options.containerId] - REMOVED this is no longer used
 * @param {string} [options.pageId] - REMOVED this is no longer used
 * @param {string} [options.fieldSetId] - REMOVED this is no longer used
 * @param {string} options.activeProduct - REMOVED this is no longer used
 * @param {string} [options.optProjectName] - REMOVED this is no longer used
 * @param {string} [options.folderData] - REMOVED this is no longer used
 * @param {bool} [options.disableKeepAlive=false] - REMOVED this is no longer used
 * @param {string[]} options.products - REMOVED this is no longer used
 * @param {bool} [options.hideSectionTabs=false] - REMOVED this is no longer used
 * @param {bool} [options.hideSuperSectionTabs=false] - REMOVED this is no longer used
 * @param {bool} [options.showSubSectionTabs=false] - REMOVED this is no longer used
 * @param {bool} [options.hideHomeSection=false] - REMOVED this is no longer used
 * @param {string} options.personLanguage.display - REMOVED this is no longer used
 *
 */

import headerTemplateUrl from '../templates/header.html';
('use strict');

export default function qHeader() {
  return {
    scope: {
      language: '<',
      pageId: '<',
      context: '<',
      options: '=options',
      selectElement: '&?',
    },
    transclude: true,
    templateUrl: headerTemplateUrl,
    link: {
      pre: function (scope) {
        if (typeof scope.language !== 'string' || scope.language === '') {
          scope.language = 'EN';
        }

        scope.showTranscludedSelector = function () {
          var isParticipantPortalSection = scope.pageId === 'ParticipantPortal';
          var isGlobalDirectorySection =
            scope.pageId === 'GlobalDirectory' ||
            scope.pageId === 'ExAutomations';
          var is360Library = scope.pageId === 'ThreeSixtyLibrary';
          var useTranscludedSelector = scope.options.useTranscludedSelector
            ? scope.options.useTranscludedSelector
            : false;
          return (
            (scope.showProjectSelector() && !scope.isProjectsSuperSection()) ||
            isGlobalDirectorySection ||
            isParticipantPortalSection ||
            is360Library ||
            useTranscludedSelector
          );
        };

        scope.isProjectsSuperSection = function () {
          return scope.context.contextType === 'Project';
        };

        scope.showProjectSelector = function showProjectSelector() {
          return !scope.options.hideProjectSelector;
        };

        scope.changePageId = (pageId) => {
          scope.pageId = pageId;
          scope.$digest();
        };

        scope.showDefaultSelector = function () {
          var isGlobalDirectorySection =
            scope.pageId === 'GlobalDirectory' ||
            scope.pageId === 'ExAutomations';
          var useTranscludedSelector = scope.options.useTranscludedSelector
            ? scope.options.useTranscludedSelector
            : false;

          return (
            scope.showProjectSelector() &&
            scope.isProjectsSuperSection() &&
            !isGlobalDirectorySection &&
            !useTranscludedSelector
          );
        };
      },
    },
  };
}
