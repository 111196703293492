import React, { useEffect, useState } from 'react';
import ProgramBreadcrumb from './ProgramBreadcrumb/ProgramBreadcrumb';
import DefaultBreadcrumb from './DefaultBreadcrumb/DefaultBreadcrumb';
import * as projectsAPI from '../Service/projects';
import styles from './breadcrumb.scss';
import { useUserInfo, useV2Info, useWorkMode } from '../Context';
import { onProjectTypeDifference } from '../Service/metrics-service';
import PropTypes from 'prop-types';

export default function Breadcrumb({ pageId }) {
  const v2Info = useV2Info();
  const { language } = useUserInfo();
  const { restrictedNavigation } = useWorkMode();
  const placeholderText = '...';
  const resourceId = v2Info?.context?.resourceId;

  const [isLoading, setLoading] = useState(true);
  const [projectName, setProjectName] = useState('');
  const [parentData, setParentData] = useState({});

  const isInProgram = parentData?.parentType === 'program';

  useEffect(() => {
    if (!resourceId) {
      console.log(
        'Breadcrumb was unable to load resource name because no resource id was passed.',
      );
      return;
    }

    const fetchProjectWithParentData = () => {
      projectsAPI
        .getProjectWithParentData(resourceId)
        .then((projectData) => {
          setProjectName(projectData?.projectName);
          setParentData(projectData?.parent);
          if (
            v2Info?.context?.resourceType &&
            v2Info.context.resourceType.toLowerCase() !== 'compositeproject' &&
            projectData?.projectType !== v2Info.context.resourceType
          ) {
            onProjectTypeDifference(
              projectData?.projectType,
              v2Info.context.resourceType,
              pageId,
            );
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchProjectWithParentData();
  }, []);

  const loadingBreadcrumb = (
    <div className={styles.loadingBreadcrumb}>{placeholderText}</div>
  );

  return isLoading ? (
    loadingBreadcrumb
  ) : isInProgram ? (
    <ProgramBreadcrumb
      projectId={resourceId}
      projectName={projectName ?? placeholderText}
      parentData={parentData}
      language={language}
      restrictedNavigation={restrictedNavigation}
    />
  ) : (
    <DefaultBreadcrumb
      projectId={resourceId}
      projectName={projectName ?? placeholderText}
      language={language}
      restrictedNavigation={restrictedNavigation}
    />
  );
}

Breadcrumb.propTypes = {
  pageId: PropTypes.string,
};
