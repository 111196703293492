export function isMac() {
  return navigator.platform.indexOf('Mac') > -1;
}

export function isFirefox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export const isExportPage = () => {
  return window.Qualtrics?.Environment?.isExport;
};
